import React, { useState } from 'react';
import { Bell, X, Send } from 'lucide-react';
import { useAgents } from '../../hooks/useAgents';
import { useToast } from '../../contexts/ToastContext';
import { sendWhatsAppMessage } from '../../services/notificationService';
import Avatar from '../ui/Avatar';

interface BulkNotificationFormProps {
  onClose: () => void;
}

export default function BulkNotificationForm({ onClose }: BulkNotificationFormProps) {
  const { agents } = useAgents();
  const { showToast } = useToast();
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sendingStatus, setSendingStatus] = useState<Record<string, 'pending' | 'success' | 'error'>>({});

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message || selectedAgents.length === 0) {
      showToast('error', 'Veuillez sélectionner au moins un agent et saisir un message');
      return;
    }

    setIsSubmitting(true);
    setSendingStatus(
      selectedAgents.reduce((acc, id) => ({ ...acc, [id]: 'pending' }), {})
    );

    try {
      // Send notifications to each agent
      const results = await Promise.allSettled(
        selectedAgents.map(async (agentId) => {
          const agent = agents.find(a => a.id === agentId);
          if (!agent?.phone) {
            setSendingStatus(prev => ({ ...prev, [agentId]: 'error' }));
            return { agentId, success: false, error: 'No phone number' };
          }

          try {
            const success = await sendWhatsAppMessage(agent.phone, message);
            setSendingStatus(prev => ({ ...prev, [agentId]: success ? 'success' : 'error' }));
            return { agentId, success };
          } catch (error) {
            setSendingStatus(prev => ({ ...prev, [agentId]: 'error' }));
            return { agentId, success: false, error };
          }
        })
      );

      // Count successes and failures
      const successes = results.filter(r => r.status === 'fulfilled' && r.value?.success).length;
      const failures = results.filter(r => r.status === 'rejected' || !r.value?.success).length;

      if (failures === 0) {
        showToast('success', 'Notifications envoyées avec succès');
        onClose();
      } else if (successes === 0) {
        showToast('error', 'Échec de l\'envoi des notifications');
      } else {
        showToast('warning', `${successes} notification(s) envoyée(s), ${failures} échec(s)`);
      }
    } catch (error) {
      console.error('Error sending notifications:', error);
      showToast('error', 'Erreur lors de l\'envoi des notifications');
    } finally {
      setIsSubmitting(false);
    }
  };

  const toggleAgent = (agentId: string) => {
    setSelectedAgents(prev => 
      prev.includes(agentId)
        ? prev.filter(id => id !== agentId)
        : [...prev, agentId]
    );
  };

  const selectAll = () => {
    setSelectedAgents(agents.map(agent => agent.id));
  };

  const unselectAll = () => {
    setSelectedAgents([]);
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-[#1c1c1e] rounded-2xl w-full max-w-lg m-4">
        {/* Header */}
        <div className="p-6 border-b border-[#2c2c2e]">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <Bell className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <h2 className="text-[22px] font-semibold text-white">Envoyer une notification</h2>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-[#2c2c2e] rounded-xl transition-colors"
            >
              <X className="w-5 h-5 text-[#98989f]" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {/* Agent Selection */}
          <div>
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-[15px] font-medium text-white">Sélectionner les agents</h3>
              <div className="flex gap-2">
                <button
                  type="button"
                  onClick={selectAll}
                  className="px-3 py-1 text-[13px] text-[#0a84ff] hover:bg-[#0a84ff]/10 rounded-lg transition-colors"
                >
                  Tout sélectionner
                </button>
                <button
                  type="button"
                  onClick={unselectAll}
                  className="px-3 py-1 text-[13px] text-[#98989f] hover:bg-[#2c2c2e] rounded-lg transition-colors"
                >
                  Tout désélectionner
                </button>
              </div>
            </div>

            <div className="space-y-2 max-h-48 overflow-y-auto">
              {agents.map(agent => (
                <div
                  key={agent.id}
                  onClick={() => toggleAgent(agent.id)}
                  className={`flex items-center gap-3 p-3 rounded-xl cursor-pointer transition-colors ${
                    selectedAgents.includes(agent.id)
                      ? 'bg-[#0a84ff]/10'
                      : 'hover:bg-[#2c2c2e]'
                  }`}
                >
                  <Avatar name={agent.name} size="sm" />
                  <div className="flex-1">
                    <p className="text-[15px] font-medium text-white">{agent.name}</p>
                    <p className="text-[13px] text-[#98989f]">
                      {agent.phone || 'Pas de numéro de téléphone'}
                    </p>
                    {sendingStatus[agent.id] && (
                      <p className={`text-[13px] ${
                        sendingStatus[agent.id] === 'success' ? 'text-[#30d158]' :
                        sendingStatus[agent.id] === 'error' ? 'text-[#ff453a]' :
                        'text-[#98989f]'
                      }`}>
                        {sendingStatus[agent.id] === 'success' ? '✓ Envoyé' :
                         sendingStatus[agent.id] === 'error' ? '✕ Échec' :
                         '⟳ Envoi...'}
                      </p>
                    )}
                  </div>
                  <div className={`w-5 h-5 rounded-md border ${
                    selectedAgents.includes(agent.id)
                      ? 'bg-[#0a84ff] border-[#0a84ff]'
                      : 'border-[#3a3a3c]'
                  }`}>
                    {selectedAgents.includes(agent.id) && (
                      <svg className="w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Message Input */}
          <div>
            <label className="block text-[15px] font-medium text-white mb-2">
              Message
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full px-4 py-3 bg-[#2c2c2e] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f] resize-none"
              placeholder="Votre message..."
              rows={4}
              required
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-[#98989f] bg-[#2c2c2e] rounded-xl hover:bg-[#3a3a3c] transition-colors"
              disabled={isSubmitting}
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={isSubmitting || selectedAgents.length === 0 || !message}
              className="px-4 py-2 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors disabled:opacity-50 flex items-center gap-2"
            >
              <Send className="w-4 h-4" />
              {isSubmitting ? 'Envoi...' : 'Envoyer'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}