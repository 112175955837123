import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Users, LogOut, ChevronDown, RefreshCw } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../../hooks/useAuth';
import { clearAllCache } from '../../utils/cache';
import { useToast } from '../../contexts/ToastContext';

export default function UserMenu() {
  const { user, logout } = useAuth();
  const { showToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClearCache = async () => {
    try {
      await clearAllCache();
      showToast('success', 'Cache vidé avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors du vidage du cache');
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={menuRef} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-3 p-2 lg:p-4 w-full"
      >
        <div className="w-8 h-8 flex items-center justify-center rounded-full bg-[#0a84ff]">
          <Users className="w-5 h-5 text-white" />
        </div>
        <div className="hidden lg:block flex-1 min-w-0 text-left">
          <p className="text-[15px] font-medium text-white truncate">
            {user?.name}
          </p>
          <p className="text-[15px] text-[#98989f] truncate">
            {user?.email}
          </p>
        </div>
        <ChevronDown className={`
          w-5 h-5 text-[#98989f] transition-transform duration-200 
          ${isOpen ? 'rotate-180' : ''}
        `} />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute z-50 right-0 mt-2 lg:mt-0 lg:bottom-full lg:mb-2 w-56 bg-[#1c1c1e] rounded-2xl shadow-xl overflow-hidden"
          >
            <div className="p-2 space-y-1">
              <div className="lg:hidden px-3 py-2 border-b border-gray-200/50 mb-2">
                <p className="font-medium text-white">{user?.name}</p>
                <p className="text-[15px] text-[#98989f]">{user?.email}</p>
              </div>
              
              <Link
                to="/profile"
                className={`
                  flex items-center gap-3 px-4 py-3 text-[17px] rounded-xl
                  text-white hover:bg-[#2c2c2e] transition-colors
                `}
                onClick={() => setIsOpen(false)}
              >
                <Users className="w-5 h-5" />
                Mon profil
              </Link>
              
              <button
                onClick={() => {
                  handleClearCache();
                  setIsOpen(false);
                }}
                className={`
                  flex items-center gap-3 px-4 py-3 text-[17px] rounded-xl w-full
                  text-[#0a84ff] hover:bg-[#2c2c2e] transition-colors text-left
                `}
              >
                <RefreshCw className="w-5 h-5" />
                Vider le cache
              </button>
              
              <button
                onClick={() => {
                  logout();
                  setIsOpen(false);
                }}
                className={`
                  flex items-center gap-3 px-4 py-3 text-[17px] rounded-xl w-full
                  text-[#ff453a] hover:bg-[#2c2c2e] transition-colors
                `}
              >
                <LogOut className="w-5 h-5" />
                Déconnexion
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}