import React from 'react';
import { DollarSign, Clock, Gift, TrendingUp } from 'lucide-react';
import { useSales } from '../../hooks/useSales';
import { formatCurrency, formatEuro } from '../../utils/format';
import BlurredNumber from '../../components/ui/BlurredNumber';

export default function KPICards() {
  const { sales, calculateCommission } = useSales();
  
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const startDate = new Date(2024, 6); // July 2024
  const monthsSinceStart = Math.max(
    1,
    (currentYear - 2024) * 12 + (currentMonth - 6) + 1
  );

  const kpis = sales.reduce(
    (acc, sale) => {
      const commission = calculateCommission(sale);
      const saleDate = new Date(sale.createdAt);
      const isCurrentMonth =
        saleDate.getMonth() === currentMonth &&
        saleDate.getFullYear() === currentYear;

      acc.totalAgencyCommission += commission.agency;
      if (!sale.paymentStatus.developer) {
        acc.pendingCommission += commission.agency;
      }

      if (isCurrentMonth) {
        acc.monthlyVolume += sale.propertyPrice;
        acc.monthlyCommission += commission.agency;
        acc.monthlySales += 1;
      }

      if (saleDate >= startDate) {
        acc.totalSalesCount += 1;
      }

      if (sale.bonus) {
        acc.totalBonus += commission.bonus.agency;
        if (!sale.paymentStatus.bonus) {
          acc.pendingBonus += commission.bonus.agency;
        }
      }

      return acc;
    },
    {
      totalAgencyCommission: 0,
      pendingCommission: 0,
      monthlyVolume: 0,
      monthlyCommission: 0,
      monthlySales: 0,
      totalSalesCount: 0,
      totalBonus: 0,
      pendingBonus: 0,
    }
  );

  const averageMonthlySales = Math.round(kpis.totalSalesCount / monthsSinceStart);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {/* Commission perçue */}
      <div className="bg-[#26382f] rounded-xl p-4">
        <div className="flex items-center gap-2 mb-2">
          <DollarSign className="w-4 h-4 text-[#30d158]" />
          <p className="text-[13px] text-[#98989f]">Commission perçue</p>
        </div>
        <p className="text-[17px] font-bold text-[#30d158]">
          <BlurredNumber value={formatCurrency(kpis.totalAgencyCommission)} />
        </p>
        <p className="text-[13px] text-[#98989f] mt-1">
          <BlurredNumber value={formatEuro(kpis.totalAgencyCommission)} />
        </p>
      </div>

      {/* Commission en attente */}
      <div className="bg-[#3a2c29] rounded-xl p-4">
        <div className="flex items-center gap-2 mb-2">
          <Clock className="w-4 h-4 text-[#ff453a]" />
          <p className="text-[13px] text-[#98989f]">Commission en attente</p>
        </div>
        <p className="text-[17px] font-bold text-[#ff453a]">
          <BlurredNumber value={formatCurrency(kpis.pendingCommission)} />
        </p>
        <p className="text-[13px] text-[#98989f] mt-1">
          <BlurredNumber value={formatEuro(kpis.pendingCommission)} />
        </p>
      </div>

      {/* Bonus total */}
      <div className="bg-[#2d2a3e] rounded-xl p-4">
        <div className="flex items-center gap-2 mb-2">
          <Gift className="w-4 h-4 text-[#bf5af2]" />
          <p className="text-[13px] text-[#98989f]">Bonus total</p>
        </div>
        <p className="text-[17px] font-bold text-[#bf5af2]">
          <BlurredNumber value={formatCurrency(kpis.totalBonus)} />
        </p>
        <p className="text-[13px] text-[#98989f] mt-1">
          <BlurredNumber value={formatEuro(kpis.totalBonus)} />
        </p>
      </div>

      {/* Moyenne mensuelle */}
      <div className="bg-[#1c3a4f] rounded-xl p-4">
        <div className="flex items-center gap-2 mb-2">
          <TrendingUp className="w-4 h-4 text-[#0a84ff]" />
          <p className="text-[13px] text-[#98989f]">Moyenne mensuelle</p>
        </div>
        <p className="text-[17px] font-bold text-[#0a84ff]">
          {averageMonthlySales} ventes
        </p>
        <p className="text-[13px] text-[#98989f] mt-1">
          Depuis juillet 2024
        </p>
      </div>
    </div>
  );
}