import React from 'react';
import { Gift, DollarSign } from 'lucide-react';
import { useAgents } from '../../hooks/useAgents';
import { formatCurrency } from '../../utils/format';

interface BonusSectionProps {
  formData: {
    bonus?: {
      amount: number;
      agencyAmount?: number;
      agents: Array<{
        agentId: string;
        amount: number;
      }>;
      isManual?: boolean;
      paid?: boolean;
    };
    agentId: string;
  };
  onChange: (data: { bonus?: { amount: number; agencyAmount?: number; agents: Array<{ agentId: string; amount: number }>; isManual?: boolean; paid?: boolean } }) => void;
}

export default function BonusSection({ formData, onChange }: BonusSectionProps) {
  const { agents } = useAgents();

  return (
    <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
      {/* Header */}
      <div className="p-4 border-b border-[#3a3a3c]">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-[#bf5af2]/10 rounded-lg">
            <Gift className="w-5 h-5 text-[#bf5af2]" />
          </div>
          <div>
            <h3 className="text-[17px] font-medium text-white">Bonus</h3>
            <p className="text-[13px] text-[#98989f]">Bonus et récompenses</p>
          </div>
        </div>
      </div>

      {/* Form Content */}
      <div className="p-4 space-y-6">
        {/* Bonus Amount */}
        <div>
          <label className="block text-[13px] font-medium text-[#98989f] mb-2">
            Montant total du bonus
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
            <input
              type="number"
              value={formData.bonus?.amount || ''}
              onChange={(e) => onChange({
                bonus: {
                  ...formData.bonus,
                  amount: Number(e.target.value)
                }
              })}
              className="w-full pl-10 pr-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
              placeholder="Montant"
            />
          </div>
        </div>

        {/* Manual Distribution Toggle */}
        <div className="flex items-center gap-3 p-4 bg-[#3a3a3c] rounded-xl">
          <label className="flex items-center gap-2 text-[15px] text-white">
            <input
              type="checkbox"
              checked={formData.bonus?.isManual || false}
              onChange={(e) => onChange({
                bonus: {
                  ...formData.bonus,
                  isManual: e.target.checked,
                  agents: e.target.checked ? [] : undefined
                }
              })}
              className="sr-only peer"
            />
            <div className="w-9 h-5 bg-[#48484a] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#0a84ff]/30 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-[#48484a] after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[#0a84ff]"></div>
            <span>Répartition manuelle</span>
          </label>
        </div>

        {/* Manual Distribution */}
        {formData.bonus?.isManual && (
          <div className="space-y-4">
            {/* Agency Commission */}
            <div className="p-4 bg-[#3a3a3c] rounded-xl">
              <label className="block text-[13px] font-medium text-[#98989f] mb-2">
                Commission agence
              </label>
              <div className="relative">
                <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
                <input
                  type="number"
                  value={formData.bonus?.agencyAmount || 0}
                  onChange={(e) => onChange({
                    bonus: {
                      ...formData.bonus,
                      agencyAmount: Number(e.target.value)
                    }
                  })}
                  className="w-full pl-10 pr-4 py-3 bg-[#48484a] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                  placeholder="Commission agence"
                />
              </div>
            </div>

            {/* Agents Distribution */}
            <div className="space-y-4">
              <h4 className="text-[15px] font-medium text-white">Distribution aux agents</h4>
              
              {/* Primary Agent */}
              {agents.find(a => a.id === formData.agentId) && (
                <div className="p-4 bg-[#3a3a3c] rounded-xl">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <input
                        type="text"
                        value={agents.find(a => a.id === formData.agentId)?.name}
                        readOnly
                        className="w-full px-4 py-3 bg-[#48484a] text-white border-none rounded-xl"
                      />
                    </div>
                    <div>
                      <input
                        type="number"
                        value={formData.bonus?.agents?.find(a => a.agentId === formData.agentId)?.amount || 0}
                        onChange={(e) => {
                          const newAgents = [...(formData.bonus?.agents || [])];
                          const primaryAgentIndex = newAgents.findIndex(a => a.agentId === formData.agentId);
                          if (primaryAgentIndex >= 0) {
                            newAgents[primaryAgentIndex].amount = Number(e.target.value);
                          } else {
                            newAgents.push({
                              agentId: formData.agentId,
                              amount: Number(e.target.value)
                            });
                          }
                          onChange({
                            bonus: {
                              ...formData.bonus,
                              agents: newAgents
                            }
                          });
                        }}
                        className="w-full px-4 py-3 bg-[#48484a] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                        placeholder="Montant"
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Additional Agents */}
              {formData.bonus?.agents?.map((agent, index) => (
                agent.agentId !== formData.agentId && (
                  <div key={index} className="p-4 bg-[#3a3a3c] rounded-xl">
                    <div className="grid grid-cols-2 gap-4">
                      <select
                        value={agent.agentId}
                        onChange={(e) => {
                          const newAgents = [...(formData.bonus?.agents || [])];
                          newAgents[index].agentId = e.target.value;
                          onChange({
                            bonus: {
                              ...formData.bonus,
                              agents: newAgents
                            }
                          });
                        }}
                        className="w-full px-4 py-3 bg-[#48484a] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff]"
                      >
                        <option value="" className="bg-[#1c1c1e] text-white">Sélectionner un agent</option>
                        {agents.filter(a => a.id !== formData.agentId).map(agent => (
                          <option key={agent.id} value={agent.id} className="bg-[#1c1c1e] text-white">
                            {agent.name}
                          </option>
                        ))}
                      </select>
                      <div className="flex gap-2">
                        <input
                          type="number"
                          value={agent.amount}
                          onChange={(e) => {
                            const newAgents = [...(formData.bonus?.agents || [])];
                            newAgents[index].amount = Number(e.target.value);
                            onChange({
                              bonus: {
                                ...formData.bonus,
                                agents: newAgents
                              }
                            });
                          }}
                          className="flex-1 px-4 py-3 bg-[#48484a] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                          placeholder="Montant"
                        />
                        <button
                          type="button"
                          onClick={() => {
                            const newAgents = formData.bonus?.agents?.filter((_, i) => i !== index);
                            onChange({
                              bonus: {
                                ...formData.bonus,
                                agents: newAgents
                              }
                            });
                          }}
                          className="px-4 py-2 bg-[#ff453a]/10 text-[#ff453a] rounded-xl hover:bg-[#ff453a]/20"
                        >
                          ×
                        </button>
                      </div>
                    </div>
                  </div>
                )
              ))}

              {/* Add Agent Button */}
              <button
                type="button"
                onClick={() => {
                  const newAgents = [...(formData.bonus?.agents || [])];
                  newAgents.push({ agentId: '', amount: 0 });
                  onChange({
                    bonus: {
                      ...formData.bonus,
                      agents: newAgents
                    }
                  });
                }}
                className="w-full px-4 py-3 bg-[#0a84ff]/10 text-[#0a84ff] rounded-xl hover:bg-[#0a84ff]/20 transition-colors text-[15px] font-medium"
              >
                + Ajouter un agent
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}