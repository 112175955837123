import React, { useState } from 'react';
import { X, Mail, Phone, User, Key, DollarSign, Users } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { generatePassword } from '../utils/crypto';
import { sendEmailNotification, sendWhatsAppNotification } from '../services/notificationService';
import { useToast } from '../contexts/ToastContext';
import type { Agent } from '../types';

interface AgentFormProps {
  onSubmit: (agent: Omit<Agent, 'id' | 'totalSales' | 'totalCommission'>) => Promise<void>;
  onClose: () => void;
  initialData?: Agent;
  createAccount?: boolean;
  agents: Agent[]; // Add agents prop
}

export default function AgentForm({ onSubmit, onClose, initialData, createAccount, agents }: AgentFormProps) {
  const { createUserAccount } = useAuth();
  const { showToast } = useToast();
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    email: initialData?.email || '',
    commissionPercentage: initialData?.commissionPercentage || 60,
    referralPercentage: initialData?.referralPercentage || 10,
    referrerId: initialData?.referrerId || '',
    phone: initialData?.phone || '',
    avatar: initialData?.avatar || '',
    directAccess: initialData?.directAccess || true,
    createAccount: createAccount || false,
    changePassword: false,
    notifications: {
      email: true,
      whatsapp: true
    }
  });
  const [password, setPassword] = useState(generatePassword(12));
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    try {
      const agentData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        avatar: formData.avatar,
        commissionPercentage: formData.commissionPercentage,
        referralPercentage: formData.referralPercentage,
        referrerId: formData.referrerId || null,
        totalSales: initialData?.totalSales || 0,
        totalCommission: initialData?.totalCommission || 0,
        createdAt: initialData?.createdAt || new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      await onSubmit(agentData);

      // Send notifications
      if (formData.createAccount || formData.changePassword) {
        try {
          if (formData.notifications.email) {
            await sendEmailNotification(
              { ...agentData, id: initialData?.id || '' } as Agent,
              password
            );
            showToast('success', 'Email de notification envoyé avec succès');
          }
          if (formData.notifications.whatsapp && formData.phone) {
            await sendWhatsAppNotification(
              { ...agentData, id: initialData?.id || '' } as Agent,
              password
            );
            showToast('success', 'Notification WhatsApp envoyée avec succès');
          }
        } catch (notifError) {
          console.error('Error sending notifications:', notifError);
          showToast('error', 'Erreur lors de l\'envoi des notifications');
        }
      }

      showToast('success', initialData ? 'Agent modifié avec succès' : 'Agent créé avec succès');
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error instanceof Error) {
        setError(error.message);
        showToast('error', error.message);
      } else {
        setError('Erreur lors de la création de l\'agent');
        showToast('error', 'Erreur lors de la création de l\'agent');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-[#1c1c1e] rounded-2xl w-full max-w-2xl max-h-[90vh] flex flex-col">
        {/* Fixed Header */}
        <div className="flex-shrink-0 p-6 border-b border-[#2c2c2e]">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <User className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <h2 className="text-[22px] font-semibold text-white">
                {initialData ? 'Modifier l\'agent' : 'Nouvel agent'}
              </h2>
            </div>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-[#2c2c2e] rounded-xl transition-colors"
            >
              <X className="w-5 h-5 text-[#98989f]" />
            </button>
          </div>
        </div>

        {/* Scrollable Content */}
        <div className="flex-1 overflow-y-auto">
          <form id="agent-form" onSubmit={handleSubmit} className="p-6 space-y-6">
            {error && (
              <div className="p-4 bg-[#3a2c29] text-[#ff453a] rounded-xl text-[15px]">
                {error}
              </div>
            )}

            <div className="space-y-4">
              <div>
                <label className="block text-[13px] font-medium text-[#98989f] mb-2">
                  Nom complet
                </label>
                <div className="relative">
                  <User className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
                  <input
                    id="name"
                    type="text"
                    required
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="w-full pl-10 pr-4 py-3 bg-[#2c2c2e] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                    placeholder="Nom et prénom"
                  />
                </div>
              </div>

              <div>
                <label className="block text-[13px] font-medium text-[#98989f] mb-2">
                  Email professionnel
                </label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
                  <input
                    id="email"
                    type="email"
                    required
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="w-full pl-10 pr-4 py-3 bg-[#2c2c2e] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                    placeholder="email@palmdubaiimmo.com"
                  />
                </div>
              </div>

              <div>
                <label className="block text-[13px] font-medium text-[#98989f] mb-2">
                  Téléphone
                </label>
                <div className="relative">
                  <Phone className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
                  <input
                    id="phone"
                    type="tel"
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    className="w-full pl-10 pr-4 py-3 bg-[#2c2c2e] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                    placeholder="+971 XX XXX XXXX"
                  />
                </div>
              </div>
            </div>

            {/* Commission Settings */}
            <div className="p-4 bg-[#2c2c2e] rounded-xl space-y-4">
              <div className="flex items-center gap-3 mb-4">
                <DollarSign className="w-5 h-5 text-[#30d158]" />
                <h3 className="text-[17px] font-medium text-white">Paramètres de commission</h3>
              </div>
              
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-[13px] text-[#98989f] mb-2">
                    Commission agent (%)
                  </label>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    value={formData.commissionPercentage}
                    onChange={(e) => setFormData({ ...formData, commissionPercentage: Number(e.target.value) })}
                    className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#30d158]"
                  />
                </div>

                <div>
                  <label className="block text-[13px] text-[#98989f] mb-2">
                    Commission parrain (%)
                  </label>
                  <div className="flex gap-2">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={formData.referralPercentage}
                      onChange={(e) => setFormData({ ...formData, referralPercentage: Number(e.target.value) })}
                      className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#30d158]"
                    />
                    <select
                      value={formData.referrerId}
                      onChange={(e) => setFormData({ ...formData, referrerId: e.target.value })}
                      className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#30d158]"
                    >
                      <option value="">Sélectionner un parrain</option>
                      {agents?.filter(a => a.id !== initialData?.id).map(agent => (
                        <option key={agent.id} value={agent.id}>{agent.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* Account Settings */}
            {!initialData && (
              <div className="p-4 bg-[#2c2c2e] rounded-xl">
                <div className="flex items-center gap-3">
                  <input
                    type="checkbox"
                    id="createUserAccount"
                    checked={formData.createAccount}
                    onChange={(e) => setFormData({ ...formData, createAccount: e.target.checked })}
                    className="w-5 h-5 rounded border-[#3a3a3c] text-[#0a84ff] focus:ring-[#0a84ff]"
                  />
                  <label htmlFor="createUserAccount" className="text-[15px] text-white">
                    Créer un compte utilisateur pour cet agent
                  </label>
                </div>
              </div>
            )}

            {/* Password Section */}
            {(formData.createAccount || initialData) && (
              <div className="space-y-4">
                {initialData && (
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id="changePassword"
                      checked={formData.changePassword}
                      onChange={(e) => {
                        setFormData({ ...formData, changePassword: e.target.checked });
                        if (e.target.checked) {
                          setPassword(generatePassword(12));
                        }
                      }}
                      className="w-4 h-4 rounded border-[#3a3a3c] text-[#0a84ff] focus:ring-[#0a84ff]"
                    />
                    <label htmlFor="changePassword" className="text-[15px] text-white">
                      Générer un nouveau mot de passe
                    </label>
                  </div>
                )}

                {(formData.createAccount || formData.changePassword) && (
                  <div className="p-4 bg-[#2c2c2e] rounded-xl">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center gap-2">
                        <Key className="w-4 h-4 text-[#98989f]" />
                        <label className="text-[15px] text-white">
                          Mot de passe généré
                        </label>
                      </div>
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="text-[13px] text-[#0a84ff]"
                      >
                        {showPassword ? "Masquer" : "Afficher"}
                      </button>
                    </div>
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        value={password}
                        readOnly
                        className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl font-mono text-[15px]"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Notification Options */}
            {(formData.createAccount || formData.changePassword) && (
              <div className="grid grid-cols-2 gap-4">
                <div className="p-4 bg-[#2c2c2e] rounded-xl">
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      id="sendEmail"
                      checked={formData.notifications.email}
                      onChange={(e) => setFormData({
                        ...formData,
                        notifications: { ...formData.notifications, email: e.target.checked }
                      })}
                      className="w-5 h-5 rounded border-[#3a3a3c] text-[#0a84ff] focus:ring-[#0a84ff]"
                    />
                    <label htmlFor="sendEmail" className="text-[15px] text-white">
                      Envoyer par email
                    </label>
                  </div>
                </div>

                <div className="p-4 bg-[#2c2c2e] rounded-xl">
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      id="sendWhatsapp"
                      checked={formData.notifications.whatsapp}
                      onChange={(e) => setFormData({
                        ...formData,
                        notifications: { ...formData.notifications, whatsapp: e.target.checked }
                      })}
                      className="w-5 h-5 rounded border-[#3a3a3c] text-[#30d158] focus:ring-[#30d158]"
                    />
                    <label htmlFor="sendWhatsapp" className="text-[15px] text-white">
                      Envoyer par WhatsApp
                    </label>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>

        {/* Fixed Footer */}
        <div className="flex-shrink-0 p-6 border-t border-[#2c2c2e]">
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-3 bg-[#2c2c2e] text-[#98989f] rounded-xl hover:bg-[#3a3a3c] transition-colors"
              disabled={isSubmitting}
            >
              Annuler
            </button>
            <button
              form="agent-form"
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-3 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors disabled:opacity-50"
            >
              {isSubmitting ? 'Enregistrement...' : initialData ? 'Mettre à jour' : 'Ajouter'} l'agent
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}