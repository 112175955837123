import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { Calculator, TrendingUp, Calendar, DollarSign, Target, ChevronUp, ChevronDown, Minus, Brain } from 'lucide-react';
import { useSales } from '../hooks/useSales';
import { useAgents } from '../hooks/useAgents';
import { formatCurrency, formatEuro } from '../utils/format';
import BlurredNumber from '../components/ui/BlurredNumber';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default function AIEstimation() {
  const { sales, calculateCommission } = useSales();
  const { agents } = useAgents();
  const startDate = new Date(2024, 6); // July 2024

  // Calculate historical data
  const historicalData = useMemo(() => {
    const monthlyData = sales.reduce((acc, sale) => {
      const date = new Date(sale.saleDate);
      const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      const commission = calculateCommission(sale);
      
      if (!acc[monthKey]) {
        acc[monthKey] = {
          month: monthKey,
          revenue: 0,
          sales: 0,
          agencyCommission: 0
        };
      }
      
      acc[monthKey].revenue += sale.propertyPrice;
      acc[monthKey].sales += 1;
      acc[monthKey].agencyCommission += commission.agency;
      if (sale.bonus) {
        acc[monthKey].agencyCommission += commission.bonus.agency;
      }
      
      return acc;
    }, {} as Record<string, {
      month: string;
      revenue: number;
      sales: number;
      agencyCommission: number;
    }>);

    return Object.values(monthlyData);
  }, [sales]);

  // Calculate averages
  const averages = useMemo(() => {
    if (historicalData.length === 0) return {
      monthlySales: 0,
      monthlyRevenue: 0,
      monthlyCommission: 0
    };

    return {
      monthlySales: historicalData.reduce((sum, month) => sum + month.sales, 0) / historicalData.length,
      monthlyRevenue: historicalData.reduce((sum, month) => sum + month.revenue, 0) / historicalData.length,
      monthlyCommission: historicalData.reduce((sum, month) => sum + month.agencyCommission, 0) / historicalData.length
    };
  }, [historicalData]);

  // Calculate growth rates
  const growthRates = useMemo(() => {
    if (historicalData.length < 2) return {
      sales: 0,
      revenue: 0,
      commission: 0
    };

    const last = historicalData[historicalData.length - 1];
    const previous = historicalData[historicalData.length - 2];

    return {
      sales: ((last.sales - previous.sales) / previous.sales) * 100,
      revenue: ((last.revenue - previous.revenue) / previous.revenue) * 100,
      commission: ((last.agencyCommission - previous.agencyCommission) / previous.agencyCommission) * 100
    };
  }, [historicalData]);

  // Generate predictions
  const predictions = useMemo(() => {
    const months = ['3 mois', '6 mois', '12 mois'];
    return months.map(period => {
      const monthCount = parseInt(period);
      const growthMultiplier = monthCount <= 3 ? 1.2 : monthCount <= 6 ? 1.15 : 1.1;
      
      return {
        period,
        optimistic: {
          sales: Math.ceil(averages.monthlySales * monthCount * growthMultiplier),
          revenue: averages.monthlyRevenue * monthCount * growthMultiplier,
          commission: averages.monthlyCommission * monthCount * growthMultiplier
        },
        realistic: {
          sales: Math.ceil(averages.monthlySales * monthCount),
          revenue: averages.monthlyRevenue * monthCount,
          commission: averages.monthlyCommission * monthCount
        },
        conservative: {
          sales: Math.ceil(averages.monthlySales * monthCount * 0.8),
          revenue: averages.monthlyRevenue * monthCount * 0.8,
          commission: averages.monthlyCommission * monthCount * 0.8
        }
      };
    });
  }, [averages]);

  return (
    <div className="max-w-7xl mx-auto space-y-6">
      {/* Header */}
      <div className="bg-gradient-to-br from-[#1e3a5f] to-[#0f172a] rounded-xl overflow-hidden shadow-xl">
        <div className="p-6">
          <div className="flex items-center gap-3">
            <div className="p-3 bg-white/10 rounded-xl border border-white/20">
              <Brain className="w-6 h-6 text-white" />
            </div>
            <div>
              <h1 className="text-2xl font-bold text-white">Estimation IA</h1>
              <p className="text-blue-200 mt-1">
                Prévisions basées sur les données depuis juillet 2024
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Current Performance */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl border border-gray-200/50 p-6"
        >
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-blue-50 rounded-lg">
              <TrendingUp className="w-5 h-5 text-blue-600" />
            </div>
            <h3 className="font-medium">Moyenne mensuelle</h3>
          </div>
          <p className="text-3xl font-bold mb-2">
            <BlurredNumber value={formatCurrency(averages.monthlyCommission)} />
          </p>
          <p className="text-sm text-gray-500">
            <BlurredNumber value={formatEuro(averages.monthlyCommission)} />
          </p>
          <div className="mt-4 flex items-center gap-2">
            <div className={`p-1 rounded-full ${
              growthRates.commission > 0 ? 'bg-green-100' :
              growthRates.commission < 0 ? 'bg-red-100' :
              'bg-gray-100'
            }`}>
              {growthRates.commission > 0 ? <ChevronUp className="w-4 h-4 text-green-600" /> :
               growthRates.commission < 0 ? <ChevronDown className="w-4 h-4 text-red-600" /> :
               <Minus className="w-4 h-4 text-gray-600" />}
            </div>
            <span className={`text-sm ${
              growthRates.commission > 0 ? 'text-green-600' :
              growthRates.commission < 0 ? 'text-red-600' :
              'text-gray-600'
            }`}>
              {growthRates.commission > 0 ? '+' : ''}{growthRates.commission.toFixed(1)}%
            </span>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-white rounded-xl border border-gray-200/50 p-6"
        >
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-green-50 rounded-lg">
              <DollarSign className="w-5 h-5 text-green-600" />
            </div>
            <h3 className="font-medium">Volume mensuel</h3>
          </div>
          <p className="text-3xl font-bold mb-2">
            <BlurredNumber value={formatCurrency(averages.monthlyRevenue)} />
          </p>
          <p className="text-sm text-gray-500">
            <BlurredNumber value={formatEuro(averages.monthlyRevenue)} />
          </p>
          <div className="mt-4 flex items-center gap-2">
            <div className={`p-1 rounded-full ${
              growthRates.revenue > 0 ? 'bg-green-100' :
              growthRates.revenue < 0 ? 'bg-red-100' :
              'bg-gray-100'
            }`}>
              {growthRates.revenue > 0 ? <ChevronUp className="w-4 h-4 text-green-600" /> :
               growthRates.revenue < 0 ? <ChevronDown className="w-4 h-4 text-red-600" /> :
               <Minus className="w-4 h-4 text-gray-600" />}
            </div>
            <span className={`text-sm ${
              growthRates.revenue > 0 ? 'text-green-600' :
              growthRates.revenue < 0 ? 'text-red-600' :
              'text-gray-600'
            }`}>
              {growthRates.revenue > 0 ? '+' : ''}{growthRates.revenue.toFixed(1)}%
            </span>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white rounded-xl border border-gray-200/50 p-6"
        >
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-purple-50 rounded-lg">
              <Target className="w-5 h-5 text-purple-600" />
            </div>
            <h3 className="font-medium">Ventes mensuelles</h3>
          </div>
          <p className="text-3xl font-bold mb-2">
            {averages.monthlySales.toFixed(1)}
          </p>
          <p className="text-sm text-gray-500">
            ventes en moyenne
          </p>
          <div className="mt-4 flex items-center gap-2">
            <div className={`p-1 rounded-full ${
              growthRates.sales > 0 ? 'bg-green-100' :
              growthRates.sales < 0 ? 'bg-red-100' :
              'bg-gray-100'
            }`}>
              {growthRates.sales > 0 ? <ChevronUp className="w-4 h-4 text-green-600" /> :
               growthRates.sales < 0 ? <ChevronDown className="w-4 h-4 text-red-600" /> :
               <Minus className="w-4 h-4 text-gray-600" />}
            </div>
            <span className={`text-sm ${
              growthRates.sales > 0 ? 'text-green-600' :
              growthRates.sales < 0 ? 'text-red-600' :
              'text-gray-600'
            }`}>
              {growthRates.sales > 0 ? '+' : ''}{growthRates.sales.toFixed(1)}%
            </span>
          </div>
        </motion.div>
      </div>

      {/* Predictions */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {predictions.map((prediction, index) => (
          <motion.div
            key={prediction.period}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 + (index * 0.1) }}
            className="bg-white rounded-xl border border-gray-200/50 overflow-hidden"
          >
            <div className="p-6 border-b border-gray-200/50">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-blue-50 rounded-lg">
                  <Calendar className="w-5 h-5 text-blue-600" />
                </div>
                <div>
                  <h3 className="font-semibold">Projection à {prediction.period}</h3>
                  <p className="text-sm text-gray-500">Basée sur les moyennes actuelles</p>
                </div>
              </div>
            </div>

            <div className="p-6 space-y-6">
              {/* Optimistic */}
              <div>
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <div className="w-2 h-2 rounded-full bg-green-400" />
                    <p className="text-sm text-gray-600">Optimiste (+20%)</p>
                  </div>
                  <div className="flex items-center gap-1 text-green-400">
                    <ChevronUp className="w-4 h-4" />
                    <p className="text-xs">+20%</p>
                  </div>
                </div>
                <p className="text-xl font-bold text-gray-900">
                  <BlurredNumber value={formatCurrency(prediction.optimistic.commission)} />
                </p>
                <p className="text-sm text-gray-500">
                  <BlurredNumber value={formatEuro(prediction.optimistic.commission)} />
                </p>
                <div className="mt-2 px-3 py-1.5 bg-green-50 text-green-700 rounded-lg text-sm inline-block">
                  ~{prediction.optimistic.sales} ventes
                </div>
              </div>

              {/* Realistic */}
              <div>
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <div className="w-2 h-2 rounded-full bg-blue-400" />
                    <p className="text-sm text-gray-600">Réaliste</p>
                  </div>
                </div>
                <p className="text-xl font-bold text-gray-900">
                  <BlurredNumber value={formatCurrency(prediction.realistic.commission)} />
                </p>
                <p className="text-sm text-gray-500">
                  <BlurredNumber value={formatEuro(prediction.realistic.commission)} />
                </p>
                <div className="mt-2 px-3 py-1.5 bg-blue-50 text-blue-700 rounded-lg text-sm inline-block">
                  ~{prediction.realistic.sales} ventes
                </div>
              </div>

              {/* Conservative */}
              <div>
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <div className="w-2 h-2 rounded-full bg-amber-400" />
                    <p className="text-sm text-gray-600">Conservateur (-20%)</p>
                  </div>
                  <div className="flex items-center gap-1 text-amber-400">
                    <ChevronDown className="w-4 h-4" />
                    <p className="text-xs">-20%</p>
                  </div>
                </div>
                <p className="text-xl font-bold text-gray-900">
                  <BlurredNumber value={formatCurrency(prediction.conservative.commission)} />
                </p>
                <p className="text-sm text-gray-500">
                  <BlurredNumber value={formatEuro(prediction.conservative.commission)} />
                </p>
                <div className="mt-2 px-3 py-1.5 bg-amber-50 text-amber-700 rounded-lg text-sm inline-block">
                  ~{prediction.conservative.sales} ventes
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Historical Chart */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6 }}
        className="bg-white rounded-xl border border-gray-200/50 overflow-hidden"
      >
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center gap-3">
            <TrendingUp className="w-6 h-6 text-blue-600" />
            <h3 className="text-xl font-semibold">Historique et Tendance</h3>
          </div>
        </div>
        
        <div className="p-6">
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={historicalData}>
                <defs>
                  <linearGradient id="colorCommission" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#1e3a5f" stopOpacity={0.1}/>
                    <stop offset="95%" stopColor="#1e3a5f" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis 
                  dataKey="month" 
                  tick={{ fill: '#6B7280', fontSize: 12 }}
                  tickLine={false}
                  axisLine={false}
                />
                <YAxis
                  tick={{ fill: '#6B7280', fontSize: 12 }}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={formatCurrency}
                />
                <Tooltip
                  formatter={(value: number) => [formatCurrency(value), 'Commission']}
                  contentStyle={{
                    backgroundColor: 'white',
                    border: '1px solid #E5E7EB',
                    borderRadius: '0.5rem'
                  }}
                />
                <Area
                  type="monotone"
                  dataKey="agencyCommission"
                  stroke="#1e3a5f"
                  fill="url(#colorCommission)"
                  strokeWidth={2}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </motion.div>
    </div>
  );
}