import React from 'react';
import { Key } from 'lucide-react';

interface GeneralTabProps {
  formData: {
    name: string;
    email: string;
  };
  setFormData: (data: any) => void;
  handleResetPasswords: () => void;
  isResettingPasswords: boolean;
}

export default function GeneralTab({
  formData,
  setFormData,
  handleResetPasswords,
  isResettingPasswords
}: GeneralTabProps) {
  return (
    <div className="space-y-6">
      <div>
        <label className="block text-[13px] font-medium text-[#98989f] mb-2">
          Nom de l'application
        </label>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="w-full px-4 py-3 bg-[#2c2c2e] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
        />
      </div>

      <div>
        <label className="block text-[13px] font-medium text-[#98989f] mb-2">
          Email de contact
        </label>
        <input
          type="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className="w-full px-4 py-3 bg-[#2c2c2e] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
        />
      </div>

      <div className="pt-6 border-t border-[#2c2c2e]">
        <div className="p-4 bg-[#3a2c29] rounded-xl border border-[#ff453a]/20">
          <p className="text-sm text-red-700 mb-4">
            Cette action réinitialisera les mots de passe de tous les agents à : LovePalmImmo
          </p>
          <button
            type="button"
            onClick={handleResetPasswords}
            disabled={isResettingPasswords}
            className="px-4 py-2 bg-[#ff453a] text-white rounded-xl hover:bg-[#ff453a]/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
          >
            <Key className="w-4 h-4" />
            {isResettingPasswords ? 'Réinitialisation...' : 'Réinitialiser tous les mots de passe'}
          </button>
        </div>
      </div>
    </div>
  );
}