import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, AlertTriangle, MessageSquare, ChevronRight } from 'lucide-react';
import type { Sale } from '../../types';
import { formatCurrency, formatEuro } from '../../utils/format';
import BlurredNumber from '../ui/BlurredNumber';

interface AdvanceCommissionCardProps {
  sale: Sale;
  commission: {
    agent: number;
    role: string;
  };
}

export default function AdvanceCommissionCard({ sale, commission }: AdvanceCommissionCardProps) {
  const advanceAmount = Math.floor(commission.agent * 0.8);
  const reductionAmount = commission.agent - advanceAmount;

  const handleWhatsAppClick = () => {
    const message = `Bonjour,\n\nJe souhaite demander une avance sur ma commission pour la vente suivante :\n\nProjet : ${sale.project}\nUnité : ${sale.unitNumber}\nCommission totale : ${formatCurrency(commission.agent)}\nMontant demandé (-20%) : ${formatCurrency(advanceAmount)}\n\nLe client a payé le DP et signé le SPA.\n\nMerci !`;
    window.open(`https://wa.me/971581998661?text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-[#1c1c1e] rounded-2xl overflow-hidden"
    >
      {/* Header */}
      <div className="px-6 py-4 border-b border-[#2c2c2e]">
        <div className="flex items-center gap-3">
          <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
            <DollarSign className="w-5 h-5 text-[#30d158]" />
          </div>
          <h3 className="text-[17px] font-semibold text-white">Besoin d'argent rapidement ?</h3>
        </div>
      </div>

      {/* Content */}
      <div className="p-6 space-y-6">
        {/* Explanation */}
        <div className="bg-[#2c2c2e] rounded-xl p-4">
          <div className="flex items-center gap-3 mb-3">
            <AlertTriangle className="w-5 h-5 text-[#0a84ff]" />
            <p className="text-[13px] text-[#98989f]">À propos des avances</p>
          </div>
          <p className="text-[15px] text-white leading-relaxed">
            Palm Dubai Immo peut vous faire une avance sur votre commission en cas d'urgence. 
            En échange d'un versement immédiat, une réduction de 20% est appliquée sur le montant total.
            Cette option est idéale si vous avez besoin de liquidités rapidement sans attendre le paiement du promoteur.
          </p>
        </div>

        {/* Available Amount */}
        <div className="bg-[#2c2c2e] rounded-xl p-4">
          <div className="flex items-center gap-3 mb-3">
            <DollarSign className="w-5 h-5 text-[#30d158]" />
            <p className="text-[13px] text-[#98989f]">Montant disponible aujourd'hui</p>
          </div>
          <p className="text-[22px] font-bold text-white mb-1">
            <BlurredNumber value={formatCurrency(advanceAmount)} />
          </p>
          <p className="text-[15px] text-[#98989f]">
            <BlurredNumber value={formatEuro(advanceAmount)} />
          </p>
          <div className="mt-3 flex items-center gap-2 px-3 py-2 bg-[#3a2c29] rounded-xl text-[#ff453a] text-[13px]">
            <AlertTriangle className="w-4 h-4" />
            <span>Frais d'avance : <BlurredNumber value={formatCurrency(reductionAmount)} /> (-20%)</span>
          </div>
        </div>

        {/* Requirements */}
        <div className="bg-[#2c2c2e] rounded-xl p-4">
          <div className="flex items-center gap-3 mb-3">
            <AlertTriangle className="w-5 h-5 text-[#0a84ff]" />
            <p className="text-[13px] text-[#98989f]">Conditions requises</p>
          </div>
          <ul className="space-y-3 text-[15px] text-white">
            <li className="flex items-center gap-2">
              <div className="w-2 h-2 rounded-full bg-[#30d158]" />
              <span>Down Payment payé ✓</span>
            </li>
            <li className="flex items-center gap-2">
              <div className="w-2 h-2 rounded-full bg-[#30d158]" />
              <span>SPA signé ✓</span>
            </li>
          </ul>
        </div>

        {/* WhatsApp Button */}
        <button
          onClick={handleWhatsAppClick}
          className="w-full flex items-center justify-between px-4 py-3 bg-[#2c2c2e] rounded-xl text-[#0a84ff]"
        >
          <div className="flex items-center gap-3">
            <MessageSquare className="w-5 h-5" />
            <span className="text-[15px] font-medium">Demander maintenant</span>
          </div>
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>
    </motion.div>
  );
}