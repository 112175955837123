import React from 'react';
import { motion } from 'framer-motion';
import { Users, DollarSign, Gift, TrendingUp, ChevronRight, MessageSquare } from 'lucide-react';

export default function ReferralBanner() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-br from-[#1c1c1e] to-[#2c2c2e] rounded-2xl overflow-hidden relative"
    >
      {/* Animated background elements */}
      <motion.div
        className="absolute top-0 right-0 w-96 h-96 bg-[#0a84ff]/5 rounded-full blur-3xl"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      <motion.div
        className="absolute bottom-0 left-0 w-96 h-96 bg-[#30d158]/5 rounded-full blur-3xl"
        animate={{
          scale: [1.2, 1, 1.2],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      <div className="relative p-6">
        <div className="flex flex-col md:flex-row items-start gap-8">
          {/* Content */}
          <div className="flex-1">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
              className="p-3 bg-[#0a84ff]/10 rounded-xl border border-[#0a84ff]/20 w-fit mb-6"
            >
              <Gift className="w-6 h-6 text-[#0a84ff]" />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <h2 className="text-[22px] font-semibold text-white mb-3">
                Programme de Parrainage
              </h2>
              <p className="text-[17px] text-[#0a84ff] font-medium mb-2">
                Gagnez 20% sur la première vente, puis 10% à vie !
              </p>
              <p className="text-[15px] text-[#98989f] mb-6">
                Développez votre équipe et générez des revenus passifs. Suivez les performances de vos filleuls directement depuis votre tableau de bord.
              </p>
            </motion.div>

            {/* Stats Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="bg-[#26382f] rounded-xl p-4 border border-[#30d158]/20"
              >
                <div className="flex items-center gap-2 mb-2">
                  <DollarSign className="w-4 h-4 text-[#30d158]" />
                  <span className="text-[13px] text-[#30d158] font-medium">Première vente</span>
                </div>
                <p className="text-[17px] text-white font-semibold">20% de commission</p>
                <p className="text-[13px] text-[#98989f] mt-1">Bonus de bienvenue</p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                className="bg-[#1c3a4f] rounded-xl p-4 border border-[#0a84ff]/20"
              >
                <div className="flex items-center gap-2 mb-2">
                  <TrendingUp className="w-4 h-4 text-[#0a84ff]" />
                  <span className="text-[13px] text-[#0a84ff] font-medium">Ventes suivantes</span>
                </div>
                <p className="text-[17px] text-white font-semibold">10% à vie</p>
                <p className="text-[13px] text-[#98989f] mt-1">Sur chaque vente</p>
              </motion.div>
            </div>
            
            {/* WhatsApp Button */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="mt-8"
            >
              <a
                href={`https://wa.me/971581998661?text=${encodeURIComponent('Je souhaite parrainer')}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-3 px-6 py-3 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors group"
              >
                <MessageSquare className="w-5 h-5" />
                Devenir parrain sur WhatsApp
                <ChevronRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
              </a>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}