import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Users, DollarSign, Gift, Calendar } from 'lucide-react';
import type { Sale } from '../../../types';
import { formatCurrency } from '../../../utils/format';
import { formatDateDisplay } from '../../../utils/date';
import BlurredNumber from '../../../components/ui/BlurredNumber';
import Avatar from '../../../components/ui/Avatar';

interface ReferralSalesCardProps {
  sales: Sale[];
  calculateCommission: (sale: Sale) => any;
  referredAgent: {
    id: string;
    name: string;
  };
}

export default function ReferralSalesCard({ sales, calculateCommission, referredAgent }: ReferralSalesCardProps) {
  // Calculate total commission from this referral
  const totalStats = sales.reduce((acc, sale) => {
    const commission = calculateCommission(sale);
    acc.totalCommission += commission.referrer || 0;
    if (sale.bonus?.secondAgentId === referredAgent.id) {
      acc.totalBonus += commission.bonus.referrer;
    }
    return acc;
  }, { totalCommission: 0, totalBonus: 0 });

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl border border-gray-200/50 overflow-hidden"
    >
      {/* Header */}
      <div className="p-4 border-b border-gray-200/50">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Avatar name={referredAgent.name} size="md" />
            <div>
              <Link 
                to={`/agents/${referredAgent.id}`}
                className="font-medium hover:text-blue-600 transition-colors"
              >
                {referredAgent.name}
              </Link>
              <p className="text-sm text-gray-500">{sales.length} ventes</p>
            </div>
          </div>
          <div className="text-right">
            <p className="text-lg font-bold">
              <BlurredNumber value={formatCurrency(totalStats.totalCommission)} />
            </p>
            {totalStats.totalBonus > 0 && (
              <div className="flex items-center gap-1 mt-1 text-purple-600">
                <Gift className="w-4 h-4" />
                <span className="text-sm font-medium">
                  +<BlurredNumber value={formatCurrency(totalStats.totalBonus)} />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Sales List */}
      <div className="divide-y divide-gray-200/50">
        {sales.map(sale => {
          const commission = calculateCommission(sale);
          const referralCommission = commission.referrer || 0;
          const bonusCommission = sale.bonus?.secondAgentId === referredAgent.id ? commission.bonus.referrer : 0;

          return (
            <div key={sale.id} className="p-4 hover:bg-gray-50/50">
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="font-medium">{sale.project}</h4>
                  <p className="text-sm text-gray-500">Unité {sale.unitNumber}</p>
                  <div className="flex items-center gap-2 mt-2 text-sm text-gray-500">
                    <Calendar className="w-4 h-4" />
                    <span>{formatDateDisplay(sale.saleDate)}</span>
                  </div>
                </div>
                <div className="text-right">
                  <p className="font-bold">
                    <BlurredNumber value={formatCurrency(referralCommission)} />
                  </p>
                  {bonusCommission > 0 && (
                    <div className="flex items-center gap-1 mt-1 text-purple-600">
                      <Gift className="w-4 h-4" />
                      <span className="text-sm font-medium">
                        +<BlurredNumber value={formatCurrency(bonusCommission)} />
                      </span>
                    </div>
                  )}
                  <div className={`mt-2 px-3 py-1 rounded-full text-sm font-medium inline-flex items-center gap-1 ${
                    sale.paymentStatus.referrer
                      ? 'bg-green-50 text-green-700 border border-green-200'
                      : 'bg-amber-50 text-amber-700 border border-amber-200'
                  }`}>
                    {sale.paymentStatus.referrer ? (
                      <>
                        <span className="w-2 h-2 rounded-full bg-green-500" />
                        Payée
                      </>
                    ) : (
                      <>
                        <span className="w-2 h-2 rounded-full bg-amber-500" />
                        En attente
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </motion.div>
  );
}