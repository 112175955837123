import React from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, TrendingDown, Building, User } from 'lucide-react';
import { useAgents } from '../../hooks/useAgents';
import { useSales } from '../../hooks/useSales';
import Avatar from '../ui/Avatar';
import { Link } from 'react-router-dom';

export default function UnderperformingAgents() {
  const { agents } = useAgents();
  const { sales } = useSales();

  // Get sales from the last 30 days
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  // Calculate performance for each agent
  const agentPerformance = agents.map(agent => {
    const recentSales = sales.filter(sale => {
      const saleDate = new Date(sale.saleDate);
      return saleDate >= thirtyDaysAgo && 
             (sale.agentId === agent.id || sale.referrerId === agent.id);
    });

    return {
      agent,
      salesCount: recentSales.length,
      isUnderperforming: recentSales.length < 2 // Target is 2 sales per 30 days
    };
  }).filter(data => data.isUnderperforming);

  if (agentPerformance.length === 0) {
    return null;
  }

  return (
    <div className="bg-[#1c1c1e] rounded-2xl overflow-hidden">
      {/* Header */}
      <div className="p-4 border-b border-[#2c2c2e]">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-xl bg-[#ff453a]/10 flex items-center justify-center">
            <AlertTriangle className="w-6 h-6 text-[#ff453a]" />
          </div>
          <div>
            <h3 className="text-[17px] font-semibold text-white">Agents en sous-performance</h3>
            <p className="text-[13px] text-[#98989f]">
              Moins de 2 ventes sur les 30 derniers jours
            </p>
          </div>
        </div>
      </div>

      <div className="divide-y divide-[#2c2c2e]">
        {agentPerformance.map(({ agent, salesCount }, index) => (
          <motion.div
            key={agent.id}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <Link
              to={`/agents/${agent.id}`}
              className="block p-4 hover:bg-[#2c2c2e] transition-all duration-200"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <Avatar name={agent.name} size="md" />
                  <div>
                    <h4 className="text-[17px] font-medium text-white">{agent.name}</h4>
                    <div className="flex items-center gap-2 mt-1">
                      <Building className="w-4 h-4 text-[#98989f]" />
                      <span className="text-[13px] text-[#98989f]">
                        {salesCount} vente{salesCount > 1 ? 's' : ''} en 30 jours
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2 px-3 py-1 bg-[#3a2c29] text-[#ff453a] rounded-xl text-[13px] font-medium">
                  <TrendingDown className="w-4 h-4" />
                  <span>Sous objectif</span>
                </div>
              </div>
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
}