import React from 'react';
import { motion } from 'framer-motion';
import { Trophy, Medal, Crown, Calendar } from 'lucide-react';
import { useAgents } from '../../../hooks/useAgents';
import { useSales } from '../../../hooks/useSales';
import Avatar from '../../../components/ui/Avatar';

export default function QuarterlyRanking() {
  const { agents } = useAgents();
  const { sales } = useSales();

  // Get current quarter dates
  const now = new Date();
  const currentQuarter = Math.floor(now.getMonth() / 3);
  const startDate = new Date(now.getFullYear(), currentQuarter * 3, 1);
  const endDate = new Date(now.getFullYear(), (currentQuarter + 1) * 3, 0);

  // Calculate rankings for the quarter
  const rankings = agents
    .map(agent => {
      const agentSales = sales.filter(sale => 
        (sale.agentId === agent.id || sale.referrerId === agent.id) &&
        new Date(sale.saleDate) >= startDate &&
        new Date(sale.saleDate) <= endDate
      );
      
      return {
        agent,
        salesCount: agentSales.length
      };
    })
    .sort((a, b) => b.salesCount - a.salesCount)
    .slice(0, 3);

  const getRankStyle = (index: number) => {
    switch (index) {
      case 0:
        return {
          icon: <Crown className="w-5 h-5 text-[#ffd60a]" />,
          iconBg: 'bg-[#ffd60a]/20',
          border: 'border-[#ffd60a]/20',
          text: 'text-[#ffd60a]'
        };
      case 1:
        return {
          icon: <Medal className="w-5 h-5 text-[#98989f]" />,
          iconBg: 'bg-[#98989f]/20',
          border: 'border-[#98989f]/20',
          text: 'text-[#98989f]'
        };
      case 2:
        return {
          icon: <Medal className="w-5 h-5 text-[#ff9f0a]" />,
          iconBg: 'bg-[#ff9f0a]/20',
          border: 'border-[#ff9f0a]/20',
          text: 'text-[#ff9f0a]'
        };
      default:
        return {
          bg: 'bg-[#2c2c2e]',
          border: 'border-[#3a3a3c]',
          text: 'text-white'
        };
    }
  };

  const quarterNames = ['1er', '2ème', '3ème', '4ème'];
  const currentQuarterName = quarterNames[currentQuarter];

  return (
    <div className="bg-[#1c1c1e] rounded-2xl overflow-hidden">
      {/* Header */}
      <div className="p-4 border-b border-[#2c2c2e]">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
            <Trophy className="w-6 h-6 text-[#0a84ff]" />
          </div>
          <div>
            <h3 className="text-[17px] font-medium text-white">Ranking du trimestre</h3>
            <div className="flex items-center gap-2 text-[13px] text-[#98989f]">
              <Calendar className="w-4 h-4" />
              <span>{currentQuarterName} trimestre {now.getFullYear()}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 space-y-4">
        {rankings.map((ranking, index) => {
          const style = getRankStyle(index);
          return (
            <motion.div
              key={ranking.agent.id}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`p-4 bg-[#2c2c2e] rounded-xl ${
                index === 0 ? 'bg-gradient-to-br from-[#1c1c1e] to-[#2c2c2e]' : ''
              }`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="relative">
                    <Avatar name={ranking.agent.name} size="md" />
                    <div className={`absolute -top-1 -right-1 p-1.5 bg-[#1c1c1e] rounded-full`}>
                      {style.icon}
                    </div>
                  </div>
                  <div>
                    <h3 className="text-[17px] font-medium text-white">
                      {ranking.agent.name}
                    </h3>
                    <div className="inline-flex items-center gap-1.5 mt-1 text-[13px] text-[#98989f]">
                      #{index + 1} 
                    </div>
                  </div>
                </div>
              </div>
              {/* Progress Bar */}
              <div className="mt-4 w-full bg-[#3a3a3c] h-1.5 rounded-full overflow-hidden">
                <div 
                  className={`h-full rounded-full ${
                    index === 0 ? 'bg-[#ffd60a]' :
                    index === 1 ? 'bg-[#98989f]' :
                    'bg-[#ff9f0a]'
                  }`}
                  style={{ width: `${(ranking.salesCount / rankings[0].salesCount) * 100}%` }}
                />
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}