import React from 'react';
import { Trophy, Medal } from 'lucide-react';
import { useAgents } from '../../hooks/useAgents';
import { useSales } from '../../hooks/useSales';
import Avatar from '../ui/Avatar';
import { formatCurrency, formatEuro } from '../../utils/format';
import BlurredNumber from '../ui/BlurredNumber';

export default function TopAgents() {
  const { agents } = useAgents();
  const { sales, calculateCommission } = useSales();

  // Calculate rankings based on number of sales since the beginning
  const rankings = agents
    .map(agent => {
      const agentSales = sales.filter(sale => 
        sale.agentId === agent.id || sale.referrerId === agent.id
      );
      
      const stats = agentSales.reduce((acc, sale) => {
        const commission = calculateCommission(sale);
        if (sale.agentId === agent.id) {
          acc.agentCommission += commission.agent;
          acc.agencyCommission += commission.agency;
          if (sale.bonus) {
            acc.agentCommission += commission.bonus.agent;
            acc.agencyCommission += commission.bonus.agency;
          }
        } else if (sale.referrerId === agent.id) {
          acc.agentCommission += commission.referrer || 0;
          if (sale.bonus?.secondAgentId === agent.id) {
            acc.agentCommission += commission.bonus.referrer;
          }
        }
        return acc;
      }, { agentCommission: 0, agencyCommission: 0 });

      return {
        agent,
        salesCount: agentSales.length,
        agentCommission: stats.agentCommission,
        agencyCommission: stats.agencyCommission
      };
    })
    .sort((a, b) => b.agentCommission - a.agentCommission)
    .slice(0, 3); // Only show top 3

  const getRankStyle = (index: number) => {
    switch (index) {
      case 0:
        return {
          icon: <Trophy className="w-5 h-5 text-[#ffd60a]" />,
          iconBg: 'bg-[#ffd60a]/20',
          border: 'border-[#ffd60a]/20',
          text: 'text-[#ffd60a]',
          badge: 'bg-[#ffd60a]/20 text-[#ffd60a]'
        };
      case 1:
        return {
          icon: <Medal className="w-5 h-5 text-[#98989f]" />,
          iconBg: 'bg-[#98989f]/20',
          border: 'border-[#98989f]/20',
          text: 'text-[#98989f]',
          badge: 'bg-[#98989f]/20 text-[#98989f]'
        };
      case 2:
        return {
          icon: <Medal className="w-5 h-5 text-[#ff9f0a]" />,
          iconBg: 'bg-[#ff9f0a]/20',
          border: 'border-[#ff9f0a]/20',
          text: 'text-[#ff9f0a]',
          badge: 'bg-[#ff9f0a]/20 text-[#ff9f0a]'
        };
      default:
        return {
          bg: 'bg-[#2c2c2e]',
          border: 'border-[#3a3a3c]',
          text: 'text-white',
          badge: 'bg-[#3a3a3c] text-white'
        };
    }
  };

  return (
    <div className="bg-[#1c1c1e] rounded-2xl overflow-hidden">
      {/* Header */}
      <div className="p-4 border-b border-[#2c2c2e]">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
            <Trophy className="w-6 h-6 text-[#0a84ff]" />
          </div>
          <div>
            <h3 className="text-[17px] font-medium text-white">Top 3 agents</h3>
            <p className="text-[13px] text-[#98989f]">Depuis le début</p>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="p-4 space-y-4">
      {rankings.map((ranking, index) => {
        const style = getRankStyle(index);
        return (
          <div 
            key={ranking.agent.id}
            className={`p-4 bg-[#2c2c2e] rounded-xl ${
              index === 0 ? 'bg-gradient-to-br from-[#1c1c1e] to-[#2c2c2e]' : ''
            }`}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="relative">
                  <Avatar name={ranking.agent.name} size="md" />
                  <div className={`absolute -top-1 -right-1 p-1.5 bg-[#1c1c1e] rounded-full`}>
                    {style.icon}
                  </div>
                </div>
                <div>
                  <h3 className="text-[17px] font-medium text-white">
                    {ranking.agent.name}
                  </h3>
                  <div className="inline-flex items-center gap-1.5 mt-1 text-[13px] text-[#98989f]">
                    #{index + 1} • {ranking.salesCount} ventes
                  </div>
                </div>
              </div>
              <div className="text-right space-y-2">
                <div>
                  <p className="text-[13px] text-[#98989f] mb-1">Commission agent</p>
                  <p className="text-[15px] font-bold text-white">
                    <BlurredNumber value={formatCurrency(ranking.agentCommission)} />
                  </p>
                  <p className="text-[13px] text-[#98989f]">
                    <BlurredNumber value={formatEuro(ranking.agentCommission)} />
                  </p>
                </div>
                <div>
                  <p className="text-[13px] text-[#98989f] mb-1">Commission agence</p>
                  <p className="text-[15px] font-bold text-white">
                    <BlurredNumber value={formatCurrency(ranking.agencyCommission)} />
                  </p>
                  <p className="text-[13px] text-[#98989f]">
                    <BlurredNumber value={formatEuro(ranking.agencyCommission)} />
                  </p>
                </div>
              </div>
            </div>
            {/* Progress Bar */}
            <div className="mt-4 w-full bg-[#3a3a3c] h-1.5 rounded-full overflow-hidden">
              <div 
                className={`h-full rounded-full ${
                  index === 0 ? 'bg-[#ffd60a]' :
                  index === 1 ? 'bg-[#98989f]' :
                  'bg-[#ff9f0a]'
                }`}
                style={{ width: `${(ranking.agentCommission / rankings[0].agentCommission) * 100}%` }}
              />
            </div>
          </div>
        );
      })}
      </div>
    </div>
  );
}