import type { Sale } from '../types';

export const COMMISSION_TYPES = {
  standard: { agent: 0.60, agency: 0.40 },
  shared: { agent: 0.50, agency: 0.50 },
  referral: { agent: 0.50, agency: 0.40, referrer: 0.10 },
  recruitment: { agent: 0.90, recruiter: 0.10 },
  direct_lead: { agent: 0.85, agency: 0.15 }, // First sale 15%
  direct_lead_referral: { agent: 0.80, agency: 0.15, referrer: 0.05 },
  shared_three_way: { agent: 0.30, agency: 0.40, referrer: 0.30 },
  terra_elite_70: { agent: 0.70, agency: 0.30 },
  terra_elite: { agent: 0.60, agency: 0.40, partner: 0.20 },
  manual: { agent: 0, agency: 0 }
} as const;

export function calculateCommission(sale: Sale) {
  // Base commission object
  let commission = {
    agent: 0,
    agency: 0,
    referrer: 0,
    bonus: {
      agent: 0,
      agency: 0,
      referrer: 0
    }
  };

  const totalCommission = sale.developerCommission;

  // Calculate main commission
  if (sale.commissionType === 'manual' && sale.manualCommissions) {
    // Use manual amounts directly
    commission.agent = sale.manualCommissions.agent;
    commission.agency = sale.manualCommissions.agency;

    // Handle additional agents
    if (sale.manualCommissions.additionalAgents) {
      commission.additionalAgents = sale.manualCommissions.additionalAgents.map(agent => {
        return {
          agentId: agent.agentId,
          amount: agent.amount
        };
      });

      // If referrer is among additional agents, set referrer commission
      const referrerAgent = sale.manualCommissions.additionalAgents.find(a => a.agentId === sale.referrerId);
      if (referrerAgent) {
        commission.referrer = referrerAgent.amount;
      }
    }
  } else {
    // Standard commission calculation
    const commissionType = COMMISSION_TYPES[sale.commissionType];

    commission.agent = Math.round(totalCommission * commissionType.agent);
    commission.agency = Math.round(totalCommission * commissionType.agency);

    if (commissionType.referrer && sale.referrerId) {
      commission.referrer = Math.round(totalCommission * commissionType.referrer);
    }
  }

  // Calculate bonus if exists
  if (sale.bonus) {
    const bonusAmount = sale.bonus.amount;
    
    if (sale.bonus.isManual) {
      // Use manual bonus distribution
      commission.bonus = {
        agent: sale.bonus.agents.find(a => a.agentId === sale.agentId)?.amount || 0,
        referrer: sale.bonus.agents.find(a => a.agentId === sale.referrerId)?.amount || 0,
        agency: sale.bonus.agencyAmount || 0
      };
    } else if (sale.bonus.secondAgentId) {
      // Three-way split for bonus (30/30/40)
      commission.bonus = {
        agent: Math.round(bonusAmount * 0.3),
        referrer: Math.round(bonusAmount * 0.3),
        agency: Math.round(bonusAmount * 0.4)
      };
    } else {
      // Standard split for bonus (60/40)
      commission.bonus = {
        agent: Math.round(bonusAmount * 0.6),
        agency: Math.round(bonusAmount * 0.4),
        referrer: 0
      };
    }
  }

  return commission;
}

export function getCommissionTypes() {
  return [
    { value: 'direct_lead', label: 'Lead direct (85/15)' }, // First sale 15%
    { value: 'standard', label: 'Standard (60/40)' },
    { value: 'terra_elite_70', label: 'Terra Elite (70/30)' },
    { value: 'shared', label: 'Partagée (50/50)' },
    { value: 'shared_three_way', label: 'Trois parties (30/30/40)' },
    { value: 'referral', label: 'Parrainage (50/40/10)' },
    { value: 'direct_lead_referral', label: 'Lead direct + Parrain (80/15/5)' },
    { value: 'terra_elite', label: 'Terra Elite (60/40 sur 5%)' },
    { value: 'manual', label: 'Manuel' }
  ];
}