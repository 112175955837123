import React from 'react';
import { Users } from 'lucide-react';
import type { Sale } from '../../../types';
import ReferralSalesCard from './ReferralSalesCard';

interface ReferralSalesSectionProps {
  sales: Sale[];
  calculateCommission: (sale: Sale) => any;
  agentId: string;
  agents: Array<{ id: string; name: string; }>;
}

export default function ReferralSalesSection({ sales, calculateCommission, agentId, agents }: ReferralSalesSectionProps) {
  // Group sales by referred agent
  const referralSales = new Map<string, Sale[]>();
  
  sales.forEach(sale => {
    if (sale.agentId !== agentId && sale.referrerId === agentId) {
      const agentSales = referralSales.get(sale.agentId) || [];
      referralSales.set(sale.agentId, [...agentSales, sale]);
    }
  });

  if (referralSales.size === 0) {
    return null;
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-3">
        <Users className="w-6 h-6 text-blue-600" />
        <h2 className="text-xl font-semibold">Ventes de mes filleuls</h2>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {Array.from(referralSales.entries()).map(([agentId, sales]) => {
          const agent = agents.find(a => a.id === agentId);
          if (!agent) return null;

          return (
            <ReferralSalesCard
              key={agentId}
              sales={sales}
              calculateCommission={calculateCommission}
              referredAgent={agent}
            />
          );
        })}
      </div>
    </div>
  );
}