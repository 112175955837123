import React from 'react';
import { motion } from 'framer-motion';
import { Brain, TrendingUp, Calendar, DollarSign, Target, ChevronUp, ChevronDown, Minus, Lightbulb, Rocket, Trophy, Sparkles, Target as TargetIcon, Zap } from 'lucide-react';
import { useSales } from '../../hooks/useSales';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import { formatCurrency, formatEuro } from '../../utils/format';
import BlurredNumber from '../../components/ui/BlurredNumber';

export default function AgentEstimation() {
  const { agent } = useAgentSlug();
  const { sales, calculateCommission } = useSales();
  if (!agent) return null;
  
  // Get all sales for this agent
  const agentSales = sales.filter(sale => sale.agentId === agent.id || sale.referrerId === agent.id).sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime());

  // Calculate monthly average based on first sale date
  const calculateMonthlyAverage = () => {
    if (agentSales.length === 0) return 0;
    const firstSale = agentSales[agentSales.length - 1];
    const firstDate = new Date(firstSale.saleDate);
    const lastDate = new Date();
    const monthsDiff = Math.max(1, (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24 * 30.44));
    
    // Calculate total commission
    const totalCommission = agentSales.reduce((sum, sale) => {
      const commission = calculateCommission(sale);
      return sum + (sale.agentId === agent.id ? commission.agent : commission.referrer || 0);
    }, 0);

    return totalCommission / monthsDiff;
  };

  const monthlyAverage = calculateMonthlyAverage();
  const monthlyAverageSales = agentSales.length === 0 ? 0 : agentSales.length / Math.max(1, (new Date().getTime() - new Date(agentSales[agentSales.length - 1].saleDate).getTime()) / (1000 * 60 * 60 * 24 * 30.44));

  // Generate predictions for different timeframes
  const projections = {
    threeMonths: {
      optimistic: monthlyAverage * 3 * 1.2,
      realistic: monthlyAverage * 3,
      conservative: monthlyAverage * 3 * 0.8
    },
    sixMonths: {
      optimistic: monthlyAverage * 6 * 1.2,
      realistic: monthlyAverage * 6,
      conservative: monthlyAverage * 6 * 0.8
    },
    endOfYear: {
      optimistic: monthlyAverage * (12 - new Date().getMonth()) * 1.2,
      realistic: monthlyAverage * (12 - new Date().getMonth()),
      conservative: monthlyAverage * (12 - new Date().getMonth()) * 0.8
    }
  };

  return (
    <div className="min-h-screen bg-black">
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-lg mx-auto px-4 pt-4 pb-4">
          <div className="flex items-center gap-6">
            <motion.div
              initial={{ scale: 0.8 }}
              animate={{ scale: [0.8, 1.1, 0.9, 1] }}
              transition={{ duration: 2, repeat: Infinity, repeatType: "reverse", ease: "easeInOut" }}
              className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center"
            >
              <Brain className="w-6 h-6 text-[#0a84ff]" />
            </motion.div>
            <div>
              <div className="flex items-center gap-4">
                <h1 className="text-[22px] font-semibold text-white">Estimation IA</h1>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="px-3 py-1 bg-[#0a84ff]/10 rounded-xl text-[13px] font-medium flex items-center gap-2 border border-[#0a84ff]/20"
                >
                  <Brain className="w-4 h-4 text-[#0a84ff]" />
                  <span className="text-[#0a84ff]">Propulsé par OpenAI</span>
                </motion.div>
              </div>
              <h3 className="text-[17px] font-semibold text-white">Objectifs Quotidiens</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-lg mx-auto px-4 py-4 space-y-4">
        <div className="grid grid-cols-3 gap-4">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="bg-[#2c2c2e] rounded-xl p-4"
          >
            <div className="flex items-center gap-3 mb-4">
              <div className="w-8 h-8 rounded-full bg-[#0a84ff]/10 flex items-center justify-center">
                <DollarSign className="w-5 h-5 text-[#0a84ff]" />
              </div>
              <p className="text-[13px] text-[#98989f]">Revenus moyens</p>
            </div>
            <p className="text-[17px] font-bold text-white mb-1">
              <BlurredNumber value={formatCurrency(monthlyAverage)} />
            </p>
            <p className="text-[13px] text-[#98989f]">
              <BlurredNumber value={formatEuro(monthlyAverage)} />
            </p>
          </motion.div>

          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.1 }}
            className="bg-[#2c2c2e] rounded-xl p-4"
          >
            <div className="flex items-center gap-3 mb-4">
              <div className="w-8 h-8 rounded-full bg-[#30d158]/10 flex items-center justify-center">
                <Target className="w-5 h-5 text-[#30d158]" />
              </div>
              <p className="text-[13px] text-[#98989f]">Ventes mensuelles</p>
            </div>
            <p className="text-[17px] font-bold text-white mb-1">{monthlyAverageSales.toFixed(1)}</p>
            <p className="text-[13px] text-[#98989f]">ventes en moyenne</p>
          </motion.div>

          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="bg-[#2c2c2e] rounded-xl p-4"
          >
            <div className="flex items-center gap-3 mb-4">
              <div className="w-8 h-8 rounded-full bg-[#ff9f0a]/10 flex items-center justify-center">
                <TrendingUp className="w-5 h-5 text-[#ff9f0a]" />
              </div>
              <p className="text-[13px] text-[#98989f]">Total des ventes</p>
            </div>
            <p className="text-[17px] font-bold text-white mb-1">{agentSales.length}</p>
            <p className="text-[13px] text-[#98989f]">depuis le début</p>
          </motion.div>
        </div>

        <div className="space-y-8">
          <motion.div 
            initial={{ opacity: 0, y: 20 }} 
            animate={{ opacity: 1, y: 0 }} 
            className="bg-[#2c2c2e] rounded-xl overflow-hidden"
          >
            <div className="p-4 border-b border-[#3a3a3c]">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-xl bg-[#30d158]/10 flex items-center justify-center">
                  <Target className="w-6 h-6 text-[#30d158]" />
                </div>
                <div>
                  <h3 className="text-[17px] font-semibold text-white">Projection à 3 mois</h3>
                  <p className="text-[13px] text-[#98989f]">Scénario optimiste</p>
                </div>
              </div>
            </div>
            
            <div className="relative">
              {/* Neural Network Animation Background */}
              <div className="absolute inset-0 overflow-hidden">
                <motion.div
                  className="absolute inset-0"
                  initial={{ backgroundPosition: '0% 0%' }}
                  animate={{ backgroundPosition: '100% 100%' }}
                  transition={{ duration: 20, repeat: Infinity, repeatType: "reverse", ease: "linear" }}
                  style={{
                    backgroundImage: `radial-gradient(circle at 30% 30%, rgba(16, 163, 127, 0.15) 0%, transparent 50%),
                                    radial-gradient(circle at 70% 70%, rgba(16, 163, 127, 0.15) 0%, transparent 50%)`,
                    backgroundSize: '100% 100%'
                  }}
                />
              </div>
              
              {/* Timeline Content */}
              <div className="relative p-6">
                <div className="flex items-center gap-4 mb-6">
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.2 }}
                    className="flex-1 p-4 bg-[#2c2c2e] rounded-xl border border-[#3a3a3c]"
                  >
                    <div className="flex items-center gap-2 mb-2">
                      <ChevronUp className="w-4 h-4 text-[#30d158]" />
                      <span className="text-[#30d158] text-[13px]">+20%</span>
                    </div>
                    <p className="text-[15px] text-white">Scénario optimiste</p>
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.3 }}
                    className="flex-1 p-4 bg-[#2c2c2e] rounded-xl border border-[#3a3a3c]"
                  >
                    <div className="flex items-center gap-2 mb-2">
                      <Target className="w-4 h-4 text-[#0a84ff]" />
                      <span className="text-[#0a84ff] text-[13px]">Objectif</span>
                    </div>
                    <p className="text-[15px] text-white">
                      <BlurredNumber value={formatCurrency(projections.threeMonths.optimistic)} />
                    </p>
                  </motion.div>
                </div>

                {/* Interactive Timeline */}
                <div className="space-y-4">
                  {[1, 2, 3].map((month, index) => (
                    <motion.div
                      key={month}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.4 + (index * 0.2) }}
                      className="p-4 bg-[#2c2c2e] rounded-xl border border-[#3a3a3c]"
                    >
                      <div className="flex items-center justify-between mb-3">
                        <div className="flex items-center gap-2">
                          <Calendar className="w-4 h-4 text-[#0a84ff]" />
                          <span className="text-[15px] text-white">Mois {month}</span>
                        </div>
                        <div className="flex items-center gap-1 px-2 py-1 bg-[#30d158]/10 rounded-full">
                          <ChevronUp className="w-3 h-3 text-[#30d158]" />
                          <span className="text-[13px] text-[#30d158]">+{(20 * month).toFixed(1)}%</span>
                        </div>
                      </div>

                      <div className="space-y-3">
                        <div>
                          <p className="text-[13px] text-[#98989f] mb-1">Commission</p>
                          <p className="text-[17px] font-bold text-white">
                            <BlurredNumber value={formatCurrency(projections.threeMonths.optimistic / 3 * month)} />
                          </p>
                          <p className="text-[13px] text-[#98989f]">
                            <BlurredNumber value={formatEuro(projections.threeMonths.optimistic / 3 * month)} />
                          </p>
                        </div>

                        <div className="p-3 bg-[#3a3a3c] rounded-xl">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                              <Target className="w-4 h-4 text-[#0a84ff]" />
                              <span className="text-[13px] text-[#98989f]">Objectif</span>
                            </div>
                            <span className="text-[15px] text-white">
                              {Math.ceil(monthlyAverageSales * 1.2 * month)} ventes
                            </span>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>

          {/* Motivational iMessage Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="bg-[#2c2c2e] rounded-xl overflow-hidden"
          >
            <div className="p-4 border-b border-[#3a3a3c]">
              <div className="flex items-center gap-3 ">
                <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                  <Rocket className="w-6 h-6 text-[#0a84ff]" />
                </div>
                <h3 className="text-[17px] font-semibold text-white">Objectifs Quotidiens</h3>
              </div>
            </div>
            <div className="p-4 space-y-4">

              {[
                { text: "10 appels leads par jour", icon: Zap },
                { text: "3 présentations projet par semaine", icon: Rocket },
                { text: "1 vente par semaine", icon: Trophy },
                { text: "Contactez vos leads dans l'heure", icon: Target },
                { text: "Faites des suivis réguliers", icon: Calendar },
                { text: "Utilisez le CRM quotidiennement", icon: Brain },
                { text: "Répondez rapidement aux leads", icon: Zap },
                { text: "Utilisez les vidéos du projet", icon: Rocket },
                { text: "Partagez les success stories", icon: Trophy }
              ].map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 * index }}
                  className="flex items-center gap-3 p-4 bg-[#3a3a3c] rounded-xl"
                >
                  <div className="w-8 h-8 rounded-full bg-[#0a84ff]/10 flex items-center justify-center">
                    <item.icon className="w-5 h-5 text-[#0a84ff]" />
                  </div>
                  <p className="text-[15px] text-white">{item.text}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>
    </div>
  );
}