import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import { useSales } from '../../hooks/useSales';
import { Building, Search, Calendar, DollarSign, Gift, ChevronRight } from 'lucide-react';
import { formatCurrency, formatEuro } from '../../utils/format';
import { formatDateDisplay } from '../../utils/date';
import BlurredNumber from '../../components/ui/BlurredNumber';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../lib/firebase';

export default function AgentSales() {
  const { agent, slug } = useAgentSlug();
  const { sales, calculateCommission } = useSales();
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState<'all' | 'pending' | 'paid'>('all');
  const [bonusPayments, setBonusPayments] = useState<any[]>([]);

  useEffect(() => {
    if (!agent) return;

    // Subscribe to bonus payments
    const q = query(
      collection(db, 'bonusPayments'),
      where('agentId', '==', agent.id),
      orderBy('paymentDate', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const payments = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        paymentDate: doc.data().paymentDate
      }));
      setBonusPayments(payments);
    });

    return () => unsubscribe();
  }, [agent]);

  if (!agent) return null;

  // Get all sales where agent is involved
  const agentSales = sales.filter(sale => 
    // Include sales where agent is:
    // 1. Primary agent
    // 2. Referrer/Secondary agent
    // 3. Additional agent in manual commissions
    // 4. Second agent in bonus
    sale.agentId === agent.id ||
    sale.referrerId === agent.id ||
    sale.manualCommissions?.additionalAgents?.some(a => a.agentId === agent.id) ||
    sale.bonus?.secondAgentId === agent.id
  );

  // Calculate monthly volume data
  const monthlyVolume = agentSales.reduce((acc, sale) => {
    const date = new Date(sale.saleDate);
    const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
    
    if (!acc[monthKey]) {
      acc[monthKey] = {
        month: monthKey,
        volume: 0,
        count: 0
      };
    }
    
    acc[monthKey].volume += sale.propertyPrice;
    acc[monthKey].count += 1;
    
    return acc;
  }, {} as Record<string, { month: string; volume: number; count: number; }>);

  const chartData = Object.values(monthlyVolume).slice(-6);

  // Filter sales based on search query and active tab
  const filteredSales = agentSales
    .filter(sale => {
      // Filter by tab
      if (activeTab === 'paid' && !sale.paymentStatus.agent) {
        return false;
      }
      if (activeTab === 'pending' && sale.paymentStatus.agent) {
        return false;
      }

      // Filter by search
      if (!searchQuery) return true;
      
      const query = searchQuery.toLowerCase();
      return (
        sale.project.toLowerCase().includes(query) ||
        sale.unitNumber.toLowerCase().includes(query)
      );
    })
    .sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime());

  // Group sales by month
  const salesByMonth = filteredSales.reduce((acc, sale) => {
    const date = new Date(sale.saleDate);
    const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
    
    if (!acc[monthKey]) {
      acc[monthKey] = [];
    }
    acc[monthKey].push(sale);
    return acc;
  }, {} as Record<string, typeof filteredSales>);

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e] sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-lg mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Building className="w-6 h-6 text-[#0a84ff]" />
              <div>
                <h1 className="text-[17px] font-semibold text-white">Mes Ventes</h1>
                <p className="text-[13px] text-[#98989f]">
                  {filteredSales.length} ventes
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="px-4 py-2 border-b border-[#2c2c2e]">
          <div className="max-w-lg mx-auto flex gap-4">
            <button
              onClick={() => setActiveTab('all')}
              className={`text-[15px] font-medium px-3 py-2 rounded-lg transition-colors ${
                activeTab === 'all' ? 'text-[#0a84ff]' : 'text-[#98989f]'
              }`}
            >
              Toutes
            </button>
            <button
              onClick={() => setActiveTab('pending')}
              className={`text-[15px] font-medium px-3 py-2 rounded-lg transition-colors ${
                activeTab === 'pending' ? 'text-[#0a84ff]' : 'text-[#98989f]'
              }`}
            >
              En attente
            </button>
            <button
              onClick={() => setActiveTab('paid')}
              className={`text-[15px] font-medium px-3 py-2 rounded-lg transition-colors ${
                activeTab === 'paid' ? 'text-[#0a84ff]' : 'text-[#98989f]'
              }`}
            >
              Payées
            </button>
          </div>
        </div>

        {/* Search */}
        <div className="px-4 py-2 border-b border-[#2c2c2e]">
          <div className="max-w-lg mx-auto">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
              <input
                type="text"
                placeholder="Rechercher une vente..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 bg-[#2c2c2e] text-white placeholder-[#98989f] rounded-lg focus:ring-2 focus:ring-[#0a84ff] border-none"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Sales List */}
      <div className="max-w-lg mx-auto px-4 space-y-8">
        {/* Manual Bonus Payments */}
        {bonusPayments.length > 0 && (
          <div className="space-y-4">
            <h2 className="text-[15px] font-medium text-[#98989f] px-4">
              Bonus manuels
            </h2>
            {bonusPayments.map((bonus) => (
              <div
                key={bonus.id}
                className="block bg-[#2c2c2e] rounded-xl overflow-hidden"
              >
                <div className="p-4">
                  <div className="flex flex-col gap-4">
                    <div className="flex items-start justify-between">
                      <div>
                        <h3 className="text-[17px] font-semibold text-white">{bonus.description}</h3>
                      </div>
                      <div className="text-right">
                        <div className="flex items-center gap-2">
                          <Gift className="w-4 h-4 text-[#bf5af2]" />
                          <p className="text-[17px] font-bold text-[#bf5af2]">
                            <BlurredNumber value={formatCurrency(bonus.amount)} />
                          </p>
                        </div>
                        <p className="text-[13px] text-[#98989f]">
                          <BlurredNumber value={formatEuro(bonus.amount)} />
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center justify-between mt-2">
                      <div className="flex items-center gap-2 text-[13px] text-[#98989f]">
                        <Calendar className="w-4 h-4 text-[#0a84ff]" />
                        <span>{formatDateDisplay(bonus.paymentDate)}</span>
                      </div>
                      <div className="bg-[#26382f] text-[#30d158] px-3 py-1 rounded-xl text-[13px] font-medium">
                        Payé
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {Object.entries(salesByMonth).map(([month, monthSales]) => (
          <div key={month} className="space-y-4">
            <h2 className="text-[15px] font-medium text-[#98989f] px-4">
              {month}
            </h2>

            {monthSales.map(sale => {
              const commission = calculateCommission(sale);
              let myCommission = 0;
              let role = '';
              let isPaid = false;

              // Calculate commission and role based on agent's involvement
              if (sale.agentId === agent.id) {
                myCommission = commission.agent;
                role = 'Agent principal';
                isPaid = sale.paymentStatus.agent;
              } else if (sale.referrerId === agent.id) {
                myCommission = commission.referrer || 0;
                role = 'Parrain';
                isPaid = sale.paymentStatus.referrer;
              } else if (sale.manualCommissions?.additionalAgents) {
                const additionalAgent = sale.manualCommissions.additionalAgents.find(a => a.agentId === agent.id);
                if (additionalAgent) {
                  myCommission = additionalAgent.amount;
                  role = 'Agent supplémentaire';
                  const index = sale.manualCommissions.additionalAgents.findIndex(a => a.agentId === agent.id);
                  isPaid = sale.paymentStatus.additionalAgents?.[index] || false;
                }
              } else if (sale.bonus?.secondAgentId === agent.id) {
                myCommission = commission.bonus.referrer;
                role = 'Agent bonus';
                isPaid = sale.paymentStatus.bonus;
              }

              return (
                <Link
                  key={sale.id}
                  to={`/agentcrm/${slug}/sales/${sale.id}`}
                  className="block bg-[#2c2c2e] rounded-xl overflow-hidden"
                >
                  <div className="p-4">
                    <div className="flex flex-col gap-4">
                      <div className="flex items-start justify-between">
                        <div>
                          <h3 className="text-[17px] font-semibold text-white">{sale.project}</h3>
                          <p className="text-[15px] text-[#98989f]">Unité {sale.unitNumber}</p>
                        </div>
                        <div className="text-right">
                          <p className="text-[17px] font-bold text-white">
                            <BlurredNumber value={formatCurrency(myCommission || 0)} />
                          </p>
                          <p className="text-[13px] text-[#98989f]">
                            <BlurredNumber value={formatEuro(myCommission || 0)} />
                          </p>
                          <p className="text-[13px] text-[#98989f] mt-1">{role}</p>
                        </div>
                      </div>

                      <div className="flex items-center justify-between mt-2">
                        <div className="flex items-center gap-2 text-[13px] text-[#98989f]">
                          <Calendar className="w-4 h-4 text-[#0a84ff]" />
                          <span>{formatDateDisplay(sale.saleDate)}</span>
                        </div>
                        <div className={`
                          px-3 py-1 rounded-xl text-[13px] font-medium
                          ${isPaid 
                            ? 'bg-[#26382f] text-[#30d158]'
                            : 'bg-[#3a2c29] text-[#ff453a]'
                          }
                        `}>
                          {isPaid ? 'Payée' : 'En attente'}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        ))}

        {filteredSales.length === 0 && (
          <div className="text-center py-12">
            <Building className="w-12 h-12 text-[#98989f] mx-auto mb-4" />
            <p className="text-[#98989f]">Aucune vente trouvée</p>
          </div>
        )}

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>
    </div>
  );
}