import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, Clock, Check, MessageSquare, ChevronRight } from 'lucide-react';
import type { Sale } from '../../types';
import { formatCurrency, formatEuro } from '../../utils/format';
import BlurredNumber from '../ui/BlurredNumber';

interface PaymentStatusCardProps {
  sale: Sale;
  commission: {
    agent: number;
    agency: number;
    referrer?: number;
    myCommission: number;
    myRole: string;
    myPaymentStatus: boolean;
  };
}

export default function PaymentStatusCard({ sale, commission }: PaymentStatusCardProps) {
  const handleWhatsAppClick = () => {
    const message = `Bonjour,\n\nJe souhaite avoir un statut sur ma commission pour la vente suivante :\n\nProjet : ${sale.project}\nUnité : ${sale.unitNumber}\nCommission : ${formatCurrency(commission.myCommission)}\n\nMerci !`;
    window.open(`https://wa.me/971581998661?text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-[#1c1c1e] rounded-2xl overflow-hidden"
    >
      {/* Header */}
      <div className="px-6 py-4 border-b border-[#2c2c2e]">
        <div className="flex items-center gap-3">
          <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
            <DollarSign className="w-5 h-5 text-[#0a84ff]" />
          </div>
          <h3 className="text-[17px] font-semibold text-white">Statut des paiements</h3>
        </div>
      </div>

      {/* Content */}
      <div className="p-6 space-y-6">
        {/* Developer Commission */}
        <div className="bg-[#2c2c2e] rounded-xl p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <DollarSign className="w-5 h-5 text-[#0a84ff]" />
              <div>
                <p className="text-[13px] text-[#98989f] mb-1">Commission promoteur</p>
                <p className="text-[17px] text-white font-medium">
                  <BlurredNumber value={formatCurrency(sale.developerCommission)} />
                </p>
              </div>
            </div>
            <div className={`px-3 py-1 rounded-xl text-[13px] font-medium ${
              sale.paymentStatus.developer
                ? 'bg-[#26382f] text-[#30d158]'
                : 'bg-[#3a2c29] text-[#ff453a]'
            }`}>
              {sale.paymentStatus.developer ? (
                <div className="flex items-center gap-1">
                  <Check className="w-4 h-4" />
                  <span>Reçue</span>
                </div>
              ) : (
                <div className="flex items-center gap-1">
                  <Clock className="w-4 h-4" />
                  <span>En attente</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Agent Commission */}
        <div className="bg-[#2c2c2e] rounded-xl p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <DollarSign className="w-5 h-5 text-[#0a84ff]" />
              <div>
                <p className="text-[13px] text-[#98989f] mb-1">Ma commission</p>
                <p className="text-[17px] text-white font-medium">
                  <BlurredNumber value={formatCurrency(commission.myCommission)} />
                </p>
              </div>
            </div>
            <div className={`px-3 py-1 rounded-xl text-[13px] font-medium ${
              commission.myPaymentStatus
                ? 'bg-[#26382f] text-[#30d158]'
                : 'bg-[#3a2c29] text-[#ff453a]'
            }`}>
              {commission.myPaymentStatus ? (
                <div className="flex items-center gap-1">
                  <Check className="w-4 h-4" />
                  <span>Payée</span>
                </div>
              ) : (
                <div className="flex items-center gap-1">
                  <Clock className="w-4 h-4" />
                  <span>En attente</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* WhatsApp Button */}
        <button
          onClick={handleWhatsAppClick}
          className="w-full flex items-center justify-between px-4 py-3 bg-[#2c2c2e] rounded-xl text-[#0a84ff]"
        >
          <div className="flex items-center gap-3">
            <MessageSquare className="w-5 h-5" />
            <span className="text-[15px] font-medium">Demander le statut</span>
          </div>
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>
    </motion.div>
  );
}