import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  Building, Calendar, DollarSign, ChevronLeft, Edit, 
  Trash2, FileText, User, Mail, Phone, Gift, ChevronRight,
  Check, Clock, MessageSquare, Share2, Users
} from 'lucide-react';
import { useSales } from '../hooks/useSales';
import { useAgents } from '../hooks/useAgents';
import { useAuth } from '../hooks/useAuth';
import SaleForm from '../components/SaleForm';
import { generateSaleReport } from '../services/pdfService';
import Avatar from '../components/ui/Avatar';
import PaymentToggle from '../components/ui/PaymentToggle';
import { getCommissionTypes, COMMISSION_TYPES } from '../utils/commission';
import { formatCurrency, formatEuro } from '../utils/format';
import { formatDateDisplay } from '../utils/date';
import BlurredNumber from '../components/ui/BlurredNumber';

interface SaleDetailsProps {
  isEditing?: boolean;
}

export default function SaleDetails({ isEditing = false }: SaleDetailsProps) {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { sales, calculateCommission, updateSale, deleteSale } = useSales();
  const { agents } = useAgents();
  const { user } = useAuth();
  const [showEditForm, setShowEditForm] = useState(isEditing);

  const sale = sales.find((s) => s.id === id);
  if (!sale) return <div>Vente non trouvée</div>;

  const agent = agents.find((a) => a.id === sale.agentId);
  const referrer = sale.referrerId ? agents.find((a) => a.id === sale.referrerId) : null;
  const secondBonusAgent = sale.bonus?.secondAgentId ? agents.find((a) => a.id === sale.bonus?.secondAgentId) : null;
  const additionalAgents = sale.manualCommissions?.additionalAgents?.map(a => ({
    agent: agents.find(agent => agent.id === a.agentId),
    amount: a.amount
  })).filter(a => a.agent) || [];
  const commission = calculateCommission(sale);

  const handleDelete = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette vente ?')) {
      await deleteSale(sale.id);
      navigate('/sales');
    }
  };

  const handleGeneratePDF = () => {
    const doc = generateSaleReport(sale, agent, referrer);
    const fileName = `vente-${sale.project.toLowerCase()}-${sale.unitNumber}-${
      new Date().toISOString().split('T')[0]
    }.pdf`;
    doc.save(fileName);
  };

  const handlePaymentStatusChange = async (
    type: 'developer' | 'agent' | 'referrer' | 'bonus' | string,
    status: boolean
  ) => {
    const updatedPaymentStatus = {
      ...sale.paymentStatus,
    };

    // Handle additional agents payment status
    if (type.startsWith('additionalAgents.')) {
      const index = parseInt(type.split('.')[1]);
      updatedPaymentStatus.additionalAgents = updatedPaymentStatus.additionalAgents || [];
      updatedPaymentStatus.additionalAgents[index] = status;
    } else {
      updatedPaymentStatus[type] = status;
    }

    await updateSale(sale.id, {
      paymentStatus: updatedPaymentStatus,
    });
  };

  const handleClientPaymentChange = async (
    type: 'eoi' | 'downPayment' | 'spa',
    status: boolean
  ) => {
    const updatedClient = {
      ...sale.client,
      payments: {
        ...sale.client.payments,
        [type]: {
          status,
          date: status ? new Date().toISOString() : undefined
        }
      }
    };

    await updateSale(sale.id, {
      client: updatedClient
    });
  };

  const getCommissionTypeLabel = (type: string) => {
    const commissionType = getCommissionTypes().find(t => t.value === type);
    return commissionType?.label || type;
  };

  const getClientProfileUrl = () => {
    if (sale.client.email) {
      return `/clients/${encodeURIComponent(sale.client.email)}`;
    }
    if (sale.client.phone) {
      return `/clients/${encodeURIComponent(sale.client.phone)}`;
    }
    return null;
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-lg mx-auto px-4 py-3">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate(-1)}
                className="p-2 hover:bg-[#2c2c2e] rounded-xl transition-colors"
              >
                <ChevronLeft className="w-6 h-6 text-[#0a84ff]" />
              </button>
              <div>
                <h1 className="text-[22px] font-semibold text-white">{sale.project}</h1>
                <p className="text-[15px] text-[#98989f]">Unité {sale.unitNumber}</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={handleGeneratePDF}
                className="p-2 text-[#0a84ff] hover:bg-[#0a84ff]/10 rounded-xl transition-colors"
              >
                <FileText className="w-4 h-4" />
              </button>
              <button
                onClick={() => setShowEditForm(true)}
                className="p-2 text-[#0a84ff] hover:bg-[#0a84ff]/10 rounded-xl transition-colors"
              >
                <Edit className="w-5 h-5" />
              </button>
              <button
                onClick={handleDelete}
                className="p-2 text-[#ff453a] hover:bg-[#ff453a]/10 rounded-xl transition-colors"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-lg mx-auto px-4 py-4 space-y-4">
        {/* Price Card */}
        <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
          <div className="grid grid-cols-2 divide-x divide-[#3a3a3c]">
            {/* Price */}
            <div className="p-6">
              <div className="flex items-center gap-3 mb-4">
                <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
                  <DollarSign className="w-5 h-5 text-[#0a84ff]" />
                </div>
                <p className="text-[13px] text-[#98989f]">Prix du bien</p>
              </div>
              <div>
                <p className="text-[28px] font-bold text-white mb-1">
                  <BlurredNumber value={formatCurrency(sale.propertyPrice)} />
                </p>
                <p className="text-[13px] text-[#98989f]">
                  <BlurredNumber value={formatEuro(sale.propertyPrice)} />
                </p>
              </div>
            </div>

            {/* Date */}
            <div className="p-6">
              <div className="flex items-center gap-3 mb-4">
                <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
                  <Calendar className="w-5 h-5 text-[#0a84ff]" />
                </div>
                <p className="text-[13px] text-[#98989f]">Date de vente</p>
              </div>
              <p className="text-[17px] text-white">{formatDateDisplay(sale.saleDate)}</p>
            </div>
          </div>
        </div>

        {/* Commission Card */}
        <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
          <div className="p-6">
            {/* Developer Commission Status */}
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
                  <DollarSign className="w-5 h-5 text-[#0a84ff]" />
                </div>
                <div>
                  <h3 className="text-[17px] font-medium text-white">Commission</h3>
                  <p className="text-[13px] text-[#98989f]">{sale.developerCommissionPercentage}% de commission</p>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              {/* Developer Commission */}
              <PaymentToggle
                label="Commission promoteur"
                checked={sale.paymentStatus.developer}
                onChange={(status) => handlePaymentStatusChange('developer', status)}
              />

              {/* Agent Commission */}
              <PaymentToggle
                label="Commission agent"
                checked={sale.paymentStatus.agent}
                onChange={(status) => handlePaymentStatusChange('agent', status)}
              />

              {/* Referrer Commission */}
              {referrer && (
                <PaymentToggle
                  label="Commission parrain"
                  checked={sale.paymentStatus.referrer || false}
                  onChange={(status) => handlePaymentStatusChange('referrer', status)}
                />
              )}

              {/* Bonus Commission */}
              {sale.bonus && (
                <PaymentToggle
                  label="Bonus"
                  checked={sale.paymentStatus.bonus || false}
                  onChange={(status) => handlePaymentStatusChange('bonus', status)}
                />
              )}

              <div>
                <p className="text-[28px] font-bold text-white mb-1">
                  <BlurredNumber value={formatCurrency(sale.developerCommission)} />
                </p>
                <p className="text-[13px] text-[#98989f]">
                  <BlurredNumber value={formatEuro(sale.developerCommission)} />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Client Card */}
        <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
          <div className="p-6">
            {/* Client Info */}
            <div className="flex items-center gap-3 mb-6">
              <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
                <User className="w-5 h-5 text-[#0a84ff]" />
              </div>
              <div>
                <h3 className="text-[17px] font-medium text-white">Client</h3>
                <p className="text-[13px] text-[#98989f]">Informations de contact</p>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h4 className="text-[17px] font-medium text-white">
                  {sale.client.name || 'Client non renseigné'}
                </h4>
                {getClientProfileUrl() && (
                  <Link
                    to={getClientProfileUrl()!}
                    className="p-2 text-[#0a84ff] hover:bg-[#0a84ff]/10 rounded-xl transition-colors"
                  >
                    <ChevronRight className="w-5 h-5" />
                  </Link>
                )}
              </div>

              {sale.client.email && (
                <a
                  href={`mailto:${sale.client.email}`}
                  className="flex items-center gap-3 p-3 bg-[#3a3a3c] rounded-xl text-white hover:bg-[#48484a] transition-colors"
                >
                  <Mail className="w-5 h-5 text-[#0a84ff]" />
                  <span>{sale.client.email}</span>
                </a>
              )}

              {sale.client.phone && (
                <a
                  href={`tel:${sale.client.phone}`}
                  className="flex items-center gap-3 p-3 bg-[#3a3a3c] rounded-xl text-white hover:bg-[#48484a] transition-colors"
                >
                  <Phone className="w-5 h-5 text-[#0a84ff]" />
                  <span>{sale.client.phone}</span>
                </a>
              )}

              <div className="flex items-center gap-3 p-3 bg-[#3a3a3c] rounded-xl">
                <Building className="w-5 h-5 text-[#0a84ff]" />
                <span className="text-white">Source: {sale.leadSource}</span>
              </div>

              {/* Client Payment Status */}
              <div className="mt-6 space-y-4">
                <div className="flex items-center gap-2 mb-2">
                  <DollarSign className="w-4 h-4 text-[#98989f]" />
                  <p className="text-[13px] text-[#98989f]">État des paiements</p>
                </div>

                {/* EOI Payment */}
                <div className="flex items-center justify-between p-3 bg-[#3a3a3c] rounded-xl">
                  <div className="flex items-center gap-3">
                    <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
                      <DollarSign className="w-5 h-5 text-[#0a84ff]" />
                    </div>
                    <div>
                      <p className="text-[15px] font-medium text-white">EOI</p>
                      {sale.client.payments?.eoi?.date && (
                        <p className="text-[13px] text-[#98989f]">
                          {formatDateDisplay(sale.client.payments.eoi.date)}
                        </p>
                      )}
                    </div>
                  </div>
                  <button
                    onClick={() => handleClientPaymentChange('eoi', !sale.client.payments?.eoi?.status)}
                    className={`flex items-center gap-2 px-4 py-2 rounded-xl text-[13px] font-medium transition-colors ${
                      sale.client.payments?.eoi?.status
                        ? 'bg-[#26382f] text-[#30d158] hover:bg-[#26382f]/80'
                        : 'bg-[#3a2c29] text-[#ff453a] hover:bg-[#3a2c29]/80'
                    }`}
                  >
                    {sale.client.payments?.eoi?.status ? 'Payé' : 'En attente'}
                  </button>
                </div>

                {/* Down Payment */}
                <div className="flex items-center justify-between p-3 bg-[#3a3a3c] rounded-xl">
                  <div className="flex items-center gap-3">
                    <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
                      <DollarSign className="w-5 h-5 text-[#0a84ff]" />
                    </div>
                    <div>
                      <p className="text-[15px] font-medium text-white">Down Payment</p>
                      {sale.client.payments?.downPayment?.date && (
                        <p className="text-[13px] text-[#98989f]">
                          {formatDateDisplay(sale.client.payments.downPayment.date)}
                        </p>
                      )}
                    </div>
                  </div>
                  <button
                    onClick={() => handleClientPaymentChange('downPayment', !sale.client.payments?.downPayment?.status)}
                    className={`flex items-center gap-2 px-4 py-2 rounded-xl text-[13px] font-medium transition-colors ${
                      sale.client.payments?.downPayment?.status
                        ? 'bg-[#26382f] text-[#30d158] hover:bg-[#26382f]/80'
                        : 'bg-[#3a2c29] text-[#ff453a] hover:bg-[#3a2c29]/80'
                    }`}
                  >
                    {sale.client.payments?.downPayment?.status ? 'Payé' : 'En attente'}
                  </button>
                </div>

                {/* SPA Status */}
                <div className="flex items-center justify-between p-3 bg-[#3a3a3c] rounded-xl">
                  <div className="flex items-center gap-3">
                    <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
                      <FileText className="w-5 h-5 text-[#0a84ff]" />
                    </div>
                    <div>
                      <p className="text-[15px] font-medium text-white">SPA</p>
                      {sale.client.payments?.spa?.date && (
                        <p className="text-[13px] text-[#98989f]">
                          {formatDateDisplay(sale.client.payments.spa.date)}
                        </p>
                      )}
                    </div>
                  </div>
                  <button
                    onClick={() => handleClientPaymentChange('spa', !sale.client.payments?.spa?.status)}
                    className={`flex items-center gap-2 px-4 py-2 rounded-xl text-[13px] font-medium transition-colors ${
                      sale.client.payments?.spa?.status
                        ? 'bg-[#26382f] text-[#30d158] hover:bg-[#26382f]/80'
                        : 'bg-[#3a2c29] text-[#ff453a] hover:bg-[#3a2c29]/80'
                    }`}
                  >
                    {sale.client.payments?.spa?.status ? 'Signé' : 'En attente'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Agents Card */}
        <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
          <div className="p-6">
            <div className="flex items-center gap-3 mb-6">
              <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
                <Users className="w-5 h-5 text-[#0a84ff]" />
              </div>
              <div>
                <h3 className="text-[17px] font-medium text-white">Agents</h3>
                <p className="text-[13px] text-[#98989f]">Répartition des commissions</p>
              </div>
            </div>

            <div className="space-y-4">
              {/* Primary Agent */}
              {agent && (
                <div className="p-4 bg-[#3a3a3c] rounded-xl">
                  <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center gap-3">
                      <Avatar name={agent.name} size="sm" />
                      <div>
                        <Link
                          to={`/agents/${agent.id}`}
                          className="text-[15px] font-medium text-white hover:text-[#0a84ff] transition-colors"
                        >
                          {agent.name}
                        </Link>
                        <p className="text-[13px] text-[#98989f]">Agent principal</p>
                      </div>
                    </div>
                    <div className={`px-3 py-1 rounded-xl text-[13px] font-medium ${
                      sale.paymentStatus.agent
                        ? 'bg-[#26382f] text-[#30d158]'
                        : 'bg-[#3a2c29] text-[#ff453a]'
                    }`}>
                      {sale.paymentStatus.agent ? 'Payé' : 'En attente'}
                    </div>
                  </div>
                  <div>
                    <p className="text-[17px] font-bold text-white mb-1">
                      <BlurredNumber value={formatCurrency(commission.agent)} />
                    </p>
                    <p className="text-[13px] text-[#98989f]">
                      <BlurredNumber value={formatEuro(commission.agent)} />
                    </p>
                    {sale.bonus && (
                      <div className="mt-2 flex items-center gap-2">
                        <Gift className="w-4 h-4 text-[#bf5af2]" />
                        <span className="text-[#bf5af2]">
                          +<BlurredNumber value={formatCurrency(commission.bonus.agent)} />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Referrer */}
              {referrer && (
                <div className="p-4 bg-[#3a3a3c] rounded-xl">
                  <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center gap-3">
                      <Avatar name={referrer.name} size="sm" />
                      <div>
                        <Link
                          to={`/agents/${referrer.id}`}
                          className="text-[15px] font-medium text-white hover:text-[#0a84ff] transition-colors"
                        >
                          {referrer.name}
                        </Link>
                        <p className="text-[13px] text-[#98989f]">Parrain</p>
                      </div>
                    </div>
                    <div className={`px-3 py-1 rounded-xl text-[13px] font-medium ${
                      sale.paymentStatus.referrer
                        ? 'bg-[#26382f] text-[#30d158]'
                        : 'bg-[#3a2c29] text-[#ff453a]'
                    }`}>
                      {sale.paymentStatus.referrer ? 'Payé' : 'En attente'}
                    </div>
                  </div>
                  <div>
                    <p className="text-[17px] font-bold text-white mb-1">
                      <BlurredNumber value={formatCurrency(commission.referrer || 0)} />
                    </p>
                    <p className="text-[13px] text-[#98989f]">
                      <BlurredNumber value={formatEuro(commission.referrer || 0)} />
                    </p>
                    {sale.bonus?.secondAgentId === referrer.id && (
                      <div className="mt-2 flex items-center gap-2">
                        <Gift className="w-4 h-4 text-[#bf5af2]" />
                        <span className="text-[#bf5af2]">
                          +<BlurredNumber value={formatCurrency(commission.bonus.referrer)} />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Additional Agents */}
              {additionalAgents.map((item, index) => (
                <div key={index} className="p-4 bg-[#3a3a3c] rounded-xl">
                  <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center gap-3">
                      <Avatar name={item.agent.name} size="sm" />
                      <div>
                        <Link
                          to={`/agents/${item.agent.id}`}
                          className="text-[15px] font-medium text-white hover:text-[#0a84ff] transition-colors"
                        >
                          {item.agent.name}
                        </Link>
                        <p className="text-[13px] text-[#98989f]">Agent supplémentaire</p>
                      </div>
                    </div>
                    <div>
                      <PaymentToggle
                        label="Payé"
                        checked={sale.paymentStatus.additionalAgents?.[index] || false}
                        onChange={(status) => handlePaymentStatusChange(`additionalAgents.${index}`, status)}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="text-[17px] font-bold text-white mb-1">
                      <BlurredNumber value={formatCurrency(item.amount)} />
                    </p>
                    <p className="text-[13px] text-[#98989f]">
                      <BlurredNumber value={formatEuro(item.amount)} />
                    </p>
                    {sale.bonus?.agents?.find(a => a.agentId === item.agent.id) && (
                      <div className="mt-2 flex items-center gap-2">
                        <Gift className="w-4 h-4 text-[#bf5af2]" />
                        <span className="text-[#bf5af2]">
                          +<BlurredNumber value={formatCurrency(
                            sale.bonus.agents.find(a => a.agentId === item.agent.id)?.amount || 0
                          )} />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              {/* Agency Commission */}
              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="flex items-center gap-3 mb-3">
                  <DollarSign className="w-5 h-5 text-[#30d158]" />
                  <div>
                    <p className="text-[15px] font-medium text-white">Commission agence</p>
                    <p className="text-[13px] text-[#98989f]">
                      {sale.commissionType === 'manual'
                        ? `${((commission.agency / sale.developerCommission) * 100).toFixed(2)}% de la commission`
                        : `${(COMMISSION_TYPES[sale.commissionType].agency * 100).toFixed(0)}% de la commission`
                      }
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-[17px] font-bold text-[#30d158] mb-1">
                    <BlurredNumber value={formatCurrency(commission.agency)} />
                  </p>
                  <p className="text-[13px] text-[#98989f]">
                    <BlurredNumber value={formatEuro(commission.agency)} />
                  </p>
                  {sale.bonus && (
                    <div className="mt-2 flex items-center gap-2">
                      <Gift className="w-4 h-4 text-[#bf5af2]" />
                      <span className="text-[#bf5af2]">
                        +<BlurredNumber value={formatCurrency(commission.bonus.agency)} />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Quick Actions */}
        <div className="grid grid-cols-2 gap-4">
          <button
            onClick={() => {
              const message = `Bonjour,\n\nJe souhaite avoir un statut sur ma commission pour la vente suivante :\n\nProjet : ${sale.project}\nUnité : ${sale.unitNumber}\nCommission : ${formatCurrency(commission.agent)}\n\nMerci !`;
              window.open(`https://wa.me/971581998661?text=${encodeURIComponent(message)}`, '_blank');
            }}
            className="flex items-center justify-center gap-2 px-4 py-3 bg-[#2c2c2e] text-[#0a84ff] rounded-xl hover:bg-[#3a3a3c] transition-colors"
          >
            <MessageSquare className="w-5 h-5" />
            <span className="text-[15px] font-medium">Demander le statut</span>
          </button>
          <button
            onClick={handleGeneratePDF}
            className="flex items-center justify-center gap-2 px-4 py-3 bg-[#2c2c2e] text-[#0a84ff] rounded-xl hover:bg-[#3a3a3c] transition-colors"
          >
            <Share2 className="w-5 h-5" />
            <span className="text-[15px] font-medium">Partager</span>
          </button>
        </div>

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>

      {showEditForm && (
        <SaleForm
          initialData={sale}
          onSubmit={async (updatedSale) => {
            await updateSale(sale.id, updatedSale);
            setShowEditForm(false);
          }}
          onClose={() => setShowEditForm(false)}
        />
      )}
    </div>
  );
}