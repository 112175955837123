import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, Gift, Building } from 'lucide-react';
import { formatCurrency, formatEuro } from '../../../utils/format';
import BlurredNumber from '../../../components/ui/BlurredNumber';

interface CommissionStatsProps {
  stats: {
    received: number;
    pending: number;
    bonus: number;
  };
  totalVolume: number;
  salesCount: number;
}

export default function CommissionStats({ stats, totalVolume, salesCount }: CommissionStatsProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-[#1c1c1e] rounded-2xl overflow-hidden"
    >
      {/* Volume Section */}
      <div className="p-6 border-b border-[#2c2c2e]">
        <div className="flex items-center gap-3 mb-4">
          <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
            <Building className="w-6 h-6 text-[#0a84ff]" />
          </div>
          <div>
            <h3 className="text-[17px] font-medium text-white">Volume des ventes</h3>
            <p className="text-[13px] text-[#98989f]">{salesCount} ventes</p>
          </div>
        </div>
        <p className="text-[34px] font-bold text-white mb-2">
          <BlurredNumber value={formatCurrency(totalVolume)} />
        </p>
        <p className="text-[15px] text-[#98989f]">
          <BlurredNumber value={formatEuro(totalVolume)} />
        </p>
      </div>

      {/* Commission Cards */}
      <div className="p-6 space-y-6">
        {/* Total Commission */}
        <div className="p-6 bg-[#2c2c2e] rounded-2xl border border-[#3a3a3c]">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
              <DollarSign className="w-6 h-6 text-[#0a84ff]" />
            </div>
            <div>
              <h3 className="text-[17px] font-medium text-white">Commission totale</h3>
            </div>
          </div>
          <p className="text-[34px] font-bold text-white mb-2">
            <BlurredNumber value={formatCurrency(stats.received + stats.pending)} />
          </p>
          <p className="text-[15px] text-[#98989f]">
            <BlurredNumber value={formatEuro(stats.received + stats.pending)} />
          </p>
        </div>

        {/* Commission Grid */}
        <div className="grid grid-cols-2 gap-6">
          {/* Received Commission */}
          <div className="p-6 bg-[#26382f] rounded-2xl border border-[#30d158]/20">
            <div className="flex items-center gap-3 mb-4">
              <div className="w-10 h-10 rounded-xl bg-[#30d158]/10 flex items-center justify-center">
                <DollarSign className="w-6 h-6 text-[#30d158]" />
              </div>
              <div>
                <h3 className="text-[17px] font-medium text-white">Reçu</h3>
              </div>
            </div>
            <p className="text-[28px] font-bold text-[#30d158] mb-2">
              <BlurredNumber value={formatCurrency(stats.received)} />
            </p>
            <p className="text-[15px] text-[#98989f]">
              <BlurredNumber value={formatEuro(stats.received)} />
            </p>
          </div>

          {/* Pending Commission */}
          <div className="p-6 bg-[#3a2c29] rounded-2xl border border-[#ff453a]/20">
            <div className="flex items-center gap-3 mb-4">
              <div className="w-10 h-10 rounded-xl bg-[#ff453a]/10 flex items-center justify-center">
                <DollarSign className="w-6 h-6 text-[#ff453a]" />
              </div>
              <div>
                <h3 className="text-[17px] font-medium text-white">À recevoir</h3>
              </div>
            </div>
            <p className="text-[28px] font-bold text-[#ff453a] mb-2">
              <BlurredNumber value={formatCurrency(stats.pending)} />
            </p>
            <p className="text-[15px] text-[#98989f]">
              <BlurredNumber value={formatEuro(stats.pending)} />
            </p>
          </div>
        </div>

        {/* Bonus */}
        <div className="p-6 bg-[#2d2a3e] rounded-2xl border border-[#bf5af2]/20">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-10 h-10 rounded-xl bg-[#bf5af2]/10 flex items-center justify-center">
              <Gift className="w-6 h-6 text-[#bf5af2]" />
            </div>
            <div>
              <h3 className="text-[17px] font-medium text-white">Bonus</h3>
            </div>
          </div>
          <p className="text-[28px] font-bold text-[#bf5af2] mb-2">
            <BlurredNumber value={formatCurrency(stats.bonus)} />
          </p>
          <p className="text-[15px] text-[#98989f]">
            <BlurredNumber value={formatEuro(stats.bonus)} />
          </p>
        </div>
      </div>
    </motion.div>
  );
}