import React from 'react';
import { motion } from 'framer-motion';
import { Brain, TrendingUp, TrendingDown, Calendar, DollarSign, Target, ChevronUp, ChevronDown, Minus } from 'lucide-react';
import { formatCurrency, formatEuro } from '../../../utils/format';
import BlurredNumber from '../../../components/ui/BlurredNumber';

interface MonthlyData {
  month: string;
  commission: number;
  sales: number;
}

interface PerformanceStats {
  monthlyAverageSales: number;
  monthlyAverageRevenue: number;
  monthlyAverageCommission: number;
  growthRate: {
    sales: number;
    revenue: number;
    commission: number;
  };
}

interface PerformanceProjections {
  nextMonth: {
    sales: number;
    revenue: number;
    commission: number;
  };
  threeMonths: {
    sales: number;
    revenue: number;
    commission: number;
  };
  endOfYear: {
    sales: number;
    revenue: number;
    commission: number;
  };
}

interface PerformanceSectionProps {
  stats: PerformanceStats;
  bestMonth: MonthlyData;
  worstMonth: MonthlyData;
  projections: PerformanceProjections;
}

export default function PerformanceSection({ stats, bestMonth, worstMonth, projections }: PerformanceSectionProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-[#1c1c1e] rounded-2xl overflow-hidden"
    >
      {/* Best/Worst Months */}
      <div className="grid grid-cols-2 divide-x divide-[#2c2c2e]">
        {/* Best Month */}
        <div className="p-6">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-10 h-10 rounded-xl bg-[#30d158]/10 flex items-center justify-center">
              <TrendingUp className="w-6 h-6 text-[#30d158]" />
            </div>
            <div>
              <h3 className="text-[17px] font-medium text-white">Meilleur mois</h3>
              <p className="text-[13px] text-[#98989f]">{bestMonth?.month || 'N/A'}</p>
            </div>
          </div>
          <p className="text-[28px] font-bold text-[#30d158] mb-2">
            <BlurredNumber value={formatCurrency(bestMonth?.commission || 0)} />
          </p>
          <p className="text-[15px] text-[#98989f]">
            <BlurredNumber value={formatEuro(bestMonth?.commission || 0)} />
          </p>
          <div className="mt-4 px-3 py-1.5 bg-[#26382f] rounded-xl inline-flex items-center gap-2">
            <Target className="w-4 h-4 text-[#30d158]" />
            <span className="text-[13px] text-[#30d158]">{bestMonth?.sales || 0} ventes</span>
          </div>
        </div>

        {/* Worst Month */}
        <div className="p-6">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-10 h-10 rounded-xl bg-[#ff453a]/10 flex items-center justify-center">
              <TrendingDown className="w-6 h-6 text-[#ff453a]" />
            </div>
            <div>
              <h3 className="text-[17px] font-medium text-white">Mois le plus faible</h3>
              <p className="text-[13px] text-[#98989f]">{worstMonth?.month || 'N/A'}</p>
            </div>
          </div>
          <p className="text-[28px] font-bold text-[#ff453a] mb-2">
            <BlurredNumber value={formatCurrency(worstMonth?.commission || 0)} />
          </p>
          <p className="text-[15px] text-[#98989f]">
            <BlurredNumber value={formatEuro(worstMonth?.commission || 0)} />
          </p>
          <div className="mt-4 px-3 py-1.5 bg-[#3a2c29] rounded-xl inline-flex items-center gap-2">
            <Target className="w-4 h-4 text-[#ff453a]" />
            <span className="text-[13px] text-[#ff453a]">{worstMonth?.sales || 0} ventes</span>
          </div>
        </div>
      </div>

      <div className="border-t border-[#2c2c2e]">
        {/* Header */}
        <div className="p-6 border-b border-[#2c2c2e]">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
              <Brain className="w-6 h-6 text-[#0a84ff]" />
            </div>
            <div>
              <h3 className="text-[17px] font-medium text-white">Performance & Prévisions</h3>
              <p className="text-[13px] text-[#98989f]">Estimation IA</p>
            </div>
          </div>
        </div>

        {/* Monthly Averages */}
        <div className="p-6 grid grid-cols-2 gap-6">
          {/* Sales Average */}
          <div className="p-4 bg-[#2c2c2e] rounded-xl">
            <div className="flex items-center gap-2 mb-2">
              <Target className="w-4 h-4 text-[#0a84ff]" />
              <p className="text-[13px] text-[#98989f]">Ventes mensuelles</p>
            </div>
            <p className="text-[22px] font-bold text-white mb-1">
              {stats.monthlyAverageSales.toFixed(1)}
            </p>
            <div className="flex items-center gap-1">
              {stats.growthRate.sales > 0 ? (
                <ChevronUp className="w-4 h-4 text-[#30d158]" />
              ) : stats.growthRate.sales < 0 ? (
                <ChevronDown className="w-4 h-4 text-[#ff453a]" />
              ) : (
                <Minus className="w-4 h-4 text-[#98989f]" />
              )}
              <span className={`text-[13px] ${
                stats.growthRate.sales > 0 ? 'text-[#30d158]' :
                stats.growthRate.sales < 0 ? 'text-[#ff453a]' :
                'text-[#98989f]'
              }`}>
                {stats.growthRate.sales > 0 ? '+' : ''}{stats.growthRate.sales.toFixed(1)}%
              </span>
            </div>
          </div>

       

          {/* Commission Average */}
          <div className="p-4 bg-[#2c2c2e] rounded-xl">
            <div className="flex items-center gap-2 mb-2">
              <DollarSign className="w-4 h-4 text-[#bf5af2]" />
              <p className="text-[13px] text-[#98989f]">Commission mensuelle</p>
            </div>
            <p className="text-[22px] font-bold text-white mb-1">
              <BlurredNumber value={formatCurrency(stats.monthlyAverageCommission)} />
            </p>
            <div className="flex items-center gap-1">
              {stats.growthRate.commission > 0 ? (
                <ChevronUp className="w-4 h-4 text-[#30d158]" />
              ) : stats.growthRate.commission < 0 ? (
                <ChevronDown className="w-4 h-4 text-[#ff453a]" />
              ) : (
                <Minus className="w-4 h-4 text-[#98989f]" />
              )}
              <span className={`text-[13px] ${
                stats.growthRate.commission > 0 ? 'text-[#30d158]' :
                stats.growthRate.commission < 0 ? 'text-[#ff453a]' :
                'text-[#98989f]'
              }`}>
                {stats.growthRate.commission > 0 ? '+' : ''}{stats.growthRate.commission.toFixed(1)}%
              </span>
            </div>
          </div>
        </div>

        {/* Projections Section */}
        <div className="p-6 border-t border-[#2c2c2e]">
          <h4 className="text-[15px] font-medium text-white mb-6">Prévisions</h4>
          
          {/* Next Month */}
          <div className="p-4 bg-[#2c2c2e] rounded-xl space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Calendar className="w-4 h-4 text-[#0a84ff]" />
                <p className="text-[15px] text-white">Mois prochain</p>
              </div>
              <div className="px-3 py-1 bg-[#0a84ff]/10 rounded-full">
                <p className="text-[13px] text-[#0a84ff]">+10%</p>
              </div>
            </div>
            
            <div className="grid grid-cols-3 gap-4">
              <div>
                <p className="text-[13px] text-[#98989f] mb-1">Ventes</p>
                <p className="text-[17px] font-bold text-white">{projections.nextMonth.sales}</p>
              </div>
             
              <div>
                <p className="text-[13px] text-[#98989f] mb-1">Commission</p>
                <p className="text-[17px] font-bold text-white">
                  <BlurredNumber value={formatCurrency(projections.nextMonth.commission)} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}