import React, { useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, TrendingDown, Minus, DollarSign, Calculator, Gift } from 'lucide-react';
import { formatCurrency, formatEuro } from '../../utils/format';
import BlurredNumber from '../ui/BlurredNumber';
import { fr } from 'date-fns/locale';
import type { Sale } from '../../types';
import { calculateCommission } from '../../utils/commission';

interface MonthlyRevenue {
  month: string;
  revenue: number;
  progression: number;
}

interface AgencyRevenueHistoryProps {
  data: MonthlyRevenue[];
  sales: Sale[];
}

const getMonthIndex = (monthName: string): number => {
  const months = Array.from({ length: 12 }, (_, i) => 
    new Date(2000, i).toLocaleString('fr-FR', { month: 'long' })
  );
  return months.indexOf(monthName.split(' ')[0].toLowerCase());
};

const sortMonths = (a: MonthlyRevenue, b: MonthlyRevenue) => {
  const [monthA, yearA] = a.month.split(' ');
  const [monthB, yearB] = b.month.split(' ');
  
  if (yearA !== yearB) {
    return parseInt(yearB) - parseInt(yearA);
  }
  
  return getMonthIndex(monthB) - getMonthIndex(monthA);
};

export default function AgencyRevenueHistory({ data, sales }: AgencyRevenueHistoryProps) {

  const monthlyAverage = useMemo(() => {
    if (data.length === 0) return 0;
    const total = data.reduce((sum, month) => sum + month.revenue, 0);
    return total / data.length;
  }, [data]);

  const sortedData = useMemo(() => {
    return [...data].sort(sortMonths);
  }, [data]);

  return (
    <div className="bg-[#1c1c1e] rounded-2xl overflow-hidden">
      <div className="p-6 border-b border-[#2c2c2e]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
              <DollarSign className="w-6 h-6 text-[#0a84ff]" />
            </div>
            <div>
              <h2 className="text-[17px] font-semibold text-white">Historique des revenus</h2>
              <p className="text-[13px] text-[#98989f]">Évolution mensuelle</p>
            </div>
          </div>
        </div>
      </div>

      {/* Monthly Average */}
      <div className="p-6 border-b border-[#2c2c2e]">
        <div className="flex items-center gap-3 mb-2">
          <Calculator className="w-5 h-5 text-[#30d158]" />
          <p className="text-[15px] text-[#98989f]">Moyenne mensuelle</p>
        </div>
        <div>
          <p className="text-[28px] font-bold text-[#30d158] mb-1">
            <BlurredNumber value={formatCurrency(monthlyAverage)} />
          </p>
          <p className="text-[13px] text-[#98989f]">
            <BlurredNumber value={formatEuro(monthlyAverage)} />
          </p>
        </div>
      </div>

      <div className="divide-y divide-[#2c2c2e]">
        {sortedData.map((month, index) => {
          const isPositive = month.progression > 0;
          const isNeutral = month.progression === 0;
          const hasRevenue = month.revenue > 0;

          return (
            <motion.div
              key={month.month}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`p-6 ${hasRevenue ? 'hover:bg-[#2c2c2e]' : 'bg-[#3a2c29]'}`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className={`p-2 rounded-lg ${
                    !hasRevenue ? 'bg-[#ff453a]/20' :
                    isPositive ? 'bg-[#30d158]/20' :
                    isNeutral ? 'bg-[#98989f]/20' : 'bg-[#ff9f0a]/20'
                  }`}>
                    {!hasRevenue ? (
                      <DollarSign className="w-5 h-5 text-[#ff453a]" />
                    ) : isPositive ? (
                      <TrendingUp className="w-5 h-5 text-[#30d158]" />
                    ) : isNeutral ? (
                      <Minus className="w-5 h-5 text-[#98989f]" />
                    ) : (
                      <TrendingDown className="w-5 h-5 text-[#ff9f0a]" />
                    )}
                  </div>
                  <div>
                    <h3 className="text-[15px] font-medium text-white">{month.month}</h3>
                    {!isNeutral && !isNaN(month.progression) && month.month !== 'janvier 2025' && (
                      <div className={`flex items-center gap-1 mt-1 text-[13px] ${
                        isPositive ? 'text-[#30d158]' : 'text-[#ff9f0a]'
                      }`}>
                        {isPositive ? '+' : ''}{month.progression.toFixed(1)}%
                      </div>
                    )}
                  </div>
                </div>

                <motion.div 
                  className="text-right"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: index * 0.1 + 0.2 }}
                >
                  <p className={`text-[17px] font-bold ${!hasRevenue ? 'text-[#ff453a]' : 'text-white'}`}>
                    <BlurredNumber value={formatCurrency(month.revenue)} />
                  </p>
                  <span className="inline-block text-[13px] text-[#98989f]">
                    <BlurredNumber value={formatEuro(month.revenue)} />
                  </span>
                </motion.div>
              </div>
            </motion.div>
          );
        })}
      </div>

    </div>
  );
}