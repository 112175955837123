import React, { useState, useRef } from 'react';
import { DollarSign, Users, Calculator, ChevronDown } from 'lucide-react';
import type { Agent } from '../../types';
import { getCommissionTypes } from '../../utils/commission';

interface FinancialSectionProps {
  formData: {
    propertyPrice: number;
    developerCommissionPercentage: number;
    developerCommission: number;
    commissionType: string;
    referrerId?: string;
    manualCommissions?: {
      agent: number;
      agency: number;
      agentPercentage?: number;
      agencyPercentage?: number;
      additionalAgents?: Array<{
        agentId: string;
        amount: number;
        percentage?: number;
      }>;
    };
  };
  agents: Agent[];
  onChange: (data: Partial<FinancialSectionProps['formData']>) => void;
}

export default function FinancialSection({ formData, agents, onChange }: FinancialSectionProps) {
  const [usePercentage, setUsePercentage] = useState(true);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'AED',
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const handlePriceChange = (price: number) => {
    const commission = (price * formData.developerCommissionPercentage) / 100;
    onChange({
      propertyPrice: price,
      developerCommission: commission
    });
  };

  const handlePercentageChange = (percentage: number) => {
    const commission = (formData.propertyPrice * percentage) / 100;
    onChange({
      developerCommissionPercentage: percentage,
      developerCommission: commission
    });
  };

  return (
    <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
      <div className="p-4 border-b border-[#3a3a3c]">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-[#0a84ff]/10 rounded-lg">
            <DollarSign className="w-5 h-5 text-[#0a84ff]" />
          </div>
          <div>
            <h3 className="text-[17px] font-medium text-white">Finances et Commissions</h3>
            <p className="text-[13px] text-[#98989f]">Prix et répartition</p>
          </div>
        </div>
      </div>

      {/* Form Content */}
      <div className="p-4 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-[13px] font-medium text-[#98989f] mb-2">
              Prix du bien
              <span className="text-[#ff453a] ml-1">*</span>
            </label>
            <div className="relative">
              <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
              <input
                type="number"
                required
                min="0"
                value={formData.propertyPrice || ''}
                onChange={(e) => handlePriceChange(Number(e.target.value))}
                className="w-full pl-10 pr-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                placeholder="Prix du bien"
              />
            </div>
          </div>

          <div>
            <label className="block text-[13px] font-medium text-[#98989f] mb-2">
              Commission promoteur (%)
              <span className="text-[#ff453a] ml-1">*</span>
            </label>
            <input
              type="number"
              required
              min="0"
              max="100"
              value={formData.developerCommissionPercentage || ''}
              onChange={(e) => handlePercentageChange(Number(e.target.value))}
              className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
              placeholder="Pourcentage"
            />
          </div>
        </div>

        {/* Commission Type */}
        <div>
          <label className="block text-[13px] font-medium text-[#98989f] mb-2">
            Type de commission
            <span className="text-[#ff453a] ml-1">*</span>
          </label>
          <div className="relative">
            <select
              value={formData.commissionType}
              onChange={(e) => onChange({ commissionType: e.target.value })}
              className="w-full appearance-none px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] pr-10"
            >
              {getCommissionTypes().map(type => (
                <option key={type.value} value={type.value} className="bg-[#1c1c1e] text-white">
                  {type.label}
                </option>
              ))}
            </select>
            <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f] pointer-events-none" />
          </div>
        </div>

        {/* Commission Total */}
        <div>
          <label className="block text-[13px] font-medium text-[#98989f] mb-2">
            Commission totale
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
            <input
              type="text"
              value={formatCurrency(formData.developerCommission)}
              readOnly
              className="w-full pl-10 pr-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl"
            />
          </div>
        </div>
        {/* Manual Commission Distribution */}
        {formData.commissionType === 'manual' && (
          <div className="space-y-6 p-4 bg-[#3a3a3c] rounded-xl border border-[#48484a]">
          <div className="flex items-center gap-3">
            <Calculator className="w-5 h-5 text-[#0a84ff]" />
            <h4 className="text-[15px] font-medium text-white">Répartition de la commission</h4>
          </div>

          {/* Agent Commission */}
          <div>
            <label className="block text-[13px] text-[#98989f] mb-2">
              Commission agent
            </label>
            <input
              type="number"
              min="0"
              value={formData.manualCommissions?.agent || 0}
              onChange={(e) => onChange({
                manualCommissions: {
                  ...formData.manualCommissions,
                  agent: Number(e.target.value)
                }
              })}
              className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff]"
            />
          </div>

          {/* Agency Commission */}
          <div>
            <label className="block text-[13px] text-[#98989f] mb-2">
              Commission agence
            </label>
            <input
              type="number"
              min="0"
              value={formData.manualCommissions?.agency || 0}
              onChange={(e) => onChange({
                manualCommissions: {
                  ...formData.manualCommissions,
                  agency: Number(e.target.value)
                }
              })}
              className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff]"
            />
          </div>

          {/* Additional Agents */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h5 className="text-[15px] font-medium text-white">Agents supplémentaires</h5>
              <button
                type="button"
                onClick={() => {
                  const newAgents = formData.manualCommissions?.additionalAgents || [];
                  onChange({
                    manualCommissions: {
                      ...formData.manualCommissions,
                      additionalAgents: [...newAgents, { agentId: '', amount: 0 }]
                    }
                  });
                }}
                className="px-3 py-1.5 bg-[#0a84ff]/10 text-[#0a84ff] rounded-lg hover:bg-[#0a84ff]/20 text-[13px] font-medium"
              >
                + Ajouter un agent
              </button>
            </div>

            {formData.manualCommissions?.additionalAgents?.map((agent, index) => (
              <div key={index} className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="grid grid-cols-3 gap-4">
                  <select
                    value={agent.agentId}
                    onChange={(e) => {
                      const newAgents = [...(formData.manualCommissions?.additionalAgents || [])];
                      newAgents[index] = { ...agent, agentId: e.target.value };
                      onChange({
                        manualCommissions: {
                          ...formData.manualCommissions,
                          additionalAgents: newAgents
                        }
                      });
                    }}
                    className="w-full px-4 py-3 bg-[#48484a] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff]"
                  >
                    <option value="" className="bg-[#1c1c1e]">Sélectionner un agent</option>
                    {agents?.filter(a => a.id !== formData.agentId).map(agent => (
                      <option key={agent.id} value={agent.id} className="bg-[#1c1c1e]">
                        {agent.name}
                      </option>
                    ))}
                  </select>

                  <div className="col-span-2 flex gap-2">
                    <input
                      type="number"
                      value={agent.amount}
                      onChange={(e) => {
                        const newAgents = [...(formData.manualCommissions?.additionalAgents || [])];
                        newAgents[index] = {
                          ...agent,
                          amount: Number(e.target.value)
                        };
                        onChange({
                          manualCommissions: {
                            ...formData.manualCommissions,
                            additionalAgents: newAgents
                          }
                        });
                      }}
                      className="flex-1 px-4 py-3 bg-[#48484a] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff]"
                      placeholder="Montant"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        const newAgents = formData.manualCommissions?.additionalAgents?.filter((_, i) => i !== index);
                        onChange({
                          manualCommissions: {
                            ...formData.manualCommissions,
                            additionalAgents: newAgents
                          }
                        });
                      }}
                      className="px-4 py-2 bg-[#ff453a]/10 text-[#ff453a] rounded-xl hover:bg-[#ff453a]/20"
                    >
                      ×
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          </div>
        )}

        {/* Agent Selection */}
        <div>
          <label className="block text-[13px] font-medium text-[#98989f] mb-2">
            Agent principal
            <span className="text-[#ff453a] ml-1">*</span>
          </label>
          <select
            required
            value={formData.agentId}
            onChange={(e) => onChange({ agentId: e.target.value })}
            className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
          >
            <option value="" className="bg-[#1c1c1e] text-white">Sélectionner un agent</option>
            {agents.map(agent => (
              <option key={agent.id} value={agent.id} className="bg-[#1c1c1e] text-white">
                {agent.name}
              </option>
            ))}
          </select>
        </div>

        {/* Referrer Selection */}
        {['referral', 'direct_lead_referral', 'shared_three_way'].includes(formData.commissionType) && (
          <div>
            <label className="block text-[13px] font-medium text-[#98989f] mb-2">
              Agent parrain
              <span className="text-[#ff453a] ml-1">*</span>
            </label>
            <select
              required
              value={formData.referrerId}
              onChange={(e) => onChange({ referrerId: e.target.value })}
              className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
            >
              <option value="" className="bg-[#1c1c1e] text-white">Sélectionner un parrain</option>
              {agents.filter(a => a.id !== formData.agentId).map(agent => (
                <option key={agent.id} value={agent.id} className="bg-[#1c1c1e] text-white">
                  {agent.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}