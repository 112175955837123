import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, Gift, Users } from 'lucide-react';
import type { Sale } from '../../types';
import { formatCurrency, formatEuro } from '../../utils/format';
import BlurredNumber from '../ui/BlurredNumber';

interface CommissionBreakdownProps {
  sale: Sale;
  commission: {
    agent: number;
    agency: number;
    referrer?: number;
    bonus: {
      agent: number;
      agency: number;
      referrer: number;
    };
  };
  agents: Array<{ id: string; name: string }>;
}

export default function CommissionBreakdown({ sale, commission, agents }: CommissionBreakdownProps) {
  const totalCommission = sale.developerCommission + (sale.bonus?.amount || 0);

  const getPercentage = (amount: number) => {
    return ((amount / totalCommission) * 100).toFixed(1);
  };

  // Get agent names
  const agent = agents.find(a => a.id === sale.agentId);
  const referrer = sale.referrerId ? agents.find(a => a.id === sale.referrerId) : null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-[#1c1c1e] rounded-2xl overflow-hidden"
    >
      {/* Header */}
      <div className="px-6 py-4 border-b border-[#2c2c2e]">
        <div className="flex items-center gap-3">
          <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
            <DollarSign className="w-5 h-5 text-[#0a84ff]" />
          </div>
          <div>
            <h3 className="text-[17px] font-semibold text-white">Répartition des commissions</h3>
            <p className="text-[13px] text-[#98989f]">
              Commission totale : <BlurredNumber value={formatCurrency(totalCommission)} />
            </p>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="p-6 space-y-6">
        {/* Agent Commission */}
        <div className="bg-[#2c2c2e] rounded-xl p-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-3">
              <Users className="w-5 h-5 text-[#0a84ff]" />
              <div>
                <p className="text-[13px] text-[#98989f] mb-1">Commission {agent?.name || 'Agent'}</p>
                <p className="text-[17px] text-white font-medium">
                  <BlurredNumber value={formatCurrency(commission.agent)} />
                </p>
                <p className="text-[13px] text-[#98989f]">
                  <BlurredNumber value={formatEuro(commission.agent)} />
                </p>
              </div>
            </div>
            <div className="text-[13px] text-[#98989f]">
              {getPercentage(commission.agent)}%
            </div>
          </div>
          {sale.bonus && commission.bonus.agent > 0 && (
            <div className="mt-2 flex items-center gap-2 px-3 py-2 bg-[#2c2c2e] rounded-xl">
              <Gift className="w-4 h-4 text-[#bf5af2]" />
              <div>
                <span className="text-[#bf5af2] text-[13px]">
                  +<BlurredNumber value={formatCurrency(commission.bonus.agent)} /> bonus
                </span>
              </div>
            </div>
          )}
          <div className="mt-3 w-full bg-[#3a3a3c] h-1.5 rounded-full overflow-hidden">
            <div 
              className="h-full bg-[#0a84ff] rounded-full"
              style={{ width: `${getPercentage(commission.agent)}%` }}
            />
          </div>
        </div>

        {/* Agency Commission */}
        <div className="bg-[#2c2c2e] rounded-xl p-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-3">
              <DollarSign className="w-5 h-5 text-[#30d158]" />
              <div>
                <p className="text-[13px] text-[#98989f] mb-1">Commission agence</p>
                <p className="text-[17px] text-white font-medium">
                  <BlurredNumber value={formatCurrency(commission.agency)} />
                </p>
                <p className="text-[13px] text-[#98989f]">
                  <BlurredNumber value={formatEuro(commission.agency)} />
                </p>
              </div>
            </div>
            <div className="text-[13px] text-[#98989f]">
              {getPercentage(commission.agency)}%
            </div>
          </div>
          {sale.bonus && commission.bonus.agency > 0 && (
            <div className="mt-2 flex items-center gap-2 px-3 py-2 bg-[#2c2c2e] rounded-xl">
              <Gift className="w-4 h-4 text-[#bf5af2]" />
              <div>
                <span className="text-[#bf5af2] text-[13px]">
                  +<BlurredNumber value={formatCurrency(commission.bonus.agency)} /> bonus
                </span>
              </div>
            </div>
          )}
          <div className="mt-3 w-full bg-[#3a3a3c] h-1.5 rounded-full overflow-hidden">
            <div 
              className="h-full bg-[#30d158] rounded-full"
              style={{ width: `${getPercentage(commission.agency)}%` }}
            />
          </div>
        </div>

        {/* Additional Agents */}
        {sale.manualCommissions?.additionalAgents?.map((additionalAgent, index) => (
          <div key={index} className="bg-[#2c2c2e] rounded-xl p-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-3">
                <Users className="w-5 h-5 text-[#ff9f0a]" />
                <div>
                  <p className="text-[13px] text-[#98989f] mb-1">
                    {agents.find(a => a.id === additionalAgent.agentId)?.name || 'Agent supplémentaire'}
                  </p>
                  <p className="text-[17px] text-white font-medium">
                    <BlurredNumber value={formatCurrency(additionalAgent.amount)} />
                  </p>
                  <p className="text-[13px] text-[#98989f]">
                    <BlurredNumber value={formatEuro(additionalAgent.amount)} />
                  </p>
                </div>
              </div>
              <div className="text-[13px] text-[#98989f]">
                {getPercentage(additionalAgent.amount)}%
              </div>
            </div>
            {sale.bonus?.agents?.find(a => a.agentId === additionalAgent.agentId)?.amount > 0 && (
              <div className="mt-2 flex items-center gap-2 px-3 py-2 bg-[#2c2c2e] rounded-xl">
                <Gift className="w-4 h-4 text-[#bf5af2]" />
                <div>
                  <span className="text-[#bf5af2] text-[13px]">
                    +<BlurredNumber value={formatCurrency(
                      sale.bonus.agents.find(a => a.agentId === additionalAgent.agentId)?.amount || 0
                    )} /> bonus
                  </span>
                </div>
              </div>
            )}
            <div className="mt-3 w-full bg-[#3a3a3c] h-1.5 rounded-full overflow-hidden">
              <div 
                className="h-full bg-[#ff9f0a] rounded-full"
                style={{ width: `${getPercentage(additionalAgent.amount)}%` }}
              />
            </div>
          </div>
        ))}

        {/* Referrer Commission */}
        {commission.referrer > 0 && (
          <div className="bg-[#2c2c2e] rounded-xl p-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-3">
                <Users className="w-5 h-5 text-[#ff9f0a]" />
                <div>
                  <p className="text-[13px] text-[#98989f] mb-1">Commission {referrer?.name || 'Parrain'}</p>
                  <p className="text-[17px] text-white font-medium">
                    <BlurredNumber value={formatCurrency(commission.referrer)} />
                  </p>
                  <p className="text-[13px] text-[#98989f]">
                    <BlurredNumber value={formatEuro(commission.referrer)} />
                  </p>
                </div>
              </div>
              <div className="text-[13px] text-[#98989f]">
                {getPercentage(commission.referrer)}%
              </div>
            </div>
            {sale.bonus && commission.bonus.referrer > 0 && (
              <div className="mt-2 flex items-center gap-2 px-3 py-2 bg-[#2c2c2e] rounded-xl">
                <Gift className="w-4 h-4 text-[#bf5af2]" />
                <div>
                  <span className="text-[#bf5af2] text-[13px]">
                    +<BlurredNumber value={formatCurrency(commission.bonus.referrer)} /> bonus
                  </span>
                </div>
              </div>
            )}
            <div className="mt-3 w-full bg-[#3a3a3c] h-1.5 rounded-full overflow-hidden">
              <div 
                className="h-full bg-[#ff9f0a] rounded-full"
                style={{ width: `${getPercentage(commission.referrer)}%` }}
              />
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}