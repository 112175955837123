import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Ban as Bank, History, X } from 'lucide-react';
import { doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { formatDateDisplay } from '../../utils/date';

interface BankInfoModalProps {
  agentId: string;
  onClose: () => void;
}

interface BankInfo {
  iban: string;
  bic: string;
  accountName: string;
  updatedAt: string;
}

interface BankInfoHistory {
  iban: string;
  bic: string;
  accountName: string;
  updatedAt: string;
}

export default function BankInfoModal({ agentId, onClose }: BankInfoModalProps) {
  const [formData, setFormData] = useState({
    iban: '',
    bic: '',
    accountName: ''
  });
  const [history, setHistory] = useState<BankInfoHistory[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBankInfo = async () => {
      const agentRef = doc(db, 'agents', agentId);
      const agentDoc = await getDoc(agentRef);
      const data = agentDoc.data();
      
      if (data?.bankInfo) {
        setFormData({
          iban: data.bankInfo.iban || '',
          bic: data.bankInfo.bic || '',
          accountName: data.bankInfo.accountName || ''
        });
      }
      
      if (data?.bankInfoHistory) {
        setHistory(data.bankInfoHistory);
      }
    };
    
    fetchBankInfo();
  }, [agentId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const agentRef = doc(db, 'agents', agentId);
      const newBankInfo = {
        ...formData,
        updatedAt: new Date().toISOString()
      };

      await updateDoc(agentRef, {
        bankInfo: newBankInfo,
        bankInfoHistory: arrayUnion(newBankInfo)
      });

      setHistory(prev => [newBankInfo, ...prev]);
      onClose();
    } catch (error) {
      console.error('Error updating bank info:', error);
      setError('Erreur lors de la mise à jour des informations bancaires');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-end sm:items-center justify-center z-50"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <motion.div
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        transition={{ type: 'spring', damping: 25, stiffness: 200 }}
        className="w-full sm:w-[28rem] bg-[#1c1c1e] rounded-t-2xl sm:rounded-2xl overflow-hidden"
      >
        {/* Header */}
        <div className="px-6 py-4 border-b border-[#2c2c2e]">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
                <Bank className="w-5 h-5 text-[#0a84ff]" />
              </div>
              <h3 className="text-[17px] font-semibold text-white">Informations bancaires</h3>
            </div>
            <button
              onClick={onClose}
              className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-[#2c2c2e] transition-colors"
            >
              <X className="w-5 h-5 text-[#98989f]" />
            </button>
          </div>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          {error && (
            <div className="p-3 bg-[#3a2c29] text-[#ff453a] rounded-xl text-[15px]">
              {error}
            </div>
          )}

          <div>
            <label className="block text-[13px] text-[#98989f] mb-2">
              Nom du compte
            </label>
            <input
              type="text"
              value={formData.accountName}
              onChange={(e) => setFormData(prev => ({ ...prev, accountName: e.target.value }))}
              className="w-full px-4 py-3 bg-[#2c2c2e] text-white rounded-xl border border-[#3a3a3c] focus:border-[#0a84ff] focus:ring-1 focus:ring-[#0a84ff] placeholder-[#98989f]"
              placeholder="John Doe"
              required
            />
          </div>

          <div>
            <label className="block text-[13px] text-[#98989f] mb-2">
              IBAN
            </label>
            <input
              type="text"
              value={formData.iban}
              onChange={(e) => setFormData(prev => ({ ...prev, iban: e.target.value.toUpperCase() }))}
              className="w-full px-4 py-3 bg-[#2c2c2e] text-white rounded-xl border border-[#3a3a3c] focus:border-[#0a84ff] focus:ring-1 focus:ring-[#0a84ff] placeholder-[#98989f]"
              placeholder="FR76 XXXX XXXX XXXX XXXX XXXX XXX"
              required
            />
          </div>

          <div>
            <label className="block text-[13px] text-[#98989f] mb-2">
              BIC
            </label>
            <input
              type="text"
              value={formData.bic}
              onChange={(e) => setFormData(prev => ({ ...prev, bic: e.target.value.toUpperCase() }))}
              className="w-full px-4 py-3 bg-[#2c2c2e] text-white rounded-xl border border-[#3a3a3c] focus:border-[#0a84ff] focus:ring-1 focus:ring-[#0a84ff] placeholder-[#98989f]"
              placeholder="BNPAFRPPXXX"
              required
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full px-4 py-3 bg-[#0a84ff] text-white rounded-xl font-medium disabled:opacity-50"
          >
            {isSubmitting ? 'Enregistrement...' : 'Enregistrer'}
          </button>
        </form>

        {/* History */}
        {history.length > 0 && (
          <div className="px-6 pb-6">
            <div className="flex items-center gap-2 mb-4">
              <History className="w-4 h-4 text-[#98989f]" />
              <h4 className="text-[15px] font-medium text-white">Historique des modifications</h4>
            </div>
            <div className="space-y-3">
              {history.map((entry, index) => (
                <div key={index} className="p-3 bg-[#2c2c2e] rounded-xl">
                  <div className="flex items-center justify-between mb-1">
                    <p className="text-[15px] text-white font-medium">{entry.accountName}</p>
                    <p className="text-[13px] text-[#98989f]">
                      {formatDateDisplay(entry.updatedAt)}
                    </p>
                  </div>
                  <p className="text-[13px] text-[#98989f]">{entry.iban}</p>
                  <p className="text-[13px] text-[#98989f]">{entry.bic}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
}