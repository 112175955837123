import React from 'react';
import { motion } from 'framer-motion';
import { Info, Gift, Users, DollarSign, TrendingUp, ChevronRight, Building, Video, MessageSquare, AlertTriangle, Clock, Calendar, Instagram, AlertCircle, GraduationCap } from 'lucide-react';
import AgentRules from '../../components/agent-dashboard/AgentRules';
import ReferralBanner from '../../components/agent-dashboard/ReferralBanner';
import VideographerService from '../../components/agent-dashboard/VideographerService';

export default function AgentInformation() {
  return (
    <div className="min-h-screen bg-[#000000]">
      {/* Header */}
      <div className="bg-[#1c1c1e] sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-lg mx-auto px-4 py-3">
          <div className="flex items-center gap-3">
            <Info className="w-6 h-6 text-[#0a84ff]" />
            <h1 className="text-[17px] font-semibold text-white">Information</h1>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-lg mx-auto px-4 py-4 space-y-6">
        {/* Rules Section - iOS 17 Style */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-[#2c2c2e] rounded-2xl overflow-hidden"
        >
          <div className="p-4 border-b border-[#3a3a3c]">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#ff453a]/10 flex items-center justify-center">
                <AlertTriangle className="w-6 h-6 text-[#ff453a]" />
              </div>
              <div>
                <h3 className="text-[17px] font-semibold text-white">Règles Importantes</h3>
                <p className="text-[13px] text-[#98989f]">À respecter impérativement</p>
              </div>
            </div>
          </div>

          <div className="divide-y divide-[#3a3a3c]">
            <div className="p-4">
              <div className="flex items-center gap-3 mb-2">
                <Clock className="w-5 h-5 text-[#0a84ff]" />
                <h4 className="text-[15px] font-medium text-white">Heures de travail</h4>
              </div>
              <p className="text-[15px] text-[#98989f] pl-8">
                Disponibilité requise de 12h à 22h
              </p>
            </div>

            <div className="p-4">
              <div className="flex items-center gap-3 mb-2">
                <AlertCircle className="w-5 h-5 text-[#ff453a]" />
                <h4 className="text-[15px] font-medium text-white">Gestion des leads</h4>
              </div>
              <div className="space-y-2 pl-8">
                <p className="text-[15px] text-[#98989f]">
                  • Contacter le lead dans l'heure (25€ de pénalité)
                </p>
                <p className="text-[15px] text-[#98989f]">
                  • Les leads sont strictement individuels
                </p>
                <p className="text-[15px] text-[#98989f]">
                  • Vol de lead = suspension d'un trimestre
                </p>
              </div>
            </div>

            <div className="p-4">
              <div className="flex items-center gap-3 mb-2">
                <Calendar className="w-5 h-5 text-[#30d158]" />
                <h4 className="text-[15px] font-medium text-white">Disponibilité</h4>
              </div>
              <p className="text-[15px] text-[#98989f] pl-8">
                Prévenez 24h à l'avance si vous êtes indisponible
              </p>
            </div>

            <div className="p-4">
              <div className="flex items-center gap-3 mb-2">
                <Instagram className="w-5 h-5 text-[#ff9f0a]" />
                <h4 className="text-[15px] font-medium text-white">Instagram professionnel</h4>
              </div>
              <div className="space-y-2 pl-8">
                <p className="text-[15px] text-[#98989f]">
                  • Compte professionnel obligatoire
                </p>
                <p className="text-[15px] text-[#98989f]">
                  • Mention @palmdubai.fr en BIO
                </p>
                <p className="text-[15px] text-[#98989f]">
                  • Publication régulière de contenu
                </p>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Referral Program - iOS App Store Style */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-[#2c2c2e] rounded-2xl overflow-hidden"
        >
          <div className="p-4 border-b border-[#3a3a3c]">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#bf5af2]/10 flex items-center justify-center">
                <Gift className="w-6 h-6 text-[#bf5af2]" />
              </div>
              <div>
                <h3 className="text-[17px] font-semibold text-white">Programme de Parrainage</h3>
                <p className="text-[13px] text-[#98989f]">Gagnez 10% à vie !</p>
              </div>
            </div>
          </div>

          <div className="p-4 space-y-4">
            <div className="bg-[#3a3a3c] rounded-xl p-4">
              <div className="flex items-center gap-3 mb-2">
                <DollarSign className="w-5 h-5 text-[#30d158]" />
                <h4 className="text-[15px] font-medium text-white">Premier bonus</h4>
              </div>
              <p className="text-[15px] text-[#98989f]">
                20% sur la première vente de chaque filleul
              </p>
            </div>

            <div className="bg-[#3a3a3c] rounded-xl p-4">
              <div className="flex items-center gap-3 mb-2">
                <TrendingUp className="w-5 h-5 text-[#30d158]" />
                <h4 className="text-[15px] font-medium text-white">Revenus passifs</h4>
              </div>
              <p className="text-[15px] text-[#98989f]">
                10% sur toutes les ventes futures, à vie !
              </p>
            </div>

            <div className="bg-[#3a3a3c] rounded-xl p-4">
              <div className="flex items-center gap-3 mb-2">
                <Users className="w-5 h-5 text-[#30d158]" />
                <h4 className="text-[15px] font-medium text-white">Équipe illimitée</h4>
              </div>
              <p className="text-[15px] text-[#98989f]">
                Aucune limite sur le nombre d'agents parrainés
              </p>
            </div>
          </div>

          <div className="p-4 border-t border-[#3a3a3c]">
            <button
              onClick={() => {
                const message = encodeURIComponent("Bonjour, je souhaite parrainer un agent chez Palm Dubai Immo.");
                window.open(`https://wa.me/971581998661?text=${message}`, '_blank');
              }}
              className="w-full flex items-center justify-between"
            >
              <div className="flex items-center gap-3">
                <MessageSquare className="w-5 h-5 text-[#0a84ff]" />
                <span className="text-[15px] text-white">Contacter pour parrainer</span>
              </div>
              <ChevronRight className="w-5 h-5 text-[#98989f]" />
            </button>
          </div>
        </motion.div>
        
        {/* Videographer Service - iOS Settings Style */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-[#2c2c2e] rounded-2xl overflow-hidden"
        >
          <div className="p-4 border-b border-[#3a3a3c]">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#ff453a]/10 flex items-center justify-center">
                <Video className="w-6 h-6 text-[#ff453a]" />
              </div>
              <div>
                <h3 className="text-[17px] font-semibold text-white">Service Vidéaste</h3>
                <p className="text-[13px] text-[#98989f]">Service suspendu</p>
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-center gap-3 bg-[#3a2c29] rounded-xl p-4">
              <AlertTriangle className="w-5 h-5 text-[#ff453a]" />
              <div>
                <p className="text-[15px] text-[#ff453a] font-medium">Service temporairement indisponible</p>
                <p className="text-[13px] text-[#98989f] mt-1">
                  Nous vous informerons dès la reprise du service vidéaste.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* Upcoming Features - iOS 17 Style */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="bg-[#2c2c2e] rounded-2xl overflow-hidden"
        >
          <div className="p-4 border-b border-[#3a3a3c]">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#30d158]/10 flex items-center justify-center">
                <Building className="w-6 h-6 text-[#30d158]" />
              </div>
              <div>
                <h3 className="text-[17px] font-semibold text-white">Nouveautés</h3>
                <p className="text-[13px] text-[#98989f]">Prochainement</p>
              </div>
            </div>
          </div>

          <div className="divide-y divide-[#3a3a3c]">
            <div className="p-4">
              <div className="flex items-center gap-3 mb-2">
                <Building className="w-5 h-5 text-[#30d158]" />
                <h4 className="text-[15px] font-medium text-white">Espace de travail à Dubai</h4>
              </div>
              <p className="text-[15px] text-[#98989f] pl-8">
                Un espace de travail dédié sera bientôt disponible avec un accès 7j/7 pour que vous puissiez travailler dans les meilleures conditions.
              </p>
            </div>

            <div className="p-4">
              <div className="flex items-center gap-3 mb-2">
                <GraduationCap className="w-5 h-5 text-[#30d158]" />
                <h4 className="text-[15px] font-medium text-white">Palm Academy</h4>
              </div>
              <p className="text-[15px] text-[#98989f] pl-8">
                Formation complète en Belgique pour développer vos compétences dans l'immobilier.
              </p>
            </div>
          </div>
        </motion.div>
        
        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>
    </div>
  );
}