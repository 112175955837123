import emailjs from '@emailjs/browser';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { getMessaging, getToken } from 'firebase/messaging';
import { auth } from '../lib/firebase';
import type { Agent, Sale } from '../types';
import { formatCurrency } from '../utils/format';

const ZAPIER_WEBHOOK_URL = import.meta.env.VITE_ZAPIER_WEBHOOK_URL;
const MAX_RETRIES = 3;
const INITIAL_RETRY_DELAY = 1000; // 1 second

async function sendWhatsAppMessage(phone: string, message: string) {
  if (!ZAPIER_WEBHOOK_URL) {
    console.warn('Zapier webhook URL not configured');
    return false;
  }

  try {
    // Format phone number
    const formattedPhone = phone.replace(/[^0-9]/g, '');
    
    let attempt = 0;
    let lastError = null;
    
    while (attempt < MAX_RETRIES) {
      try {
        const response = await fetch(ZAPIER_WEBHOOK_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            phone: formattedPhone,
            message: message,
            type: 'whatsapp_notification',
            timestamp: new Date().toISOString(),
            retry_count: attempt
          })
        });
    
        if (response.ok) {
          console.log(`WhatsApp message sent successfully to ${formattedPhone}`);
          return true;
        }

        // If response is not ok, try to get error details
        let errorDetails = 'Unknown error';
        try {
          const errorData = await response.json();
          errorDetails = errorData.message || errorData.error || JSON.stringify(errorData);
        } catch (e) {
          errorDetails = response.statusText;
        }

        lastError = new Error(`HTTP ${response.status}: ${errorDetails}`);
        console.warn(`Attempt ${attempt + 1} failed:`, lastError.message);
        
        // Wait before retrying (exponential backoff)
        const delay = INITIAL_RETRY_DELAY * Math.pow(2, attempt);
        await new Promise(resolve => setTimeout(resolve, delay));
        
      } catch (error) {
        console.error(`Attempt ${attempt + 1} failed with error:`, error);
        lastError = error;
        
        // Wait before retrying
        const delay = INITIAL_RETRY_DELAY * Math.pow(2, attempt);
        await new Promise(resolve => setTimeout(resolve, delay));
      }
      
      attempt++;
    }
    
    // If we get here, all retries failed
    throw lastError || new Error('Failed to send WhatsApp message after all retries');
    
  } catch (error) {
    console.error('Error sending WhatsApp message:', error);
    return false;
  }
}

// Demander la permission pour les notifications push
export async function requestNotificationPermission() {
  try {
    const messaging = getMessaging();
    const token = await getToken(messaging, {
      vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY
    });
    
    if (token) {
      // Sauvegarder le token dans Firestore pour l'agent
      const userDoc = doc(db, 'users', auth.currentUser?.uid || '');
      await updateDoc(userDoc, {
        fcmToken: token,
        notificationsEnabled: true
      });
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error requesting notification permission:', error);
    return false;
  }
}

// Demander la permission pour les notifications iOS
export async function requestIOSNotificationPermission() {
  try {
    if (!('Notification' in window)) {
      throw new Error('Notifications not supported');
    }

    // Vérifier si on est sur iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (!isIOS) {
      return await requestNotificationPermission();
    }

    // Demander la permission iOS
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      // Enregistrer le token pour les notifications iOS
      const messaging = getMessaging();
      const token = await getToken(messaging, {
        vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY
      });

      if (token) {
        // Sauvegarder le token dans Firestore
        const userDoc = doc(db, 'users', auth.currentUser?.uid || '');
        await updateDoc(userDoc, {
          fcmToken: token,
          notificationsEnabled: true,
          platform: 'ios'
        });
        return true;
      }
    }
    return false;
  } catch (error) {
    console.error('Error requesting iOS notification permission:', error);
    return false;
  }
}

export async function sendLeadNotification(agentId: string, lead: any) {
  try {
    // Récupérer les infos de l'agent
    const agentDoc = await getDoc(doc(db, 'agents', agentId));
    const agent = agentDoc.data();
    
    if (!agent) {
      throw new Error('Agent not found');
    }

    // Préparer le message WhatsApp
    const message = `👋 Bonjour ${agent.name},\n\n` +
      `🎯 Un nouveau lead vous a été assigné :\n\n` +
      `👤 Nom : ${lead.name}\n` +
      `📞 Téléphone : ${lead.phone || 'Non renseigné'}\n` +
      `📧 Email : ${lead.email || 'Non renseigné'}\n` +
      `🏢 Projet : ${lead.project || 'Non spécifié'}\n` +
      `💰 Budget : ${lead.budget ? formatCurrency(lead.budget) : 'Non spécifié'}\n\n` +
      `⚠️ Important : Contactez le lead dans l'heure pour éviter une pénalité de 25€\n\n` +
      `🌐 Voir le lead : ${window.location.origin}/pipeline`;

    // Envoyer via WhatsApp si un numéro est disponible
    if (agent.phone) {
      console.log('Attempting to send WhatsApp notification to agent:', agent.name);
      const whatsappSent = await sendWhatsAppMessage(agent.phone, message);
      if (!whatsappSent) {
        console.warn('First attempt failed, retrying in 5 seconds...');
        // Retry once after 5 seconds
        setTimeout(async () => {
          const retryResult = await sendWhatsAppMessage(agent.phone, message);
          if (!retryResult) {
            console.error('Both attempts to send WhatsApp notification failed');
          } else {
            console.log('Retry successful');
          }
        }, 5000);
      }
    }

    // Envoyer aussi via l'API de notifications
    const response = await fetch(`${import.meta.env.VITE_API_URL}/api/notifications/lead`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${import.meta.env.VITE_API_KEY}`
      },
      body: JSON.stringify({
        agentId,
        lead,
        type: 'new_lead',
        priority: 'high',
        retryCount: 3
      })
    });

    if (!response.ok) {
      throw new Error(`API error: ${response.statusText}`);
    }

    return true;
  } catch (error) {
    console.error('Error sending lead notification:', error);
    return false;
  }
}

const CC_EMAIL = import.meta.env.VITE_CC_EMAIL;

export async function sendSaleNotification(sale: Sale, agent: Agent, type: 'new' | 'status_change' = 'new') {
  try {
    // Préparer le message WhatsApp
    const message = `👋 Bonjour ${agent.name},\n\n` +
      `${type === 'new' ? '🎉 Nouvelle vente enregistrée' : '📝 Mise à jour de la vente'} :\n\n` +
      `🏢 Projet : ${sale.project}\n` +
      `🔑 Unité : ${sale.unitNumber}\n` +
      `💰 Prix : ${formatCurrency(sale.propertyPrice)}\n` +
      `💎 Commission : ${formatCurrency(sale.developerCommission)}\n\n` +
      `🌐 Plus de détails sur : ${window.location.origin}/sales/${sale.id}`;

    // Envoyer via WhatsApp si un numéro est disponible
    if (agent.phone) {
      await sendWhatsAppMessage(agent.phone, message);
    }

    // Envoyer aussi via l'API de notifications (email + push)
    const response = await fetch(`${import.meta.env.VITE_API_URL}/api/notifications/sale`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${import.meta.env.VITE_API_KEY}`
      },
      body: JSON.stringify({
        sale,
        agent,
        type,
        priority: 'high',
        retryCount: 3,
        appUrl: window.location.origin
      })
    });

    if (!response.ok) {
      throw new Error(`API error: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error sending notification:', error);
    throw error;
  }
}

export async function sendEmailNotification(agent: Agent, password?: string) {
  try {
    const settingsRef = doc(db, 'settings', 'general');
    const settingsDoc = await getDoc(settingsRef);
    const settings = settingsDoc.data();

    if (!settings?.emailjs) {
      throw new Error('EmailJS configuration not found');
    }

    emailjs.init(settings.emailjs.publicKey);

    const templateParams = {
      to_name: agent.name,
      to_email: agent.email,
      subject: password ? 'Vos identifiants PalmCRM' : 'Modification de votre compte PalmCRM',
      message: password 
        ? `Votre compte a été créé avec succès.\n\nEmail: ${agent.email}\nMot de passe: ${password}`
        : `Votre compte a été mis à jour.\n\nVos nouvelles informations:\nNom: ${agent.name}\nEmail: ${agent.email}\nTéléphone: ${agent.phone || 'Non renseigné'}`,
      app_url: import.meta.env.VITE_APP_URL
    };

    await emailjs.send(
      settings.emailjs.serviceId,
      settings.emailjs.templateId,
      templateParams
    );

    return true;
  } catch (error) {
    console.error('Error sending email notification:', error);
    throw error;
  }
}

export async function sendWhatsAppNotification(agent: Agent, password?: string) {
  if (!agent.phone) return;
  
  const message = password
    ? `👋 Bonjour ${agent.name},\n\n` +
      `🔑 Voici vos identifiants de connexion:\n` +
      `📧 Email: ${agent.email}\n` +
      `🔒 Mot de passe: ${password}\n\n` +
      `🌐 Connectez-vous sur: ${import.meta.env.VITE_APP_URL}`
    : `👋 Bonjour ${agent.name},\n\n` +
      `✨ Votre compte a été mis à jour avec succès.\n\n` +
      `📧 Email: ${agent.email}\n` +
      `📱 Téléphone: ${agent.phone || 'Non renseigné'}\n\n` +
      `🌐 Connectez-vous sur: ${import.meta.env.VITE_APP_URL}`;
  
  const phone = agent.phone.replace(/[^0-9]/g, '');
  const encodedMessage = encodeURIComponent(message);
  window.open(`https://wa.me/${phone}?text=${encodedMessage}`, '_blank');
}

export { sendWhatsAppMessage }