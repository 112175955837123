import React from 'react';
import { motion } from 'framer-motion';
import { User, Mail, Phone, Building } from 'lucide-react';

interface ClientInfoCardProps {
  client: {
    name?: string;
    email?: string;
    phone?: string;
    payments?: {
      eoi?: {
        status: boolean;
        date?: string;
      };
      downPayment?: {
        status: boolean;
        date?: string;
      };
      spa?: {
        status: boolean;
        date?: string;
      };
    };
  };
  leadSource: string;
}

export default function ClientInfoCard({ client, leadSource }: ClientInfoCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-[#1c1c1e] rounded-2xl overflow-hidden"
    >
      {/* Header */}
      <div className="py-4 border-b border-[#2c2c2e]">
        <div className="flex items-center gap-3 px-4">
          <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
            <User className="w-5 h-5 text-[#0a84ff]" />
          </div>
          <h3 className="text-[17px] font-semibold text-white">Informations Client</h3>
        </div>
      </div>

      {/* Content */}
      <div className="p-4 space-y-4">
        {/* Name */}
        <div className="bg-[#2c2c2e] rounded-xl p-4">
          <div className="flex items-center gap-3">
            <User className="w-5 h-5 text-[#0a84ff]" />
            <div>
              <p className="text-[13px] text-[#98989f] mb-1">Nom du client</p>
              <p className="text-[17px] text-white font-medium">{client.name || 'Non renseigné'}</p>
            </div>
          </div>
        </div>

        {/* Contact Info */}
        {client.email && (
          <div className="bg-[#2c2c2e] rounded-xl p-4">
            <div className="flex items-center gap-3">
              <Mail className="w-5 h-5 text-[#0a84ff]" />
              <div>
                <p className="text-[13px] text-[#98989f] mb-1">Email</p>
                <a href={`mailto:${client.email}`} className="text-[17px] text-white font-medium">
                  {client.email}
                </a>
              </div>
            </div>
          </div>
        )}

        {client.phone && (
          <div className="bg-[#2c2c2e] rounded-xl p-4">
            <div className="flex items-center gap-3">
              <Phone className="w-5 h-5 text-[#0a84ff]" />
              <div>
                <p className="text-[13px] text-[#98989f] mb-1">Téléphone</p>
                <a href={`tel:${client.phone}`} className="text-[17px] text-white font-medium">
                  {client.phone}
                </a>
              </div>
            </div>
          </div>
        )}

        {/* Lead Source */}
        <div className="bg-[#2c2c2e] rounded-xl p-4">
          <div className="flex items-center gap-3">
            <Building className="w-5 h-5 text-[#0a84ff]" />
            <div>
              <p className="text-[13px] text-[#98989f] mb-1">Source du lead</p>
              <p className="text-[17px] text-white font-medium">{leadSource}</p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}