import React, { useState } from 'react';
import { X, Building, User, DollarSign, Bell, Gift, ChevronRight } from 'lucide-react';
import { PropertySection, ClientSection, FinancialSection, NotificationSection } from './forms';
import BonusSection from './forms/BonusSection';
import { useToast } from '../contexts/ToastContext';
import { sendSaleNotification } from '../services/notificationService';
import { useAgents } from '../hooks/useAgents';
import type { Sale } from '../types';

interface SaleFormProps {
  onSubmit: (sale: Omit<Sale, 'id' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  onClose: () => void;
  initialData?: Sale;
}

export default function SaleForm({ onSubmit, onClose, initialData }: SaleFormProps) {
  const { agents } = useAgents();
  const { showToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [activeSection, setActiveSection] = useState<string>('property');

  const [formData, setFormData] = useState({
    propertyPrice: initialData?.propertyPrice || 0,
    developerCommissionPercentage: initialData?.developerCommissionPercentage || 6,
    developerCommission: initialData?.developerCommission || 0,
    client: {
      name: initialData?.client.name || '',
      phone: initialData?.client.phone || '',
      email: initialData?.client.email || '',
      payments: initialData?.client.payments || {
        eoi: { status: false },
        downPayment: { status: false },
        spa: { status: false }
      }
    },
    leadSource: initialData?.leadSource || '',
    project: initialData?.project || '',
    unitNumber: initialData?.unitNumber || '',
    expectedDeliveryYear: initialData?.expectedDeliveryYear || new Date().getFullYear() + 1,
    agentId: initialData?.agentId || '',
    referrerId: initialData?.referrerId || '',
    status: initialData?.status || 'pending',
    commissionType: initialData?.commissionType || 'standard',
    saleDate: initialData?.saleDate || new Date().toISOString().split('T')[0],
    paymentStatus: {
      developer: initialData?.paymentStatus?.developer || false,
      agent: initialData?.paymentStatus?.agent || false,
      referrer: initialData?.paymentStatus?.referrer || false,
      bonus: initialData?.paymentStatus?.bonus || false
    },
    notifications: {
      email: true,
      whatsapp: true
    },
    manualCommissions: initialData?.manualCommissions || {
      agent: 0,
      agency: 0,
      agentPercentage: 60,
      agencyPercentage: 40
    },
    bonus: initialData?.bonus || undefined
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      await onSubmit(formData);

      // Send notifications to agent
      const agent = agents.find(a => a.id === formData.agentId);
      if (agent && formData.notifications.email) {
        try {
          await sendSaleNotification(
            { ...formData, id: initialData?.id || '' } as Sale,
            agent,
            initialData ? 'status_change' : 'new'
          );
          showToast('success', 'Notifications envoyées avec succès');
        } catch (error) {
          console.error('Error sending notifications:', error);
          showToast('error', 'Erreur lors de l\'envoi des notifications');
        }
      }

      showToast('success', initialData ? 'Vente modifiée avec succès' : 'Vente créée avec succès');
      onClose();
    } catch (err) {
      console.error('Error submitting form:', err);
      if (err instanceof Error) {
        setError(err.message);
        showToast('error', err.message);
      } else {
        setError('Une erreur est survenue');
        showToast('error', 'Une erreur est survenue');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateFormData = (updates: Partial<typeof formData>) => {
    setFormData(prev => ({ ...prev, ...updates }));
  };

  const sections = [
    {
      id: 'property',
      icon: Building,
      title: 'Information du bien',
      component: PropertySection,
      description: 'Détails du projet et de l\'unité'
    },
    {
      id: 'client',
      icon: User,
      title: 'Information client',
      component: ClientSection,
      description: 'Coordonnées et paiements'
    },
    {
      id: 'financial',
      icon: DollarSign,
      title: 'Finances et Commissions',
      component: FinancialSection,
      description: 'Prix et répartition'
    },
    {
      id: 'bonus',
      icon: Gift,
      title: 'Bonus',
      component: BonusSection,
      description: 'Bonus et récompenses'
    }
  ];

  return (
    <div className="fixed inset-0 bg-black/80 flex items-start justify-center z-50 overflow-y-auto">
      <div className="min-h-screen w-full py-8 px-4 relative">
        <div className="bg-[#1c1c1e] rounded-2xl shadow-xl w-full max-w-4xl mx-auto">
          {/* Header */}
          <div className="sticky top-0 z-10 px-6 py-4 bg-[#1c1c1e] border-b border-[#2c2c2e] flex justify-between items-center rounded-t-2xl">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <Building className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <h2 className="text-[22px] font-semibold text-white">
                {initialData ? 'Modifier la vente' : 'Nouvelle vente'}
              </h2>
            </div>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-[#2c2c2e] rounded-xl transition-colors"
            >
              <X className="w-5 h-5 text-[#98989f]" />
            </button>
          </div>

          {/* Section Navigation */}
          <div className="px-6 py-4 border-b border-[#2c2c2e] overflow-x-auto">
            <div className="flex gap-2">
              {sections.map(section => (
                <button
                  key={section.id}
                  onClick={() => setActiveSection(section.id)}
                  className={`flex items-center gap-2 px-4 py-2 rounded-xl transition-colors whitespace-nowrap ${
                    activeSection === section.id
                      ? 'bg-[#0a84ff] text-white'
                      : 'bg-[#2c2c2e] text-[#98989f] hover:bg-[#3a3a3c]'
                  }`}
                >
                  <section.icon className="w-4 h-4" />
                  <span className="text-[15px] font-medium">{section.title}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mx-6 mt-6">
              <div className="bg-[#3a2c29] text-[#ff453a] p-4 rounded-xl text-[15px]">
                {error}
              </div>
            </div>
          )}

          {/* Form Content */}
          <form onSubmit={handleSubmit}>
            <div className="max-h-[calc(100vh-16rem)] overflow-y-auto px-6 py-6">
              {sections.map(({ id, component: Component }) => (
                <div
                  key={id}
                  className={`space-y-6 ${activeSection === id ? 'block' : 'hidden'}`}
                >
                  <Component
                    formData={formData}
                    onChange={updateFormData}
                    agents={agents}
                  />
                </div>
              ))}
            </div>

            {/* Form Actions */}
            <div className="px-6 py-4 border-t border-[#2c2c2e] bg-[#1c1c1e] flex flex-col sm:flex-row justify-between items-center gap-4">
              <div className="flex items-center gap-2">
                {activeSection !== sections[sections.length - 1].id && (
                  <button
                    type="button"
                    onClick={() => {
                      const currentIndex = sections.findIndex(s => s.id === activeSection);
                      if (currentIndex < sections.length - 1) {
                        setActiveSection(sections[currentIndex + 1].id);
                      }
                    }}
                    className="flex items-center gap-2 px-4 py-2 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors"
                  >
                    <span>Section suivante</span>
                    <ChevronRight className="w-4 h-4" />
                  </button>
                )}
              </div>
              
              <div className="flex gap-3 w-full sm:w-auto">
                <button
                  type="button"
                  onClick={onClose}
                  className="flex-1 sm:flex-initial px-4 py-2 text-[#98989f] bg-[#2c2c2e] rounded-xl hover:bg-[#3a3a3c] transition-colors"
                  disabled={isSubmitting}
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="flex-1 sm:flex-initial px-4 py-2 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-all duration-200 disabled:opacity-50"
                >
                  {isSubmitting ? 'Enregistrement...' : initialData ? 'Mettre à jour' : 'Ajouter la vente'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}