import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Clock, DollarSign, Calendar, Gift, Ban as Bank } from 'lucide-react';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import { useSales } from '../../hooks/useSales';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { formatCurrency, formatEuro } from '../../utils/format';
import { formatDateDisplay } from '../../utils/date';
import BlurredNumber from '../../components/ui/BlurredNumber';
import BankInfoModal from '../../components/agent-dashboard/BankInfoModal';

export default function AgentPayments() {
  const { agent } = useAgentSlug();
  const { sales, calculateCommission } = useSales();
  const [showBankInfoModal, setShowBankInfoModal] = useState(false);
  const [bonusPayments, setBonusPayments] = useState<any[]>([]);

  useEffect(() => {
    if (!agent) return;

    // Subscribe to bonus payments
    const q = query(
      collection(db, 'bonusPayments'),
      where('agentId', '==', agent.id),
      orderBy('paymentDate', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const payments = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        paymentDate: doc.data().paymentDate
      }));
      setBonusPayments(payments);
    });

    return () => unsubscribe();
  }, [agent]);

  if (!agent) return null;

  // Get all payments for this agent
  const payments = sales
    .filter(sale => 
      // Include only paid sales where agent is involved
      sale.agentId === agent.id ||
      sale.referrerId === agent.id ||
      sale.manualCommissions?.additionalAgents?.some(a => a.agentId === agent.id) ||
      sale.bonus?.secondAgentId === agent.id
    )
    .filter(sale => {
      // Check if payment is received based on agent role
      if (sale.agentId === agent.id) {
        return sale.paymentStatus.agent;
      } else if (sale.referrerId === agent.id) {
        return sale.paymentStatus.referrer;
      } else if (sale.manualCommissions?.additionalAgents) {
        const agentIndex = sale.manualCommissions.additionalAgents.findIndex(a => a.agentId === agent.id);
        return sale.paymentStatus.additionalAgents?.[agentIndex];
      } else if (sale.bonus?.secondAgentId === agent.id) {
        return sale.paymentStatus.bonus;
      }
      return false;
    })
    .map(sale => {
      const commission = calculateCommission(sale);
      let amount = 0;
      let role = '';
      let isPaid = false;

      // Calculate amount and payment status based on role
      if (sale.agentId === agent.id) {
        amount = commission.agent;
        role = 'Agent principal';
        isPaid = sale.paymentStatus.agent;
        if (sale.bonus && sale.paymentStatus.bonus) {
          amount += commission.bonus.agent;
        }
      } else if (sale.referrerId === agent.id) {
        amount = commission.referrer || 0;
        role = 'Parrain';
        isPaid = sale.paymentStatus.referrer;
        if (sale.bonus?.secondAgentId === agent.id && sale.paymentStatus.bonus) {
          amount += commission.bonus.referrer;
        }
      } else if (sale.manualCommissions?.additionalAgents) {
        const agentIndex = sale.manualCommissions.additionalAgents.findIndex(a => a.agentId === agent.id);
        const additionalAgent = sale.manualCommissions.additionalAgents[agentIndex];
        if (additionalAgent) {
          amount = additionalAgent.amount;
          role = 'Agent supplémentaire';
          isPaid = sale.paymentStatus.additionalAgents?.[agentIndex] || false;
        }
      }

      return {
        id: sale.id,
        date: sale.saleDate,
        project: sale.project,
        unitNumber: sale.unitNumber,
        amount,
        role,
        isPaid
      };
    })
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  // Calculate totals
  const totalReceived = payments.reduce((sum, payment) => 
    payment.isPaid ? sum + payment.amount : sum, 0
  );

  // Calculate pending amount
  const pendingPayments = sales
    .filter(sale => 
      // Include only unpaid sales where agent is involved
      (sale.agentId === agent.id && !sale.paymentStatus.agent) || // Main agent
      (sale.referrerId === agent.id && !sale.paymentStatus.referrer) || // Referrer
      (sale.manualCommissions?.additionalAgents?.some(a => a.agentId === agent.id) && // Additional agent
        !sale.paymentStatus.additionalAgents?.[sale.manualCommissions.additionalAgents.findIndex(a => a.agentId === agent.id)]) ||
      (sale.bonus?.secondAgentId === agent.id && !sale.paymentStatus.bonus) // Bonus agent
    )
    .reduce((sum, sale) => {
      const commission = calculateCommission(sale);
      let amount = 0;

      if (sale.agentId === agent.id) {
        amount = commission.agent;
        if (sale.bonus && !sale.paymentStatus.bonus) {
          amount += commission.bonus.agent;
        }
      } else if (sale.referrerId === agent.id) {
        amount = commission.referrer || 0;
        if (sale.bonus?.secondAgentId === agent.id && !sale.paymentStatus.bonus) {
          amount += commission.bonus.referrer;
        }
      } else if (sale.manualCommissions?.additionalAgents) {
        const additionalAgent = sale.manualCommissions.additionalAgents.find(a => a.agentId === agent.id);
        if (additionalAgent) {
          amount = additionalAgent.amount;
        }
      }

      return sum + amount;
    }, 0);

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e] sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-lg mx-auto px-4 py-3">
          <div className="flex items-center gap-3">
            <Clock className="w-6 h-6 text-[#0a84ff]" />
            <div>
              <h1 className="text-[17px] font-semibold text-white">Historique des paiements</h1>
              <p className="text-[13px] text-[#98989f]">{payments.length} paiements reçus</p>
            </div>
          </div>
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-2 gap-4 p-4 border-t border-[#2c2c2e]">
          {/* Total Received */}
          <div className="bg-[#26382f] rounded-xl p-4">
            <div className="flex items-center gap-2 mb-2">
              <DollarSign className="w-4 h-4 text-[#30d158]" />
              <p className="text-[13px] text-[#98989f]">Total reçu</p>
            </div>
            <p className="text-[17px] font-bold text-[#30d158]">
              <BlurredNumber value={formatCurrency(totalReceived)} />
            </p>
            <p className="text-[13px] text-[#98989f] mt-1">
              <BlurredNumber value={formatEuro(totalReceived)} />
            </p>
          </div>

          {/* Total Pending */}
          <div className="bg-[#3a2c29] rounded-xl p-4">
            <div className="flex items-center gap-2 mb-2">
              <Clock className="w-4 h-4 text-[#ff453a]" />
              <p className="text-[13px] text-[#98989f]">À recevoir</p>
            </div>
            <p className="text-[17px] font-bold text-[#ff453a]">
              <BlurredNumber value={formatCurrency(pendingPayments)} />
            </p>
            <p className="text-[13px] text-[#98989f] mt-1">
              <BlurredNumber value={formatEuro(pendingPayments)} />
            </p>
          </div>
        </div>
      </div>

      {/* Payments List */}
      <div className="max-w-lg mx-auto px-4 py-4 space-y-4">
        {/* Bonus Payments */}
        {bonusPayments.map((bonus) => (
          <motion.div
            key={bonus.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-[#2c2c2e] rounded-xl p-4"
          >
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-[17px] font-semibold text-white">{bonus.description}</h3>
                <div className="flex items-center gap-2 mt-2">
                  <Calendar className="w-4 h-4 text-[#0a84ff]" />
                  <span className="text-[13px] text-[#98989f]">
                    {formatDateDisplay(bonus.paymentDate)}
                  </span>
                </div>
              </div>
              <div className="text-right">
                <div className="flex items-center gap-2">
                  <Gift className="w-4 h-4 text-[#bf5af2]" />
                  <p className="text-[17px] font-bold text-[#bf5af2] whitespace-nowrap">
                    <BlurredNumber value={formatCurrency(bonus.amount)} />
                  </p>
                </div>
                <p className="text-[13px] text-[#98989f]">
                  <BlurredNumber value={formatEuro(bonus.amount)} />
                </p>
              </div>
            </div>
          </motion.div>
        ))}

        {payments.map((payment) => (
          <motion.div
            key={payment.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-[#2c2c2e] rounded-xl p-4"
          >
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-[17px] font-semibold text-white">{payment.project}</h3>
                <p className="text-[15px] text-[#98989f]">Unité {payment.unitNumber}</p>
                <div className="flex items-center gap-2 mt-2">
                  <Calendar className="w-4 h-4 text-[#0a84ff]" />
                  <span className="text-[13px] text-[#98989f]">
                    {formatDateDisplay(payment.date)}
                  </span>
                </div>
              </div>
              <div className="text-right">
                <p className="text-[17px] font-bold text-white">
                  <BlurredNumber value={formatCurrency(payment.amount)} />
                </p>
                <p className="text-[13px] text-[#98989f]">
                  <BlurredNumber value={formatEuro(payment.amount)} />
                </p>
                <p className="text-[13px] text-[#98989f] mt-1">{payment.role}</p>
                <div className={`mt-2 px-3 py-1 rounded-xl text-[13px] font-medium inline-flex items-center gap-1 ${
                  payment.isPaid
                    ? 'bg-[#26382f] text-[#30d158]'
                    : 'bg-[#3a2c29] text-[#ff453a]'
                }`}>
                  {payment.isPaid ? (
                    <>
                      <span className="w-2 h-2 rounded-full bg-[#30d158]" />
                      Payée
                    </>
                  ) : (
                    <>
                      <span className="w-2 h-2 rounded-full bg-[#ff453a]" />
                      En attente
                    </>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        ))}

        {payments.length === 0 && (
          <div className="text-center py-12">
            <Clock className="w-12 h-12 text-[#98989f] mx-auto mb-4" />
            <p className="text-[#98989f]">Aucun paiement reçu</p>
          </div>
        )}

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>
      
      {/* Bank Info Button - Fixed at bottom */}
      <div className="fixed bottom-20 lg:bottom-8 left-0 right-0 lg:left-auto lg:right-8">
        <div className="max-w-lg mx-auto px-4 lg:px-0 lg:mx-0 lg:w-auto">
          <button
            onClick={() => setShowBankInfoModal(true)}
            className="w-full lg:w-auto flex items-center justify-center gap-2 px-4 py-3 bg-[#2c2c2e] text-[#0a84ff] rounded-xl hover:bg-[#3a3a3c] transition-colors whitespace-nowrap"
          >
            <Bank className="w-5 h-5" />
            <span className="text-[15px] font-medium">Coordonnées bancaires</span>
          </button>
        </div>
      </div>

      {/* Bank Info Modal */}
      {showBankInfoModal && (
        <BankInfoModal
          agentId={agent.id}
          onClose={() => setShowBankInfoModal(false)}
        />
      )}
    </div>
  );
}