import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import { useSales } from '../../hooks/useSales';
import { useAgents } from '../../hooks/useAgents';
import ReferralBanner from '../../components/agent-dashboard/ReferralBanner';
import { Building, TrendingUp, Edit, ChevronRight, Target, DollarSign, Gift, Trophy, TrendingDown, Calculator, ChevronUp } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import MonthlyCommissionHistory from './components/MonthlyCommissionHistory';
import { formatCurrency, formatEuro } from '../../utils/format';
import ReferralSalesSection from './components/ReferralSalesSection';
import BlurredNumber from '../../components/ui/BlurredNumber';
import QuarterlyGoalCard from '../../components/agent-dashboard/QuarterlyGoalCard';
import AgentStats from './components/AgentStats';
import QuarterlyRanking from './components/QuarterlyRanking';
import MonthlyGoalCard from './components/MonthlyGoalCard';
import LastSaleCard from './components/LastSaleCard';
import UnderperformingAlert from './components/UnderperformingAlert';
import InactivityAlert from './components/InactivityAlert';
import AdminGoalsButton from '../../components/agent-dashboard/AdminGoalsButton';
import AgentEditPopup from '../../components/agent-dashboard/AgentEditPopup';
import { useSettings } from '../../hooks/useSettings';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import SalesVolumeChart from '../../components/agent-dashboard/components/SalesVolumeChart';
import PerformanceSection from './components/PerformanceSection';
import CommissionStats from './components/CommissionStats';

export default function AgentDashboard() {
  const { agent, slug } = useAgentSlug();
  const { sales, calculateCommission } = useSales();
  const { agents } = useAgents();
  const { settings } = useSettings();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showEditPopup, setShowEditPopup] = useState(false);
  const quarterlyGoal = settings?.quarterlyGoals?.[agent?.id];
  const conventionSigned = quarterlyGoal?.conventionSigned || false;

  if (!agent) return null;

  const agentSales = sales.filter(sale => 
    sale.agentId === agent.id || sale.referrerId === agent.id
  ).sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime());

  // Get the most recent and first sale
  const lastSale = agentSales[0];
  const firstSale = agentSales[agentSales.length - 1];

  // Calculate days since last sale
  const daysSinceLastSale = lastSale 
    ? formatDistanceToNow(new Date(lastSale.saleDate), { locale: fr, addSuffix: true })
    : 'Aucune vente';

  // Calculate monthly average
  const calculateMonthlyAverage = () => {
    if (!firstSale || !lastSale) return 0;
    const firstDate = new Date(firstSale.saleDate);
    const lastDate = new Date(lastSale.saleDate);
    // Calculate exact months difference including partial months
    const monthsDiff = Math.max(1, (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24 * 30.44));

    return agentSales.length / Math.max(1, monthsDiff);
  };

  const monthlyAverage = calculateMonthlyAverage();

  const commission = lastSale ? calculateCommission(lastSale) : null;
  
  // Round monthly average to nearest whole number
  const roundedMonthlyAverage = Math.round(monthlyAverage);
  
  // Calculate monthly average sales
  const monthlyAverageSales = agentSales.length === 0 ? 0 : 
    agentSales.length / Math.max(1, (new Date().getTime() - new Date(agentSales[agentSales.length - 1].saleDate).getTime()) / (1000 * 60 * 60 * 24 * 30.44));

  const agentCommission = lastSale ? (
    lastSale.agentId === agent.id ? commission?.agent : commission?.referrer
  ) : 0;
  
  // Calculate total volume
  const totalVolume = agentSales.reduce((sum, sale) => sum + sale.propertyPrice, 0);

  // Calculate commission stats
  const stats = agentSales.reduce((acc, sale) => {
    const commission = calculateCommission(sale);
    const isMainAgent = sale.agentId === agent.id;
    let saleCommission = 0;
    let saleBonus = 0;

    // Calculate commission based on agent role
    if (isMainAgent) {
      saleCommission += commission.agent;
      if (sale.bonus) {
        saleBonus += commission.bonus.agent;
      }
    } else if (sale.referrerId === agent.id) {
      saleCommission += commission.referrer || 0;
      if (sale.bonus?.secondAgentId === agent.id) {
        saleBonus += commission.bonus.referrer;
      }
    } else if (sale.manualCommissions?.additionalAgents) {
      const additionalAgent = sale.manualCommissions.additionalAgents.find(a => a.agentId === agent.id);
      if (additionalAgent) {
        saleCommission += additionalAgent.amount;
        if (sale.bonus?.agents?.find(a => a.agentId === agent.id)) {
          saleBonus += sale.bonus.agents.find(a => a.agentId === agent.id)?.amount || 0;
        }
      }
    }

    // Determine payment status based on role
    const isPaid = isMainAgent ? sale.paymentStatus.agent : sale.paymentStatus.referrer;
    const isBonusPaid = sale.paymentStatus.bonus;

    if (isPaid) {
      acc.received += saleCommission;
      if (isBonusPaid) {
        acc.bonus += saleBonus;
      }
    } else {
      acc.pending += saleCommission;
      if (!isBonusPaid) {
        acc.pending += saleBonus;
      }
    }

    return acc;
  }, { received: 0, pending: 0, bonus: 0 });

  // Calculate monthly data
  const monthlyData = agentSales.reduce((acc, sale) => {
    const date = new Date(sale.saleDate);
    const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
    const commission = calculateCommission(sale);
    let saleCommission = 0;
    
    // Calculate commission based on agent role
    if (sale.agentId === agent.id) {
      saleCommission = commission.agent;
      if (sale.bonus) {
        saleCommission += commission.bonus.agent;
      }
    } else if (sale.referrerId === agent.id) {
      saleCommission = commission.referrer || 0;
      if (sale.bonus?.secondAgentId === agent.id) {
        saleCommission += commission.bonus.referrer;
      }
    }
    
    if (!acc[monthKey]) {
      acc[monthKey] = {
        month: monthKey,
        commission: saleCommission,
        sales: 0
      };
    } else {
      acc[monthKey].commission += saleCommission;
    }
    
    acc[monthKey].sales += 1;
    return acc;
  }, {} as Record<string, {
    month: string;
    commission: number;
    sales: number;
  }>);

  // Find best and worst months
  const monthlyStats = Object.values(monthlyData);
  const bestMonth = monthlyStats.length > 0 
    ? monthlyStats.reduce((best, current) => 
        current.commission > best.commission ? current : best
      )
    : { month: 'N/A', commission: 0, sales: 0 };

  const worstMonth = monthlyStats.length > 0
    ? monthlyStats.reduce((worst, current) => 
        current.commission < worst.commission ? current : worst
      )
    : { month: 'N/A', commission: 0, sales: 0 };
  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl w-full border-b border-[#2c2c2e]  top-0 z-50">
        <div className="max-w-lg mx-auto px-4 pt-12 pb-4">
          <div className="space-y-6">
            {/* Welcome Header */}
            <div className="space-y-1">
              <h1 className="text-[34px] font-bold text-white">
                Bienvenue,<br />
                {agent.name}
              </h1>
              <div className="flex items-center gap-2">
                <Building className="w-5 h-5 text-[#0a84ff]" />
                <p className="text-[17px] text-[#98989f]">
                  Dernière vente : {daysSinceLastSale}
                </p>
              </div>
            </div>
            
            {/* Stats */}
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2">
                  <TrendingUp className="w-5 h-5 text-[#30d158]" />
                  <span className="text-[17px] text-[#98989f]">Moyenne : {roundedMonthlyAverage} ventes/mois</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}

      
      
      <div className="max-w-lg mx-auto px-4 py-4 space-y-4">

{/* Quarterly Goal Card */}
        {quarterlyGoal && (
          <MonthlyGoalCard
            quarterlyGoal={quarterlyGoal}
            sales={agentSales}
          />
        )}

        {/* Alerts */}
        <InactivityAlert lastSaleDate={lastSale?.saleDate} />
        <UnderperformingAlert monthlyAverage={roundedMonthlyAverage} />
        
        {/* Last Sale */}
        {lastSale && agentCommission && (
          <LastSaleCard 
            sale={lastSale}
            commission={agentCommission}
          />
        )}

        
        
        {/* Commission Stats */}
        <CommissionStats
          stats={stats}
          totalVolume={totalVolume}
          salesCount={agentSales.length}
        />

        {/* Monthly Commission History */}
        <MonthlyCommissionHistory
          sales={agentSales}
          agentId={agent.id}
        />

        {/* Performance & AI Estimations */}
        <PerformanceSection
          bestMonth={bestMonth}
          worstMonth={worstMonth}
          stats={{
            monthlyAverageSales: monthlyAverage,
            monthlyAverageRevenue: totalVolume / Math.max(1, Object.keys(monthlyData).length),
            monthlyAverageCommission: (stats.received + stats.pending) / Math.max(1, Object.keys(monthlyData).length),
            growthRate: {
              sales: ((monthlyAverage - (monthlyAverage * 0.9)) / (monthlyAverage * 0.9)) * 100,
              revenue: 15, // Example growth rate
              commission: 12 // Example growth rate
            }
          }}
          projections={{
            nextMonth: {
              sales: Math.ceil(monthlyAverage * 1.1),
              revenue: totalVolume / Math.max(1, Object.keys(monthlyData).length) * 1.1,
              commission: (stats.received + stats.pending) / Math.max(1, Object.keys(monthlyData).length) * 1.1
            },
            threeMonths: {
              sales: Math.ceil(monthlyAverage * 3 * 1.2),
              revenue: totalVolume / Math.max(1, Object.keys(monthlyData).length) * 3 * 1.2,
              commission: (stats.received + stats.pending) / Math.max(1, Object.keys(monthlyData).length) * 3 * 1.2
            },
            endOfYear: {
              sales: Math.ceil(monthlyAverage * (12 - new Date().getMonth()) * 1.3),
              revenue: totalVolume / Math.max(1, Object.keys(monthlyData).length) * (12 - new Date().getMonth()) * 1.3,
              commission: (stats.received + stats.pending) / Math.max(1, Object.keys(monthlyData).length) * (12 - new Date().getMonth()) * 1.3
            }
          }}
        />
        
        {/* Quarterly Ranking */}
        <QuarterlyRanking />

        {/* Referral Sales Section */}
        <ReferralSalesSection
          sales={sales}
          calculateCommission={calculateCommission}
          agentId={agent.id}
          agents={agents}
        />


        {/* Estimation IA Button */}
        <Link
          to={`/agentcrm/${slug}/estimation`}
          className="mt-6 flex items-center justify-between w-full px-4 py-3 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors"
        >
          <div className="flex items-center gap-3">
            <Calculator className="w-5 h-5" />
            <span className="text-[15px] font-medium">Voir l'estimation détaillée</span>
          </div>
          <ChevronRight className="w-5 h-5" />
        </Link>

        {/* Referral Banner */}
        <ReferralBanner />

        {/* Quick Actions */}
        <div className="p-4 bg-[#2c2c2e] rounded-xl">
          <h3 className="text-[17px] font-medium text-white mb-4">Actions rapides</h3>
          <div className="grid grid-cols-2 gap-4">
            <Link
              to={`/agentcrm/${slug}/sales`}
              className="flex items-center justify-center gap-2 px-4 py-3 bg-[#3a3a3c] text-[#FFF] rounded-xl hover:bg-[#434345] transition-colors"
            >
              <Building className="w-4 h-4" />
              <span className="text-[15px] font-medium">Mes ventes</span>
            </Link>
            <Link
              to={`/agentcrm/${slug}/estimation`}
              className="flex items-center justify-center gap-2 px-4 py-3 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors"
            >
              <Target className="w-4 h-4" />
              <span className="text-[15px] font-medium">Estimation IA</span>
            </Link>
          </div>
        </div>

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>
    </div>
  );
}