import React from 'react';
import { User, Mail, Phone, CreditCard, FileText } from 'lucide-react';

interface ClientSectionProps {
  formData: {
    client: {
      name?: string;
      phone?: string;
      email?: string;
      payments?: {
        eoi?: {
          status: boolean;
          date?: string;
        };
        downPayment?: {
          status: boolean;
          date?: string;
        };
        spa?: {
          status: boolean;
          date?: string;
        };
      };
    };
  };
  onChange: (data: { client: ClientSectionProps['formData']['client'] }) => void;
}

export default function ClientSection({ formData, onChange }: ClientSectionProps) {
  const handlePaymentChange = (type: 'eoi' | 'downPayment' | 'spa', status: boolean) => {
    onChange({
      client: {
        ...formData.client,
        payments: {
          ...formData.client.payments,
          [type]: {
            status,
            date: status ? new Date().toISOString() : undefined
          }
        }
      }
    });
  };

  const formatDate = (date?: string) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('fr-FR');
  };

  return (
    <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
      {/* Header */}
      <div className="p-4 border-b border-[#3a3a3c]">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-[#0a84ff]/10 rounded-lg">
            <User className="w-5 h-5 text-[#0a84ff]" />
          </div>
          <div>
            <h3 className="text-[17px] font-medium text-white">Information client</h3>
            <p className="text-[13px] text-[#98989f]">Coordonnées et paiements</p>
          </div>
        </div>
      </div>

      {/* Form Content */}
      <div className="p-4 space-y-6">
        {/* Client Info Fields */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-[13px] font-medium text-[#98989f] mb-2">
              Nom du client
            </label>
            <div className="relative">
              <User className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
              <input
                type="text"
                value={formData.client.name || ''}
                onChange={(e) => onChange({
                  client: { ...formData.client, name: e.target.value }
                })}
                className="w-full pl-10 pr-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                placeholder="Nom complet"
              />
            </div>
          </div>

          <div>
            <label className="block text-[13px] font-medium text-[#98989f] mb-2">
              Email
            </label>
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
              <input
                type="email"
                value={formData.client.email || ''}
                onChange={(e) => onChange({
                  client: { ...formData.client, email: e.target.value }
                })}
                className="w-full pl-10 pr-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                placeholder="email@example.com"
              />
            </div>
          </div>

          <div>
            <label className="block text-[13px] font-medium text-[#98989f] mb-2">
              Téléphone
            </label>
            <div className="relative">
              <Phone className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
              <input
                type="tel"
                value={formData.client.phone || ''}
                onChange={(e) => onChange({
                  client: { ...formData.client, phone: e.target.value }
                })}
                className="w-full pl-10 pr-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                placeholder="+971 XX XXX XXXX"
              />
            </div>
          </div>
        </div>

        {/* Payment Status Section */}
        <div>
          <h4 className="text-[15px] font-medium text-white mb-4">État des paiements</h4>
          <div className="grid grid-cols-3 gap-4">
            {/* EOI Payment */}
            <div className="p-4 bg-[#3a3a3c] rounded-xl">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <CreditCard className="w-4 h-4 text-[#0a84ff]" />
                  <span className="text-[15px] text-white">EOI</span>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={formData.client.payments?.eoi?.status || false}
                    onChange={(e) => handlePaymentChange('eoi', e.target.checked)}
                    className="sr-only peer"
                  />
                  <div className="w-9 h-5 bg-[#48484a] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#0a84ff]/30 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-[#48484a] after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[#0a84ff]"></div>
                </label>
              </div>
              {formData.client.payments?.eoi?.date && (
                <p className="text-[13px] text-[#98989f]">
                  {formatDate(formData.client.payments.eoi.date)}
                </p>
              )}
            </div>

            {/* Down Payment */}
            <div className="p-4 bg-[#3a3a3c] rounded-xl">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <CreditCard className="w-4 h-4 text-[#0a84ff]" />
                  <span className="text-[15px] text-white">DP</span>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={formData.client.payments?.downPayment?.status || false}
                    onChange={(e) => handlePaymentChange('downPayment', e.target.checked)}
                    className="sr-only peer"
                  />
                  <div className="w-9 h-5 bg-[#48484a] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#0a84ff]/30 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-[#48484a] after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[#0a84ff]"></div>
                </label>
              </div>
              {formData.client.payments?.downPayment?.date && (
                <p className="text-[13px] text-[#98989f]">
                  {formatDate(formData.client.payments.downPayment.date)}
                </p>
              )}
            </div>

            {/* SPA */}
            <div className="p-4 bg-[#3a3a3c] rounded-xl">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <FileText className="w-4 h-4 text-[#0a84ff]" />
                  <span className="text-[15px] text-white">SPA</span>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={formData.client.payments?.spa?.status || false}
                    onChange={(e) => handlePaymentChange('spa', e.target.checked)}
                    className="sr-only peer"
                  />
                  <div className="w-9 h-5 bg-[#48484a] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#0a84ff]/30 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-[#48484a] after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[#0a84ff]"></div>
                </label>
              </div>
              {formData.client.payments?.spa?.date && (
                <p className="text-[13px] text-[#98989f]">
                  {formatDate(formData.client.payments.spa.date)}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}