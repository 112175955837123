import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, Calendar } from 'lucide-react';
import { formatCurrency, formatEuro } from '../../utils/format';
import { formatDateDisplay } from '../../utils/date';
import BlurredNumber from '../../components/ui/BlurredNumber';

interface SalePriceCardProps {
  price: number;
  saleDate: string;
  developerCommissionPercentage: number;
}

export default function SalePriceCard({ price, saleDate, developerCommissionPercentage }: SalePriceCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-[#1c1c1e] rounded-2xl overflow-hidden"
    >
      <div className="grid grid-cols-2 divide-x divide-[#2c2c2e]">
        {/* Price */}
        <div className="p-6">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
              <DollarSign className="w-5 h-5 text-[#30d158]" />
            </div>
            <p className="text-[13px] text-[#98989f]">Prix du bien</p>
          </div>
          <div>
            <p className="text-[22px] font-bold text-white mb-1">
              <BlurredNumber value={formatCurrency(price)} />
            </p>
            <p className="text-[15px] text-[#98989f]">
              <BlurredNumber value={formatEuro(price)} />
            </p>
          </div>
        </div>

        {/* Date */}
        <div className="p-6">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-8 h-8 rounded-full bg-[#2c2c2e] flex items-center justify-center">
              <Calendar className="w-5 h-5 text-[#0a84ff]" />
            </div>
            <p className="text-[13px] text-[#98989f]">Date de vente</p>
          </div>
          <p className="text-[17px] text-white">{formatDateDisplay(saleDate)}</p>
        </div>
      </div>

      {/* Commission Rate */}
      <div className="px-6 py-4 border-t border-[#2c2c2e]">
        <div className="flex items-center justify-between">
          <p className="text-[15px] text-[#98989f]">Commission promoteur</p>
          <p className="text-[17px] text-white font-medium">{developerCommissionPercentage}%</p>
        </div>
      </div>
    </motion.div>
  );
}