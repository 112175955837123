import React, { useState, useMemo } from 'react';
import { useSales } from '../hooks/useSales';
import { useAgents } from '../hooks/useAgents';
import { Building, Plus, Search, Filter, Calendar, DollarSign, Edit, Trash2, Users, Gift, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import SaleForm from '../components/SaleForm';
import Avatar from '../components/ui/Avatar';
import { formatCurrency, formatEuro } from '../utils/format';
import { formatDateDisplay } from '../utils/date';
import BlurredNumber from '../components/ui/BlurredNumber';
import { useToast } from '../contexts/ToastContext';

type TabType = 'all' | 'received';

export default function Sales() {
  const { sales, addSale, updateSale, deleteSale, calculateCommission } = useSales();
  const { agents } = useAgents();
  const { showToast } = useToast();
  const [isAddingSale, setIsAddingSale] = useState(false);
  const [editingSale, setEditingSale] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterBy, setFilterBy] = useState<'all' | 'project' | 'phone'>('all');
  const [activeTab, setActiveTab] = useState<TabType>('all');

  // Filter sales based on search query and active tab
  const filteredSales = useMemo(() => {
    return sales.filter(sale => {
      // Filter by tab
      if (activeTab === 'received' && !sale.paymentStatus.developer) {
        return false;
      }

      // Filter by search query
      if (!searchQuery) return true;

      const query = searchQuery.toLowerCase();
      switch (filterBy) {
        case 'project':
          return sale.project.toLowerCase().includes(query);
        case 'phone':
          return sale.client.phone?.toLowerCase().includes(query) || false;
        default:
          return (
            sale.project.toLowerCase().includes(query) ||
            sale.client.name?.toLowerCase().includes(query) ||
            sale.unitNumber.toLowerCase().includes(query)
          );
      }
    });
  }, [sales, searchQuery, filterBy, activeTab]);

  // Group sales by month
  const salesByMonth = useMemo(() => {
    const grouped: { [key: string]: typeof sales } = {};
    
    filteredSales.forEach(sale => {
      const date = new Date(sale.saleDate);
      const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      
      if (!grouped[monthKey]) {
        grouped[monthKey] = [];
      }
      grouped[monthKey].push(sale);
    });
    
    // Sort months in reverse chronological order
    return Object.fromEntries(
      Object.entries(grouped).sort((a, b) => {
        const dateA = new Date(a[1][0].saleDate);
        const dateB = new Date(b[1][0].saleDate);
        return dateB.getTime() - dateA.getTime();
      })
    );
  }, [filteredSales]);

  const handleDelete = async (id: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette vente ?')) {
      try {
        await deleteSale(id);
        showToast('success', 'Vente supprimée avec succès');
      } catch (error) {
        showToast('error', 'Erreur lors de la suppression de la vente');
      }
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl sticky top-0 z-50 w-full">
        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <div className="flex-1">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                  <Building className="w-6 h-6 text-[#0a84ff]" />
                </div>
                <div>
                  <h1 className="text-[22px] font-semibold text-white">Ventes</h1>
                  <p className="text-[13px] text-[#98989f]">
                    {filteredSales.length} ventes • Volume total : {formatCurrency(filteredSales.reduce((sum, sale) => sum + sale.propertyPrice, 0))}
                  </p>
                </div>
              </div>
            </div>
            <button
              onClick={() => setIsAddingSale(true)}
              className="px-4 py-2 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors flex items-center gap-2"
            >
              <Plus className="w-5 h-5" />
              Nouvelle vente
            </button>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 py-2 border-t border-[#2c2c2e]">
          <div className="flex gap-2 p-1.5 bg-[#2c2c2e] rounded-xl shadow-inner">
            <button
              onClick={() => setActiveTab('all')}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'all'
                  ? 'bg-[#0a84ff] text-white'
                  : 'text-[#98989f] hover:text-white'
              }`}
            >
              <Building className="w-4 h-4" />
              <span className="text-[15px] font-medium">Toutes les ventes</span>
            </button>
            <button
              onClick={() => setActiveTab('received')}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'received'
                  ? 'bg-[#0a84ff] text-white'
                  : 'text-[#98989f] hover:text-white'
              }`}
            >
              <DollarSign className="w-4 h-4" />
              <span className="text-[15px] font-medium">Commission reçue</span>
            </button>
          </div>
        </div>

        {/* Search & Filters */}
        <div className="max-w-7xl mx-auto px-4 py-3 border-t border-[#2c2c2e]">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
                <input
                  type="text"
                  placeholder="Rechercher une vente..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 bg-[#2c2c2e] text-white placeholder-[#98989f] rounded-lg focus:ring-2 focus:ring-[#0a84ff] border-none"
                />
              </div>
            </div>
            <div className="relative">
              <select
                value={filterBy}
                onChange={(e) => setFilterBy(e.target.value as 'all' | 'project' | 'phone')}
                className="w-full sm:w-auto appearance-none pl-8 pr-10 py-2 bg-[#2c2c2e] text-white border-none rounded-lg focus:ring-2 focus:ring-[#0a84ff]"
              >
                <option value="all">Tous les filtres</option>
                <option value="project">Projet</option>
                <option value="phone">Téléphone</option>
              </select>
              <Filter className="absolute left-2 top-1/2 -translate-y-1/2 w-4 h-4 text-[#98989f]" />
            </div>
          </div>
        </div>
      </div>

      {/* Sales List */}
      <div className="max-w-7xl mx-auto px-4 py-4 space-y-8">
        {Object.entries(salesByMonth).map(([month, monthSales]) => (
          <div key={month} className="space-y-4">
            <h2 className="text-[17px] font-medium text-white mb-4">
              {month}
            </h2>

            {monthSales.map(sale => {
              const commission = calculateCommission(sale);
              const agent = agents.find(a => a.id === sale.agentId);
              const referrer = sale.referrerId ? agents.find(a => a.id === sale.referrerId) : null;

              return (
                <div key={sale.id} className="bg-[#2c2c2e] rounded-xl overflow-hidden">
                  <div className="p-6">
                    {/* Top Section */}
                    <Link 
                      to={`/sales/${sale.id}`}
                      className="flex items-center justify-between mb-6 group"
                    >
                      <div className="flex items-center gap-3">
                        <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                          <Building className="w-6 h-6 text-[#0a84ff]" />
                        </div>
                        <div>
                          <h3 className="text-[22px] font-semibold text-white group-hover:text-[#0a84ff] transition-colors">
                            {sale.project}
                          </h3>
                          <p className="text-[15px] text-[#98989f]">Unité {sale.unitNumber}</p>
                        </div>
                      </div>

                      {/* Commission Status */}
                      <div className={`px-4 py-2 rounded-xl text-[15px] font-medium ${
                        sale.paymentStatus.developer
                          ? 'bg-[#26382f] text-[#30d158]'
                          : 'bg-[#3a2c29] text-[#ff453a]'
                      }`}>
                        {sale.paymentStatus.developer ? 'Commission reçue' : 'En attente'}
                      </div>
                    </Link>

                    {/* Commission Info */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
                      {/* Agency Commission */}
                      <div className="p-4 bg-[#2c2c2e] rounded-xl">
                        <div className="flex items-center gap-3 mb-3">
                          <DollarSign className="w-5 h-5 text-[#30d158]" />
                          <p className="text-[15px] text-[#98989f]">Commission agence</p>
                        </div>
                        <p className="text-[28px] font-bold text-[#30d158] mb-1">
                          <BlurredNumber value={formatCurrency(commission.agency)} />
                        </p>
                        <p className="text-[13px] text-[#98989f]">
                          <BlurredNumber value={formatEuro(commission.agency)} />
                        </p>
                        {sale.bonus && (
                          <div className="mt-3 flex items-center gap-2 px-3 py-1.5 bg-[#2c2c2e] rounded-xl">
                            <Gift className="w-4 h-4 text-[#bf5af2]" />
                            <span className="text-[13px] text-[#bf5af2]">
                              +<BlurredNumber value={formatCurrency(commission.bonus.agency)} />
                            </span>
                          </div>
                        )}
                      </div>

                      {/* Price Info */}
                      <div className="p-4 bg-[#2c2c2e] rounded-xl">
                        <div className="flex items-center gap-3 mb-3">
                          <Building className="w-5 h-5 text-[#0a84ff]" />
                          <p className="text-[15px] text-[#98989f]">Prix du bien</p>
                        </div>
                        <p className="text-[28px] font-bold text-white mb-1">
                          <BlurredNumber value={formatCurrency(sale.propertyPrice)} />
                        </p>
                        <p className="text-[13px] text-[#98989f]">
                          <BlurredNumber value={formatEuro(sale.propertyPrice)} />
                        </p>
                      </div>
                    </div>

                    {/* Agents Section */}
                    <div className="space-y-4 bg-[#3a3a3c] rounded-xl p-4">
                      <div className="flex items-center gap-2 mb-2">
                        <Users className="w-4 h-4 text-[#98989f]" />
                        <p className="text-[13px] text-[#98989f]">Agents</p>
                      </div>

                      {/* Primary Agent */}
                      {agent && (
                        <div className="flex items-center justify-between p-3 bg-[#48484a] rounded-xl">
                          <div className="flex items-center gap-3">
                            <Avatar name={agent.name} size="sm" />
                            <Link
                              to={`/agents/${agent.id}`}
                              className="text-[15px] font-medium text-white hover:text-[#0a84ff] transition-colors"
                            >
                              {agent.name}
                            </Link>
                          </div>
                          <div className="text-right">
                            <p className="text-[15px] font-medium text-white">
                              <BlurredNumber value={formatCurrency(commission.agent)} />
                            </p>
                            {sale.bonus && (
                              <div className="flex items-center gap-1 mt-1 text-[13px] text-[#bf5af2]">
                                <Gift className="w-4 h-4" />
                                +<BlurredNumber value={formatCurrency(commission.bonus.agent)} />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {/* Referrer */}
                      {referrer && (
                        <div className="flex items-center justify-between p-3 bg-[#48484a] rounded-xl">
                          <div className="flex items-center gap-3">
                            <Avatar name={referrer.name} size="sm" />
                            <Link
                              to={`/agents/${referrer.id}`}
                              className="text-[15px] font-medium text-white hover:text-[#0a84ff] transition-colors"
                            >
                              {referrer.name}
                            </Link>
                          </div>
                          <div className="text-right">
                            <p className="text-[15px] font-medium text-white">
                              <BlurredNumber value={formatCurrency(commission.referrer || 0)} />
                            </p>
                            {sale.bonus?.secondAgentId === referrer.id && (
                              <div className="flex items-center gap-1 mt-1 text-[13px] text-[#bf5af2]">
                                <Gift className="w-4 h-4" />
                                +<BlurredNumber value={formatCurrency(commission.bonus.referrer)} />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {/* Additional Agents */}
                      {sale.manualCommissions?.additionalAgents?.map((additionalAgent, index) => {
                        const agent = agents.find(a => a.id === additionalAgent.agentId);
                        if (!agent) return null;
                        
                        return (
                          <div key={index} className="flex items-center justify-between p-3 bg-[#48484a] rounded-xl">
                            <div className="flex items-center gap-3">
                              <Avatar name={agent.name} size="sm" />
                              <Link
                                to={`/agents/${agent.id}`}
                                className="text-[15px] font-medium text-white hover:text-[#0a84ff] transition-colors"
                              >
                                {agent.name}
                              </Link>
                            </div>
                            <div className="text-right">
                              <p className="text-[15px] font-medium text-white">
                                <BlurredNumber value={formatCurrency(additionalAgent.amount)} />
                              </p>
                              {sale.bonus?.agents?.find(a => a.agentId === additionalAgent.agentId) && (
                                <div className="flex items-center gap-1 mt-1 text-[13px] text-[#bf5af2]">
                                  <Gift className="w-4 h-4" />
                                  +<BlurredNumber value={formatCurrency(
                                    sale.bonus.agents.find(a => a.agentId === additionalAgent.agentId)?.amount || 0
                                  )} />
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {/* Footer */}
                    <div className="flex items-center justify-between mt-6 pt-4">
                      <div className="flex items-center gap-2 text-[13px] text-[#98989f]">
                        <Calendar className="w-4 h-4" />
                        <span>{formatDateDisplay(sale.saleDate)}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => {
                            setEditingSale(sale.id);
                            setIsAddingSale(true);
                          }}
                          className="p-2 text-[#0a84ff] hover:bg-[#0a84ff]/10 rounded-lg transition-colors"
                        >
                          <ChevronRight className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => handleDelete(sale.id)}
                          className="p-2 text-[#ff453a] hover:bg-[#ff453a]/10 rounded-lg transition-colors"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ))}

        {filteredSales.length === 0 && (
          <div className="text-center py-12 bg-[#2c2c2e] rounded-xl">
            <Building className="w-12 h-12 text-[#98989f] mx-auto mb-4" />
            <p className="text-[#98989f]">Aucune vente trouvée</p>
          </div>
        )}

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>

      {/* Add/Edit Sale Modal */}
      {isAddingSale && (
        <SaleForm
          initialData={editingSale ? sales.find(s => s.id === editingSale) : undefined}
          onSubmit={async (saleData) => {
            try {
              if (editingSale) {
                await updateSale(editingSale, saleData);
                showToast('success', 'Vente modifiée avec succès');
              } else {
                await addSale(saleData);
                showToast('success', 'Vente ajoutée avec succès');
              }
              setIsAddingSale(false);
              setEditingSale(null);
            } catch (error) {
              showToast('error', editingSale ? 'Erreur lors de la modification' : 'Erreur lors de l\'ajout');
            }
          }}
          onClose={() => {
            setIsAddingSale(false);
            setEditingSale(null);
          }}
        />
      )}
    </div>
  );
}