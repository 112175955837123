import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { verifyFirebaseSetup } from './lib/firebase'; 
import { initializeCollections } from './lib/firebase/init';
import './index.css';

async function initializeApp() {
  try {
    await verifyFirebaseSetup();
    await initializeCollections();
  } catch (error) {
    console.error('Firebase initialization error:', error);
  } finally {
    createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
}

initializeApp();