import React, { useMemo } from 'react';
import { Building, Trophy, Medal, Crown } from 'lucide-react';
import { useSales } from '../../hooks/useSales';
import BlurredNumber from '../../components/ui/BlurredNumber';
import { formatCurrency, formatEuro } from '../../utils/format';

export default function TopProjects() {
  const { sales, calculateCommission } = useSales();

  const projectStats = useMemo(() => {
    const stats = sales.reduce((acc, sale) => {
      if (!acc[sale.project]) {
        acc[sale.project] = {
          name: sale.project,
          salesCount: 0,
          agencyCommission: 0,
          totalVolume: 0
        };
      }

      const commission = calculateCommission(sale);
      acc[sale.project].salesCount += 1;
      acc[sale.project].agencyCommission += commission.agency;
      acc[sale.project].totalVolume += sale.propertyPrice;
      if (sale.bonus) {
        acc[sale.project].agencyCommission += commission.bonus.agency;
      }

      return acc;
    }, {} as Record<string, {
      name: string;
      salesCount: number;
      agencyCommission: number;
      totalVolume: number;
    }>);

    return Object.values(stats)
      .sort((a, b) => b.agencyCommission - a.agencyCommission)
      .slice(0, 5);
  }, [sales]);

  const getRankStyle = (index: number) => {
    switch (index) {
      case 0:
        return {
          icon: <Crown className="w-5 h-5 text-[#ffd60a]" />,
          bg: 'bg-gradient-to-br from-[#ffd60a]/10 to-[#ffd60a]/5',
          border: 'border-[#ffd60a]/20',
          text: 'text-[#ffd60a]'
        };
      case 1:
        return {
          icon: <Medal className="w-5 h-5 text-[#98989f]" />,
          bg: 'bg-gradient-to-br from-[#98989f]/10 to-[#98989f]/5',
          border: 'border-[#98989f]/20',
          text: 'text-[#98989f]'
        };
      case 2:
        return {
          icon: <Medal className="w-5 h-5 text-[#ff9f0a]" />,
          bg: 'bg-gradient-to-br from-[#ff9f0a]/10 to-[#ff9f0a]/5',
          border: 'border-[#ff9f0a]/20',
          text: 'text-[#ff9f0a]'
        };
      default:
        return {
          icon: <Trophy className="w-5 h-5 text-[#0a84ff]" />,
          bg: 'bg-gradient-to-br from-[#0a84ff]/10 to-[#0a84ff]/5',
          border: 'border-[#0a84ff]/20',
          text: 'text-[#0a84ff]'
        };
    }
  };

  return (
    <div className="bg-[#1c1c1e] rounded-2xl overflow-hidden">
      <div className="p-4 border-b border-[#2c2c2e]">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
            <Trophy className="w-6 h-6 text-[#0a84ff]" />
          </div>
          <div>
            <h3 className="text-[17px] font-medium text-white">Top 5 Projets</h3>
            <p className="text-[13px] text-[#98989f]">Ce mois-ci</p>
          </div>
        </div>
      </div>

      <div className="divide-y divide-[#2c2c2e]">
        {projectStats.map((project, index) => (
          <div 
            key={project.name} 
            className={`p-4 hover:bg-[#2c2c2e] transition-all duration-200 ${getRankStyle(index).bg}`}
          >
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-4">
                <div className={`flex items-center justify-center w-12 h-12 rounded-lg shadow-sm ${
                  index === 0 ? 'bg-[#ffd60a]/20' :
                  index === 1 ? 'bg-[#98989f]/20' :
                  index === 2 ? 'bg-[#ff9f0a]/20' :
                  'bg-[#0a84ff]/20'
                }`}>
                  {getRankStyle(index).icon}
                </div>
                <div>
                  <h4 className={`font-medium ${getRankStyle(index).text}`}>{project.name}</h4>
                  <div className="flex items-center gap-2 mt-1">
                    <span className="text-sm text-[#98989f]">{project.salesCount} ventes</span>
                  </div>
                </div>
              </div>
              <div className="text-right">
                <p className="text-[17px] font-bold text-white">
                  <BlurredNumber value={formatCurrency(project.agencyCommission)} />
                </p>
                <p className="text-[13px] text-[#98989f]">
                  <BlurredNumber value={formatEuro(project.agencyCommission)} />
                </p>
              </div>
            </div>
          </div>
        ))}

        {projectStats.length === 0 && (
          <div className="text-center py-12">
            <Building className="w-12 h-12 text-[#98989f] mx-auto mb-4" />
            <p className="text-[#98989f]">Aucun projet trouvé</p>
          </div>
        )}
      </div>
    </div>
  );
}