import React from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface SaleHeaderProps {
  project: string;
  unitNumber: string;
}

export default function SaleHeader({ project, unitNumber }: SaleHeaderProps) {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-[#1c1c1e] sticky top-0 z-50 w-full border-b border-[#2c2c2e]"
    >
      <div className="max-w-lg mx-auto px-4 py-3 flex items-center">
        <button
          onClick={() => navigate(-1)}
          className="w-10 h-10 flex items-center justify-center -ml-2 rounded-xl hover:bg-[#2c2c2e] transition-colors"
        >
          <ChevronLeft className="w-6 h-6 text-[#0a84ff]" />
        </button>
        <div className="ml-2">
          <h1 className="text-[17px] font-semibold text-white">{project}</h1>
          <p className="text-[15px] text-[#98989f]">Unité {unitNumber}</p>
        </div>
      </div>
    </motion.div>
  );
}