import localforage from 'localforage';

const CACHE_PREFIX = 'palm_crm_';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

// Liste des clés à préserver lors du nettoyage
const PRESERVED_KEYS = [
  'visibility-storage',
  'firebase:host:realestatecrm-84f20.firebaseio.com',
  'firebase:authUser:AIzaSyD85I8BK2vBD92xKGkDK68DXYFnYXjfoK4:[DEFAULT]'
];

interface CacheItem<T> {
  data: T;
  timestamp: number;
}

export const cache = {
  async set<T>(key: string, data: T): Promise<void> {
    const cacheItem: CacheItem<T> = {
      data,
      timestamp: Date.now()
    };
    await localforage.setItem(`${CACHE_PREFIX}${key}`, cacheItem);
  },

  async get<T>(key: string): Promise<T | null> {
    const cacheItem = await localforage.getItem<CacheItem<T>>(`${CACHE_PREFIX}${key}`);
    
    if (!cacheItem) return null;
    
    // Check if cache is expired
    if (Date.now() - cacheItem.timestamp > CACHE_DURATION) {
      await this.remove(key);
      return null;
    }
    
    return cacheItem.data;
  },

  async remove(key: string): Promise<void> {
    await localforage.removeItem(`${CACHE_PREFIX}${key}`);
  },

  async clear(): Promise<void> {
    const keys = await localforage.keys();
    const promises = keys
      .filter(key => key.startsWith(CACHE_PREFIX))
      .map(key => localforage.removeItem(key));
    await Promise.all(promises);
  }
};

export async function clearAllCache(): Promise<void> {
  try {
    // 1. Clear localStorage except preserved keys
    Object.keys(localStorage).forEach(key => {
      if (!PRESERVED_KEYS.some(preserved => key.includes(preserved))) {
        localStorage.removeItem(key);
      }
    });
    
    // 2. Clear localforage except preserved keys
    const keys = await localforage.keys();
    await Promise.all(
      keys
        .filter(key => !PRESERVED_KEYS.some(preserved => key.includes(preserved)))
        .map(key => localforage.removeItem(key))
    );
    
    // 3. Clear IndexedDB databases except Firestore and preserved
    const databases = await window.indexedDB.databases();
    await Promise.all(
      databases
        .filter(db => db.name && 
          !db.name.includes('firestore') && 
          !PRESERVED_KEYS.some(preserved => db.name!.includes(preserved))
        )
        .map(db => {
          return new Promise<void>((resolve, reject) => {
            const request = window.indexedDB.deleteDatabase(db.name!);
            request.onsuccess = () => resolve();
            request.onerror = () => reject();
          });
        })
    );

    // 4. Clear service workers
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      await Promise.all(registrations.map(reg => reg.unregister()));
    }

    // 5. Reload la page pour appliquer le nettoyage
    window.location.reload();
  } catch (error) {
    console.error('Erreur lors du nettoyage du cache:', error);
    throw error;
  }
}